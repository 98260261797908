import React, { useState, useEffect } from 'react';
import '../styles/EditCampaign.scss';
import ImageUploader from './ImageUploader';
import dayjs from 'dayjs';
import CurrencyInput from 'react-currency-input-field';
import { Form, Input, Button, Typography, DatePicker, Drawer, List, Radio, message,QRCode, Segmented, Space,Checkbox, Row, Col, Flex, Spin, Popover, Divider } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useDownloadReportMutation, useEditCampaignMutation, useEditGroupsMutation, useGetCampaignpByIdQuery, useGetContactsBulkQuery, useGetFilteredPublicGroupByIdQuery, useGetGroupByIdQuery, useGetGroupsNamesQuery, useGetInventoryCatalogsQuery, useGetOrdersBulkQuery, useInviteStudentsMutation, useSearchGroupsQuery } from '../services/admin/inventoryApi';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Group, Campaign, Catalog, User, Item } from '../types.ts';
import { BgColorsOutlined, CopyOutlined, DownOutlined, DownloadOutlined, ExportOutlined, LinkOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CSVUploader from './CSVUploader';
import ViewInvitesModal from './ViewInvitesModal';
import AccountsignupModal from './AccountsignupModal';
import { Tooltip } from 'antd';
import type { QRCodeProps } from 'antd';


// @ts-ignore
import { CSVLink, CSVDownload } from 'react-csv';
import { convertUTCDateToLocalDate } from '../utils';
import { setActiveTab } from '../slices/admin/activeTabSlice';
import { setCampaignId, setGroupId, setUserId } from '../slices/admin/adminUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { HexColorPicker } from "react-colorful";
import UsersGroupModal from './UsersGroupModal';
import { RootState } from '../store';
import PublicLeaderBoard from './PublicLeaderBoard';
import { useGetLeaderboardForPublicCampaignBasedOnTagsQuery } from '../services/campaign';
import Unauthorized from './UnAuthorised';
const { Title, Paragraph,Text } = Typography;



const { TextArea } = Input;
const defaultLogoUrl = 'https://storage.googleapis.com/frm-reporting/fund-dash-user/1739522830.4327264_i75DFhoV2osDLTnDv4h5vn.png';
const defaultHeroUrl = 'https://storage.googleapis.com/frm-reporting/fund-dash-user/1725657145.5373192_njLgoSL9xrghZ6K5XzznUC.png';
interface ParsedData {
  email_address: string;
  name: string;
}
function doDownload(url: string, fileName: string) {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
const downloadCanvasQRCode = () => {
  const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
  if (canvas) {
    const url = canvas.toDataURL('image/png');
    doDownload(url, 'QRCode.png');
  }
};
const downloadCampaignCanvasQRCode = () => {
  const canvas = document.getElementById('mycampaignqrcode')?.querySelector<HTMLCanvasElement>('canvas');
  if (canvas) {
    const url = canvas.toDataURL('image/png');
    doDownload(url, 'QRCode.png');
  }
};
const EditCampaign: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isViewInvitesModalVisible, setIsViewInvitesModalVisible] = useState(false);
  
  const handleViewInvitesModalClose = () => setIsViewInvitesModalVisible(false);
  const [isUserssignupModalVisible, setIsUserssignupModalVisible] = useState(false);
  const showUserssignupModal = () => setIsUserssignupModalVisible(true);
  const handleUserssignupModalClose = () => setIsUserssignupModalVisible(false);
  const { data: campaignsData, isLoading: isLoadingCampaigns } = useGetCampaignpByIdQuery({ campaign_id: parseInt(id || "0") });
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsNamesQuery({ skip: 0, limit: 1000 , is_rep_group:false});
  
  const { data: catalogsData, isLoading: isLoadingCatalogs } = useGetInventoryCatalogsQuery({ skip: 0, limit: 1000 });
  const [updateCampaign, { isLoading, isError, isSuccess }] = useEditCampaignMutation();
  const navigate = useNavigate();
  const [ endDate,setEndDate]= useState(dayjs(campaignsData?.end_date) as unknown as string ?? '')
  const { data: groupData, error: groupError, isLoading: groupLoading } = useGetFilteredPublicGroupByIdQuery({ group_id: campaignsData?.group?.id  },{skip:!campaignsData?.group?.id});
  const { data: ordersData, isLoading: isLoadingOrders } = useGetOrdersBulkQuery({user_ids:groupData?.users.map((user:User)=>user.id)??[], campaign_id:parseInt(id || '0') },{skip:!groupData?.users.map((user:User)=>user.id)});
  const { data: contactsData, isLoading: isLoadingContacts } = useGetContactsBulkQuery({user_ids:groupData?.users.map((user:User)=>user.id)??[] },{skip:!groupData?.users.map((user:User)=>user.id)});
  const { register, handleSubmit, control, formState: { errors,isDirty }, setValue, watch } = useForm({
    defaultValues: {
      campaign_link: '',
      logo_image_url: defaultLogoUrl,
      hero_image_url: defaultHeroUrl,
      video_url: '',
      title: '',
      blurb: '',
      money_goal: 0,
      money_raised: 0,
      end_date: null,
      start_date: null,
      is_closed: false,
      donations_enabled: false,
      leader_board_enabled: false,
      recent_orders_enabled:false,
      group_id: null,
      group_name: '',
      catalog_id: null,
      catalog_name: '',
      ship_promo_enabled: false,
      pre_sale_id: 0,
      is_paid: false,
      campaign_send_rep_1:false,
      campaign_send_admin_1_send: false,
      campaign_send_contact_goal_1_send: false,
      campaign_send_contact_end_1_send: false,
    }
  });
  const {
    control: controlOrganizationSetting,
    handleSubmit: handleSubmitOrganizationSetting,
    formState: { errors: organizationErrors },
    setValue: setOrganizationValue,
    getValues,
  } = useForm<Group>({
    defaultValues: {
   
      group_contacts_required: 0,
   
  group_default_goal:0,
  fee_platform_base: 0,
  fee_platform_percentage: 0
    },
  });

  const logoImageUrl = watch('logo_image_url');
  const heroImageUrl = watch('hero_image_url');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [textColor, setTextColor] = useState("#000000"); // Default color: black
  const [visible, setVisible] = useState(false);
  const [blurbColor, setBlurbColor] = useState("#000000"); // Default color: black
  const [visibleBlurbColor, setVisibleBlurbColor] = useState(false);
  const [hexInput, setHexInput] = useState<string>(blurbColor); // Track hex input

  const [hexError, setHexError] = useState<string | null>(null);
  const isValidHex = (hex: string) => /^#([0-9A-F]{3}){1,2}$/i.test(hex); 
  const handleHexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setHexInput(value);

    if (isValidHex(value)) {
      setBlurbColor(value);
      setHexError(null);
    } else {
      setHexError("Invalid Hex Code");
    }
  };
  const [hexTitleInput, setHexTitleInput] = useState<string>(blurbColor); // Track hex input

  const [hexTitleError, setHexTitleError] = useState<string | null>(null);
  const handleHexTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setHexTitleInput(value);

    if (isValidHex(value)) {
      setTextColor(value);
      setHexTitleError(null);
    } else {
      setHexTitleError("Invalid Hex Code");
    }
  };
  const [isLeaderBoardVisible, setIsLeaderBoardVisible] = useState(false); // Sta
  const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_BASE_URL_FE);
  const [isDrawerCatalogVisible, setIsDrawerCatalogVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [selectedCatalog, setSelectedCatalog] = useState<Catalog | null>(null);
const [inviteStudents,{isLoading:isuploadinngcsv}]=useInviteStudentsMutation()
const [updateGroup, { isLoading: isUpdating, isError: isErrorUpdatingGroup, isSuccess: isSuccessUpdatingGroup }] =
useEditGroupsMutation();
const [useDefaultLogo, setUseDefaultLogo] = useState(false);
const [useDefaultHero, setUseDefaultHero] = useState(false);
const dispatch = useDispatch();
const { data: studentData = [], error: errorFetchingTagLeaderBoard, isLoading: isLoadingStudents ,isFetching} = useGetLeaderboardForPublicCampaignBasedOnTagsQuery({
  campaignId: id || "0",
  groupId: campaignsData?.group.id ?? 0,
  skip: 0,
  limit: 10000,
}, {skip: !campaignsData?.group.id|| !id});
const [searchPreSaleId, setSearchPreSaleId] = useState<string>('');
const [debouncedPreSaleId, setDebouncedPreSaleId] = useState<string>(searchPreSaleId);
const { data:groups , isFetching:isGroupsFetching,isLoading:isGroupsLoading,status:statusGroups} = useSearchGroupsQuery({
  key: "name",
  value: debouncedPreSaleId,
  limit: 1000,
},{skip: !debouncedPreSaleId});
console.log(debouncedPreSaleId)
useEffect(() => {
  if (groupsData) {
    const group = groupData;
    if (group) {
    
      setOrganizationValue('group_default_goal', group.group_default_goal);
   
      setOrganizationValue('group_contacts_required', group.group_contacts_required ?? 0);
   
      //@ts-ignore
      setOrganizationValue('fee_platform_base',group.fee_platform_base? group.fee_platform_base/100 : parseFloat(process.env.REACT_APP_FEE_PLATFORM_BASE)/100);
      setOrganizationValue('fee_platform_percentage', group.fee_platform_percentage ?? process.env.REACT_APP_FEE_PLATFORM_PERCENTAGE);
     
    
      
    }
  }
}, [groupData, campaignsData?.group?.id, setOrganizationValue]);
  useEffect(() => {
    if (campaignsData) {
      const campaign = campaignsData;
      if (campaign) {
        setValue('campaign_link', campaign.campaign_link);
        setValue('logo_image_url', campaign.logo_image_url);
        setValue('hero_image_url', campaign.hero_image_url);
        setValue('video_url', campaign.video_url);
        setValue('title', campaign.title);
        setHexInput(campaign.blurb_color?campaign.blurb_color : "#000000")
        setHexTitleInput(campaign.title_color?campaign.title_color : "#000000")
        setBlurbColor(campaign.blurb_color?campaign.blurb_color : "#000000")
        setTextColor(campaign.title_color?campaign.title_color : "#000000")
        setValue('blurb', campaign.blurb);
        setValue('money_goal', campaign.money_goal);
        setValue('money_raised', campaign.money_raised);
        setValue('ship_promo_enabled', campaign.ship_promo_enabled);
        setValue('is_closed', campaign.is_closed);
        const targetDate =campaign?.end_date?convertUTCDateToLocalDate(new Date(campaign?.end_date) as Date):null
        const targetStartDate =campaign?.start_date?convertUTCDateToLocalDate(new Date(campaign?.start_date) as Date):null
        //@ts-ignore
        setValue('start_date', targetStartDate?dayjs(targetStartDate) as unknown:null);
        //@ts-ignore
        setValue('end_date', targetDate?dayjs(targetDate) as unknown :null);
        setValue('donations_enabled', campaign.donations_enabled);
        setValue('leader_board_enabled', campaign.leader_board_enabled);
        setValue('is_paid', campaign?.is_paid);
        setValue('campaign_send_rep_1', campaign.campaign_send_rep_1);
        setValue('campaign_send_admin_1_send', campaign.campaign_send_admin_1_send);
        setValue('campaign_send_contact_goal_1_send', campaign.campaign_send_contact_goal_1_send);
        setValue('campaign_send_contact_end_1_send', campaign.campaign_send_contact_end_1_send);
        setValue('recent_orders_enabled', campaign.recent_orders_enabled);
        setSelectedGroup(campaign.group);
        setSelectedCatalog(campaign.catalog);
        setValue('group_name', campaign?.group.name || '');
        setValue('catalog_name', campaign?.catalog?.name || '');
        setValue('pre_sale_id', campaign.pre_sale_id);
       
      }
    }
  }, [campaignsData, id, setValue, groupsData]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedPreSaleId(searchPreSaleId);  // Set debounced search term after delay
    }, 500);  // Adjust the delay as needed
  
    return () => {
      clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
    };
  }, [searchPreSaleId]);
  useEffect(()=>{
    setBaseUrl(process.env.REACT_APP_BASE_URL_FE)
  },[process.env.REACT_APP_BASE_URL_FE])
  const onSubmit = async (data: any) => {
   // data.end_date = moment(data.end_date).utc().toISOString();
   console.log( moment(data.end_date).utc().toISOString())
    data.group_id = selectedGroup?.id ?? null;
    data.catalog_id = selectedCatalog?.id ?? null;
    data.campaign_link = data.campaign_link;
    data.title_color= textColor;
    data.blurb_color= blurbColor;
    if (useDefaultLogo) {
      data.logo_image_url = defaultLogoUrl;
    }
    if (useDefaultHero) {
      data.hero_image_url = defaultHeroUrl;
    }
    try {
      await updateCampaign({ id: parseInt(id || '0'), ...data }).unwrap();
      message.success('Your changes have been saved');
      console.log('Campaign updated successfully:', data);
      // navigate(-1);
    } catch (error) {
          // @ts-ignore
          message.error(error?.data?.detail ||'Failed to update campaign.');
    }
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  useEffect(() => {
  

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogoImageUpload = (imageUrl: string) => {
    setValue('logo_image_url', imageUrl, { shouldValidate: true });
    setUseDefaultLogo(false);
  };
  const handleLeaderBoardClose = () => {
    setIsLeaderBoardVisible(false); // Close the leaderboard modal
  };
  const handleHeroImageUpload = (imageUrl: string) => {
    setValue('hero_image_url', imageUrl, { shouldValidate: true });
    setUseDefaultHero(false);  
  };

  const handleDrawerOpen = () => {
    if((((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)){
      return
    }
    setIsDrawerVisible(true);
  };

  const handleDrawerCatalogOpen = () => {
    if((((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)){
      return
    }
    setIsDrawerCatalogVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const handleDrawerCatalogClose = () => {
    setIsDrawerCatalogVisible(false);
  };

  const handleGroupSelect = (group: Group) => {
    setSelectedGroup(group);
    setValue('group_name', group.name);
    setIsDrawerVisible(false);
  };

  const handleCatalogSelect = (catalog: Catalog) => {
    setSelectedCatalog(catalog);
    setValue('catalog_name', catalog.name);
    setIsDrawerCatalogVisible(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${baseUrl}signup/${campaignsData?.group?.id}`);
    message.success('Invite link copied to clipboard');
  };
  const copyCampaignToClipboard = () => {
    // @ts-ignore
    navigator.clipboard.writeText(`${baseUrl}raise/${campaignsData?.campaign_link}`);
    message.success('Invite link copied to clipboard');
  };
  
  const handleDataParsed = async (data: ParsedData[]) => {
    console.log('Parsed Data:', data);
    await inviteStudents({campaignId:id ??"0" ,data})
    message.success('CSV file parsed successfully!');
    // Do something with the data, e.g., send it to a server
  };
  const [downloadReport,{data:csv}] = useDownloadReportMutation();
  const user = useSelector((state: RootState) => state.adminUser.user);

  const handleDownload = async (campaignId: string, reportType: 'based_order' | 'based_user' | 'based_product'| 'finance_based_order'|'based_frm_xml') => {
    try {
      // Call the API and expect a response in CSV format
      const response = await downloadReport({ campaignId, reportType }).unwrap();
  
      // Check if response is a Blob or string
      // @ts-ignore
      if (response instanceof Blob) {
        const url = URL.createObjectURL(response);
  
        const link = document.createElement('a');
        link.href = url;
        if(reportType==='based_frm_xml'){
          link.setAttribute('download', `report_${campaignId}_${reportType}.xml`);
        }else{
          link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        }
       
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else if (typeof response === 'string') {
        // If response is a string, handle it as CSV content
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        message.error('Unexpected response format');
      }
    } catch (err) {
      console.error('Download Error:', err);
      message.error('Failed to download report');
    }
  };
  const onGroupSubmit = async (data: Group) => {
    const updatedData = {
      ...groupData,
      ...data,
      users: groupData?.users.map((user) => user.id),
      tag_updates:groupData?.tags??[],
      fee_platform_base: Math.ceil((data?.fee_platform_base || 0) * 100),
    };
    try {
      await updateGroup({ groupData:updatedData, id: campaignsData?.group?.id }).unwrap();
      console.log('Group updated successfully:', updatedData);
      message.success('Organization updated successfully!');
      // navigate(-1);
    } catch (error) {
      console.error('Failed to update Organization:', error);
    }
  };
  function setRenderType(arg0: string | undefined): void {
    throw new Error('Function not implemented.');
  }
  if(isLoadingCampaigns){
    return  <div className="edit-campaign-container" style={{height:"100%", padding:20}}><Spin tip="Loading..." /></div>
  }
  // if ((user?.scope === ('rep')&&!user?.permissions?.edit_campaign)) {


  //   return <Unauthorized />
  // }
  return (
    <>
     <div className="edit-campaign-container" >
     <Title level={2} className="editProduct">Invite Seller</Title>
     <div  style={{
      padding:20,
   

      
     }}>
       {/* <Button type="primary"  onClick={showAccountssignupModal} style={{ marginRight: '8px' }}>
        Invited Signup Details
      </Button> */}
      <Button type="primary"  onClick={showUserssignupModal} style={{ marginRight: '8px' }}>
       User Signup Details
      </Button>
     <CSVUploader onDataParsed={handleDataParsed} loading={isuploadinngcsv}/>
       {/* <Button type="primary"  onClick={showViewInvitesModal} style={{ marginLeft: '8px' }}>
        View invites
      </Button> */}
     
       <Paragraph style={{
        paddingTop: 20,
        paddingBottom: 0,
        paddingLeft:0,
        borderBottom:"none",

       }}>
        Not sure what to include?
        <CSVLink 
         data ={`name,email
John Doe,jdoe@example.com`}
         >
          {' '}Download our sample CSV
         </CSVLink>
        
         
         ;



       </Paragraph>
       {isViewInvitesModalVisible&&<ViewInvitesModal visible={isViewInvitesModalVisible} onClose={handleViewInvitesModalClose} campaignId={id!} />}
       {isUserssignupModalVisible&&<UsersGroupModal isMobile={!isDesktop} visible={isUserssignupModalVisible} onClose={handleUserssignupModalClose}  
       userLoading={isLoadingOrders || groupLoading||isLoadingContacts}
       campaignId={id!}
       userData={
        groupData?.users.map(user => {
          const userDonations = ordersData && user
          ? ordersData.filter((donation: { user_id: number }) => donation?.user_id === user?.id)?.length > 0
            ? "Yes"
            : "No"
          : "No";
          const userContacts = contactsData && user
          ? contactsData.filter((contact: { user_id: number }) => contact?.user_id === user?.id)?.length:0
            ;
          return { ...user, donations: userDonations, contacts: userContacts };
        })??[]
        }/>}
     </div>
     
   
     </div>
     <div className="edit-campaign-container" >
     <Title level={2} className="editProduct">Quick Links</Title>
     <div  style={{
      padding:20,
   gap:10,
   display:"flex",
   flexWrap:"wrap"


      
     }}>
       <Button type="primary"  onClick={()=>{
       dispatch(setUserId(null));
         dispatch(setCampaignId(id??""));
         dispatch(setActiveTab(`tab7` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
         navigate(`/dashboard/orders`)
       }} style={{ marginRight: '8px' }}>
       View Sales
      </Button>
      <Button type="primary"  onClick={()=>{
       dispatch(setGroupId(campaignsData?.group?.id?.toString()??''));
         dispatch(setActiveTab(`tab6` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
         navigate(`/dashboard/users`, { state: { route:"prevRouteGroup"  } })
       }} style={{ marginRight: '8px' }}>
       View Users
      </Button>
      <Button type="primary"  onClick={()=>{
         dispatch(setActiveTab(campaignsData?.group.is_rep_group?`tab13`:`tab3` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
         navigate(`/edit-groups/${campaignsData?.group?.id?.toString()??''}`, { state: { route:"prevRouteGroup"  } })
       }} style={{ marginRight: '8px' }}>
       View Organization
      </Button>
      <Button type="primary"  onClick={()=>{
       setIsLeaderBoardVisible(true)
       }} style={{ marginRight: '8px' }}>
       View Leaderboard
      </Button>
     </div>
     
   
     </div>
     <div className="edit-campaign-container" >
     <Title level={2} className="editProduct">Organization Settings</Title>
     <div  style={{
      padding:20,
   

      
     }}>
  <Form
            onFinish={handleSubmitOrganizationSetting(onGroupSubmit)}
            layout="vertical"
            className="edit-campaign-form"
          
          >
        <Flex justify="space-between" align="center" className='flexRow' style={{
          gap:10
        }}>
      <Form.Item
         label="Seller default goal"
         validateStatus={organizationErrors.group_default_goal ? 'error' : ''}
         help={organizationErrors.group_default_goal ? organizationErrors.group_default_goal.message : ''}
         style={{width:350}}
       >
         <Controller
           name="group_default_goal"
           control={controlOrganizationSetting}
           render={({ field }) => (
             <CurrencyInput
             className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
             style={{
              height: "hj"
             }}
              placeholder="Please enter Goal"
              defaultValue={((groupData?.group_default_goal)??0)/100}
              value={field.value/100}
              decimalsLimit={2}
              onValueChange={(value) => {
                const centsValue = value ? parseFloat(value) * 100 : 0;
                field.onChange( centsValue );
              }}
              prefix="$"
            />
           )}
         />
       </Form.Item>
       
        <Form.Item
          label="Contact Threshold"
          validateStatus={organizationErrors.group_contacts_required ? 'error' : ''}
          help={organizationErrors.group_contacts_required ? organizationErrors.group_contacts_required.message : ''}
          style={{width:350}}
        >
          <Controller
            name="group_contacts_required"
            control={controlOrganizationSetting}
            render={({ field }) => (
              <Input type="number" {...field} placeholder='Enter contacts required count'/>
            )}
          />
        </Form.Item>
      </Flex>
      {(user?.scope === 'internal_admin'||user?.scope === 'rep') && <> <Divider />
      <Flex justify="space-between" align="center" className='flexRow' style={{
          gap:10
        }}>
      <Form.Item
          label="Platform Base fee percentage"
          validateStatus={organizationErrors.fee_platform_percentage ? 'error' : ''}
          help={organizationErrors.fee_platform_percentage ? organizationErrors.fee_platform_percentage.message : ''}
          style={{width:350}}
        >
          <Controller
            name="fee_platform_percentage"
            control={controlOrganizationSetting}
            render={({ field }) => (
              <Input type="number" {...field} placeholder='Enter base fee'
              disabled={(user?.scope === ('rep')&&!user?.permissions?.edit_platform_and_processing_fee)}
              />
            )}
          />
        </Form.Item>
  <Form.Item
    label="Platform base amount"
    validateStatus={organizationErrors.fee_platform_base ? 'error' : ''}
    help={organizationErrors.fee_platform_base ? organizationErrors.fee_platform_base.message : ''}
    style={{width:350}}
  >
    <Controller
      name="fee_platform_base"
      control={controlOrganizationSetting}
      render={({ field }) => (
        <Input type="number" {...field} placeholder='Enter base fee'  addonBefore="$" 
        disabled={(user?.scope === ('rep')&&!user?.permissions?.edit_platform_and_processing_fee)}
        />
      )}
    />
  </Form.Item>
</Flex>
</>}
<Row justify={'end'}>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isUpdating}
          disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_group)}
           >Update Organization settings</Button>
        </Form.Item>
        </Row>
</Form>

     </div>
     
   
     </div>
     <div className="edit-campaign-container" >
     <Title level={2} className="editProduct">Download Reports</Title>
     <div  style={{
      padding:20,
   

      
     }}>
       <Button onClick={() => handleDownload(id??"", "based_order")} icon={<DownloadOutlined />} style={{
            marginBottom: 10, marginRight: 40

          }}> { 'Download Order Report'}</Button>
          <Button onClick={() => handleDownload(id??"", "based_user")} icon={<DownloadOutlined />}
          style={{
            marginBottom: 10, marginRight: 40
          }}
          >{ 'Download User Report'}</Button>
          <Button onClick={() => handleDownload(id??"", "based_product")} icon={<DownloadOutlined />}
          style={{
            marginBottom: 10, marginRight: 40
          }}
          >  {'Download Product Report'}</Button>
         {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id??"", "finance_based_order")} icon={<DownloadOutlined />}
         style={{
          marginBottom: 10, marginRight: 40
        }}
         > Download Accounting Report </Button>}
            {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id??"", "based_frm_xml")} icon={<DownloadOutlined />}
         style={{
          marginBottom: 10, marginRight: 40
        }}
         > Download FRM XML Report </Button>}
     </div>
   
     </div>
     {((((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&user?.permissions?.create_order_manual)||user?.scope === ('internal_admin'))&&
     <div className="edit-campaign-container" >
     <Title level={2} className="editProduct">Link Order to Campaign</Title>
     <div  style={{
      padding:20,
   

      
     }}>
       <Button onClick={() =>  navigate(`/add-order/${campaignsData?.id}`, { state: { route:"prevRouteGroup"  } }) } icon={ <LinkOutlined/>} style={{
            marginBottom: 10, marginRight: 40

          }}> { 'Create Order'}</Button>
        
    
     </div>
   
     </div>}
    <div className="edit-campaign-container" style={{
      marginTop: '40px'
    }}>
      
      <Title level={2} className="editProduct">Edit Campaign</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="edit-campaign-form">
      <Row>
            <Col flex={3}>
      <Form.Item
  label="Campaign Seller Invite Link"
 
 
>
  <div style={{ display: 'flex', alignItems: 'center', gap: "20px"}}>
    <div style={{ display: 'flex', alignItems: 'center',width:"100%"}}>
      <Input
        value={`${baseUrl}signup/${campaignsData?.group?.id}`}
        readOnly
        addonAfter={<CopyOutlined onClick={copyToClipboard} />}
      />
    </div>
    <div id="myqrcode" style={{ display: 'flex', alignItems: 'center'}}>
      <QRCode
        value={`${baseUrl}signup/${campaignsData?.group?.id}?ref=qr`}
        size={60}
        style={{ marginRight: '8px' }}
      />
      <DownloadOutlined onClick={downloadCanvasQRCode} size={20} style={{fontSize:30}}/>
      <ExportOutlined style={{fontSize:30, marginLeft:10}} onClick={() => window.open(`${baseUrl}signup/${campaignsData?.group?.id}`, '_blank', 'noopener,noreferrer')} />
      
    </div>
  </div>
</Form.Item>

        <Form.Item
          label="Campaign Store Link"
          validateStatus={errors.campaign_link ? 'error' : ''}
          help={errors.campaign_link ? errors.campaign_link.message : ''}
        >
          <Controller
            name="campaign_link"
            control={control}
            rules={{
              required: 'Campaign Store Link is required',
              pattern: {
                value: /^[a-zA-Z0-9-_]+$/,
                message: 'Only URL-friendly characters (letters, numbers, hyphens, and underscores) are allowed'
              }
            }}
            
            render={({ field }) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                 <div style={{ display: 'flex', alignItems: 'center', width:"100%"}}>
              <Input
                addonBefore={`${baseUrl}raise/`}
                {...field}
                disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_group)}
                placeholder="Campaign Store Link"
                        addonAfter={<CopyOutlined onClick={copyCampaignToClipboard}/>}
              />
              </div>
              <div id="mycampaignqrcode" style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
      <QRCode
        value={`${baseUrl}raise/${campaignsData?.campaign_link}?ref=qr`}
        size={60}
        style={{ marginRight: '8px' }}
      />
    <DownloadOutlined  onClick={downloadCampaignCanvasQRCode}  style={{fontSize:30}}/>
    <ExportOutlined  style={{fontSize:30, marginLeft:10}} onClick={() => window.open(`${baseUrl}raise/${campaignsData?.campaign_link}?ref=qr`, '_blank', 'noopener,noreferrer')} />
    
    </div>
    </div>

              

            )}
          />
        </Form.Item>

     

        <Form.Item
          label="Youtube Video Link"
          validateStatus={errors.video_url ? 'error' : ''}
          help={errors.video_url ? errors.video_url.message : ''}
        >
          <Controller
            name="video_url"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter Video URL'/>
            )}
          />
        </Form.Item>
        <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Title"
          validateStatus={errors.title ? 'error' : ''}
          help={errors.title ? errors.title.message : ''}
        >
          <Controller
            name="title"
            control={control}
            rules={{ required: 'Title is required' }}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter Campaign Title'
              disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
              styles={{
                input: { color: textColor }, // Apply text color correctly
              }}
              addonAfter={
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                 

                  {/* Color Picker Popover */}
                  <Popover
                    content={
                      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                      <HexColorPicker color={textColor} onChange={(color) => {
                        setTextColor(color);
                        setHexTitleInput(color);
                        setHexTitleError(null);
                      }} />
                      <Input
                        value={hexTitleInput}
                        onChange={handleHexTitleChange}
                        placeholder="Enter hex code"
                        maxLength={7}
                      />
                      {hexTitleError && <span style={{ color: "red" }}>{hexTitleError}</span>}
                    </div>
                    }
                    trigger="click"
                    visible={visible}
                    onVisibleChange={setVisible}
                  >
                    <BgColorsOutlined style={{ cursor: "pointer" }} />
                  </Popover>
                </div>
              }/>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Pre-Sale-ID"
          validateStatus={errors.pre_sale_id ? 'error' : ''}
          help={errors.pre_sale_id ? errors.pre_sale_id.message : ''}
        >
          <Controller
            name="pre_sale_id"
            control={control}
            rules={{ required: 'pre_sale_id is required' }}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter Pre Sale ID'
              disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
              />
            )}
          />
        </Form.Item>
      </Flex>
        <Flex justify="space-between" align="center" className='flexRow'>

        <Form.Item
          label="Group Money Goal"
          validateStatus={errors.money_goal ? 'error' : ''}
          help={errors.money_goal ? errors.money_goal.message : ''}
        >
          <Controller
            name="money_goal"
            control={control}
            rules={{ required: 'Money goal is required' }}
            render={({ field }) => (
              <CurrencyInput
              className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
               placeholder="Please enter Goal"
               defaultValue={((campaignsData?.money_goal)??0)/100}
               value={field.value/100}
               decimalsLimit={2}
               onValueChange={(value) => {
                 const centsValue = value ? parseFloat(value) * 100 : 0;
                 field.onChange( centsValue );
               }}
               prefix="$"
             />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Money Raised"
          validateStatus={errors.money_raised ? 'error' : ''}
          help={errors.money_raised ? errors.money_raised.message : ''}
        >
          <Controller
            name="money_raised"
            control={control}
           
            render={({ field }) => (
              <Input type="text" 
              disabled
              placeholder='Enter Money Raised'
              value={(field.value as number / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              onChange={(e) => {
                // Remove non-numeric characters and convert to cents
                const centsValue = parseInt(e.target.value.replace(/[^\d]/g, ''), 10);
                field.onChange(centsValue);
              }}  />
            )}
          />
        </Form.Item>
        </Flex>
     

        <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Start Date and Time"
          validateStatus={errors.start_date ? 'error' : ''}
          help={errors.start_date ? errors.start_date.message : ''}
        >
          <Controller
            name="start_date"
            control={control}
            rules={{ required: 'Start date and time is required' }}
            render={({ field }) => (
              <DatePicker 
                {...field} 
                disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
                style={{ width: '100%' ,padding: 16,borderRadius: 0}} 
                showTime 
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Select Start Date and Time"
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="End Date and Time"
          validateStatus={errors.end_date ? 'error' : ''}
          help={errors.end_date ? errors.end_date.message : ''}
        >
          <Controller
            name="end_date"
            control={control}
            rules={{ required: 'End date and time is required' }}
            render={({ field }) => (
              <DatePicker 
                {...field} 
                style={{ width: '100%' ,padding: 16,borderRadius: 0}} 
                showTime 
                disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Select End Date and Time"
              />
            )}
          />
        </Form.Item>
        </Flex>
        <Form.Item
          label="Main Store Text"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}

            rules={{ required: 'Seller Default text is required' }}
            render={({ field}) => (
              <Flex justify="space-between" align="start" className='flexRow' style={{gap:20}}>
              <TextArea {...field} rows={8} placeholder='Enter your description....'
              // value={value?.replace(/\\n/g, "\n")}
              styles={{
                textarea: { color: blurbColor }, // Apply text color correctly
              }}
           
              />
              <Popover
                    content={
                      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                      <HexColorPicker color={blurbColor} onChange={(color) => {
                        setBlurbColor(color);
                        setHexInput(color);
                        setHexError(null);
                      }} />
                      <Input
                        value={hexInput}
                        onChange={handleHexChange}
                        placeholder="Enter hex code"
                        maxLength={7}
                      />
                      {hexError && <span style={{ color: "red" }}>{hexError}</span>}
                    </div>
    
                    }
                    trigger="click"
                    visible={visibleBlurbColor}
                    onVisibleChange={setVisibleBlurbColor}
                  >
                    <BgColorsOutlined style={{ cursor: "pointer" ,fontSize:20}} />
                  </Popover>
                  </Flex>
            )}
          />
        </Form.Item>
        <Row gutter={20} >
        <Col span={12}>
        <Form.Item
          label=""
          validateStatus={errors.is_closed ? 'error' : ''}
          help={errors.is_closed ? errors.is_closed.message : ''}
        >
          <Controller
            name="is_closed"
            control={control}
            render={({ field }) => (
            <div>
             <Checkbox checked={field.value} onChange={field.onChange} 
              disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
             />
            <Text style={{
              borderBottom: "none",
              color:"#1d2026"
            }}>
            Is Closed
            </Text>
            </div> 
            )}
          />
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          label=""
          validateStatus={errors.campaign_send_admin_1_send ? 'error' : ''}
          help={errors.campaign_send_admin_1_send ? errors.campaign_send_admin_1_send.message : ''}
        >
          <Controller
            name="campaign_send_admin_1_send"
            control={control}
            render={({ field }) => (
              <div>
                <Checkbox checked={field.value} onChange={field.onChange}
                 disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
                />
                <Text style={{
              borderBottom: "none",
              color:"#1d2026"
            }}>
           Send Sponsor Daily Summary Email
            </Text>
              </div> 
            )}
          />
        </Form.Item>
        </Col>
        </Row >
        <Row gutter={20} >
          <Col span={12}>
        <Form.Item
          label=""
          validateStatus={errors.is_closed ? 'error' : ''}
          help={errors.ship_promo_enabled ? errors.ship_promo_enabled.message : ''}
        >
          <Controller
            name="ship_promo_enabled"
            control={control}
            render={({ field }) => (
              <div> <Checkbox checked={field.value} onChange={field.onChange}
              disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
              /><Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Ship Promo Enabled</Text></div>
             
            )}
          />
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          label=""
          validateStatus={errors.campaign_send_rep_1 ? 'error' : ''}
          help={errors.campaign_send_rep_1 ? errors.campaign_send_rep_1.message : ''}
        >
          <Controller
            name="campaign_send_rep_1"
            control={control}
            render={({ field }) => (
              <div>
<Checkbox checked={field.value} onChange={field.onChange} 
 disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
/>
<Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Send Rep Daily Summary Email</Text>
              </div>
              
            )}
          />
        </Form.Item>
        {/* <Form.Item
          label=""
          validateStatus={errors.campaign_send_contact_goal_1_send ? 'error' : ''}
          help={errors.campaign_send_contact_goal_1_send ? errors.campaign_send_contact_goal_1_send.message : ''}
        >
          <Controller
            name="campaign_send_contact_goal_1_send"
            control={control}
            render={({ field }) => (
             <div>
              <Checkbox checked={field.value} onChange={field.onChange} />
              <Text style={{
              borderBottom: "none",
              color:"#1d2026"
            }}>Send Sponsor's Contacts Threshold Email</Text>
             </div> 
            )}
          />
        </Form.Item> */}
        </Col>
        </Row >
        <Row gutter={20}>
        <Col span={12}>
        <Form.Item
          label=""
          validateStatus={errors.donations_enabled ? 'error' : ''}
          help={errors.donations_enabled ? errors.donations_enabled.message : ''}
        >
          <Controller
            name="donations_enabled"
            control={control}
            render={({ field }) => (
              <div>
 <Checkbox checked={field.value} onChange={field.onChange} 
  disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
 />
 <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Donations Enabled</Text>
              </div>
             
            )}
          />
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          label=""
          validateStatus={errors.campaign_send_contact_end_1_send ? 'error' : ''}
          help={errors.campaign_send_contact_end_1_send ? errors.campaign_send_contact_end_1_send.message : ''}
        >
          <Controller
            name="campaign_send_contact_end_1_send"
            control={control}
            render={({ field }) => (
            <div>
<Checkbox checked={field.value} onChange={field.onChange} 
 disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
/>
               <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Send Sponsor's Contacts Closing Push Email</Text>
            </div>  
            )}
          />
        </Form.Item>
        </Col>
        </Row>
      
        <Row gutter={20}>
        <Col span={12}>
        <Form.Item
          label=""
          validateStatus={errors.leader_board_enabled ? 'error' : ''}
          help={errors.leader_board_enabled ? errors.leader_board_enabled.message : ''}
        >
          <Controller
            name="leader_board_enabled"
            control={control}
            render={({ field }) => (
              <div>
                <Checkbox checked={field.value} onChange={field.onChange} 
           disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}      
                />
                <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Leader Board enabled</Text>
              </div>
              
            )}
          />
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          label=""
          validateStatus={errors.recent_orders_enabled ? 'error' : ''}
          help={errors.recent_orders_enabled ? errors.recent_orders_enabled.message : ''}
        >
          <Controller
            name="recent_orders_enabled"
            control={control}
            render={({ field }) => (
              <div>
 <Checkbox checked={field.value} onChange={field.onChange} 
  disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
 />
                <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Recent Sales Enabled</Text>
              </div>
             
            )}
          />
        </Form.Item>
        </Col>
       
        </Row>
        {user?.scope === ('internal_admin')&&
        <Row gutter={20}>
        <Col span={12}>
        <Form.Item
          label=""
          validateStatus={errors.is_paid ? 'error' : ''}
          help={errors.is_paid ? errors.is_paid.message : ''}
        >
          <Controller
            name="is_paid"
            control={control}
            render={({ field }) => (
              <div>
                <Checkbox checked={field.value} onChange={field.onChange} />
                <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Is Paid</Text>
              </div>
              
            )}
          />
        </Form.Item>
        </Col>
     
       
        </Row>}
     
  

       
</Col>
<Col flex={2}>
<Form.Item
          label="Logo Image URL"
          validateStatus={errors.logo_image_url ? 'error' : ''}
          help={errors.logo_image_url ? errors.logo_image_url.message : ''}
          style={{
            paddingLeft: 20
          }}
        >
          <Checkbox
            checked={useDefaultLogo}
            onChange={(e) => {
              setUseDefaultLogo(e.target.checked);
              
            }}
            style={{
              paddingBlock:10
            }}
          >
            Use Default Logo
          </Checkbox>
          {(
              <Flex justify='center' align='center' vertical style={{
                border: "1px solid  #d9d9d9",
                paddingBottom: 40,
                paddingTop:40
               }}>
            <Controller
              name="logo_image_url"
              control={control}
              rules={{ required: 'Logo image is required' }}
              render={({ field }) => (
                <>
                  {(useDefaultLogo?defaultLogoUrl:logoImageUrl) && (
                    <img src={useDefaultLogo?defaultLogoUrl:logoImageUrl} alt="Logo" style={{ width: '256px', height: '105.6px' }} />
                  )}
               {!useDefaultLogo &&    <div style={{ display: 'flex', alignItems: 'center',flexDirection:"column"  }}>
                    <ImageUploader onUpload={(imageUrl) => handleLogoImageUpload(imageUrl)} width={640} height={264} aspectRatio={2.42}/>
                    <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center',height:'100%' }}>
                      <p  style={{
                        width: 300,
                        textAlign: "center",
color: "#6E7485"
          }} >
         <Text strong style={{
            borderBottom:"none",
            padding: 0
           }}> Important guidelines: </Text> For best results, please upload a 640 × 264. Supported format: <Text strong style={{
            borderBottom:"none",
            padding: 0
           }}> .jpg or .png</Text>

          </p>
          </div>
                    {/* <Tooltip title="For best results, please upload a 640 × 264 png or jpg">
                      <QuestionCircleOutlined style={{ marginLeft: 8, marginTop: 20, color: '#1890ff' }} />
                    </Tooltip> */}
                  </div>}
                </>
              )}
            />
            </Flex>
          )}
          

        </Form.Item>

        <Form.Item
          label="Hero Image URL"
          validateStatus={errors.hero_image_url ? 'error' : ''}
          help={errors.hero_image_url ? errors.hero_image_url.message : ''}
          style={{
            paddingLeft: 20,
          }}
        >
          <Checkbox
            checked={useDefaultHero}
            disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
            onChange={(e) => {
              setUseDefaultHero(e.target.checked);
              
            }}
             style={{
              paddingBlock:10
            }}
          >
            Use Default Hero
          </Checkbox>
          { (
              <Flex justify='center' align='center' vertical style={{
                border: "1px solid  #d9d9d9",
                paddingBottom: 40,
                paddingLeft:10,
                paddingRight:10,
                paddingTop:40
               }}>
            <Controller
              name="hero_image_url"
              control={control}
              
              rules={{ required: 'Hero image is required' }}
              render={({ field }) => (
                < >
                  {(useDefaultHero?defaultHeroUrl:heroImageUrl) && (
                    <img src={useDefaultHero?defaultHeroUrl:heroImageUrl} alt="Hero" style={{  display: 'flex', alignItems: 'center' ,width: '320px', height: '180px' }} />
                  )}
                {!useDefaultHero &&  <div style={{ display: 'flex', alignItems: 'center',flexDirection:"column" }}>
                    <ImageUploader onUpload={(imageUrl) => handleHeroImageUpload(imageUrl)} width={1600} height={900} aspectRatio={1.78}
                    disabled={(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&!user?.permissions?.edit_campaign)}
                    />
                    {/* <Tooltip title="For best results, please upload a 1600 × 900 png or jpg">
                      <QuestionCircleOutlined style={{ marginLeft: 8, marginTop: 20, color: '#1890ff' }} />
                    </Tooltip> */}
                        <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center',height:'100%' }}>
                      <p  style={{
                        width: 300,
                        textAlign: "center",
color: "#6E7485"
          }} >
           <Text strong style={{
            borderBottom:"none",
            padding: 0
           }}>  Important guidelines: </Text>For best results, please upload a 1600 × 900. Supported format:<Text strong style={{
            borderBottom:"none",
            padding: 0
           }}> .jpg or .png</Text>

          </p>
          </div>
                  </div>}
                </>
              )}
            />
            </Flex>
          )}
        </Form.Item>
     
        </Col>
        </Row>
        <Form.Item
          label="Organization"
          validateStatus={errors.group_name ? 'error' : ''}
          help={errors.group_name ? errors.group_name.message : ''}
        >
          <Controller
            name="group_name"
            control={control}
            rules={{ required: 'Organization is required' }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  readOnly
                  placeholder="Select Organization"
                  onClick={handleDrawerOpen}
                  addonAfter={<DownOutlined  onClick={handleDrawerOpen}/>}
                />
              </>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Catalog"
          validateStatus={errors.catalog_name ? 'error' : ''}
          help={errors.catalog_name ? errors.catalog_name.message : ''}
        >
          <Controller
            name="catalog_name"
            control={control}
            rules={{ required: 'Catalog is required' }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  readOnly
                  onClick={handleDrawerCatalogOpen}
                  placeholder="Select a catalog"
                  addonAfter={<DownOutlined  onClick={handleDrawerCatalogOpen}/>}
                />
              </>
            )}
          />
        </Form.Item>
        <Row justify={'end'}>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}
          >Update Campaign</Button>
        </Form.Item>
        </Row>

        {isError && <p>Failed to update campaign. Please try again.</p>}
        {isSuccess && <p>Campaign updated successfully!</p>}
      </Form>

      <Drawer
        title={<>
        <Text>Select Organization</Text>
        <Input  value={searchPreSaleId}
        onChange={(e)=>{
         
          setSearchPreSaleId(e.target.value)
        }}/>
        </>}
        width={400}
        loading={isLoadingGroups||isGroupsLoading||isGroupsFetching}
        
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <>
      
        </>
        <List
        //@ts-ignore
          dataSource={searchPreSaleId?groups?.filter(group=>!group.is_rep_group):groupsData?.data ?? []}
          renderItem={(group: Group) => (
            <List.Item key={group.id}>
              <Radio
                checked={selectedGroup?.id == group.id}
                onChange={() => handleGroupSelect(group)}
              >
                {group.name}
              </Radio>
            </List.Item>
          )}
        />
      </Drawer>
      <Drawer
        title="Select Catalog"
        width={400}
        onClose={handleDrawerCatalogClose}
        visible={isDrawerCatalogVisible}
      >
        <List
          dataSource={catalogsData ?? []}
          renderItem={(catalog: Catalog) => (
            <List.Item key={catalog.id}>
              <Radio
                checked={selectedCatalog?.id == catalog.id}
                onChange={() => handleCatalogSelect(catalog)}
              >
                {catalog.name}
              </Radio>
            </List.Item>
          )}
        />
      </Drawer>
      {isLeaderBoardVisible&&
      <PublicLeaderBoard
      studentData={studentData}
      error={errorFetchingTagLeaderBoard}
      isLoadingStudents={isLoadingStudents}
      isFetching={isFetching}
      isClickable={true}
      campaignId={id || "0"}
      groupId={campaignsData?.group?.id ?campaignsData?.group?.id : 0}
      visible={isLeaderBoardVisible}
      onClose={handleLeaderBoardClose}
      isMobile={false} // Or pass a prop to determine if the device is mobile
    />}

    </div>
    </>
  );
};

export default EditCampaign;

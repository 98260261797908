import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Typography,
  Divider,
  Space,
  Button,
  Spin,
  Flex,
  Modal,
  message,
  Form,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CodeSandboxOutlined,
  CreditCardFilled,
  DollarOutlined,
  DownloadOutlined,
  FileImageOutlined,
  MessageOutlined,
  MinusOutlined,
  PayCircleOutlined,
} from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import '../styles/ProfileSetup.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandHoldingDollar,
  faSackDollar,
} from '@fortawesome/free-solid-svg-icons';
import { convertUTCDateToLocalDate, formatWithCommas } from '../utils';
import Hand from '../assets/svg/Hand';
import Give from '../assets/svg/Give';
import OrdersTable from './OrdersTable';
import DashboardOrdersTable from './DashboardOrdersTable';
import CampaignCard from './CampaignCard';
import { useDownloadReportMutation, useEditCampaignMutation, useEditCampaignOnlyMutation, useGetCampaignsQuery } from '../services/admin/inventoryApi';
import { useNavigate } from 'react-router-dom';
import ActiveCampaignCard from './ActiveCampaignCard';
import { useDispatch, useSelector } from 'react-redux';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { setActiveTab } from '../slices/admin/activeTabSlice';
import { RootState } from '../store';
import Unauthorized from './UnAuthorised';
import { setCampaignId, setGroupId, setUserId } from '../slices/admin/adminUserSlice';
import PublicLeaderBoard from './PublicLeaderBoard';
import { Campaign } from '../types.ts';
import { Controller, useForm } from 'react-hook-form';

const { Text, Title } = Typography;

const OrganizerProfileSetup = () => {
    const [downloadReport,{isLoading:isLoadingReport}] = useDownloadReportMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
   // Pagination state
   const [skip, setSkip] = useState(0);
   const { register, handleSubmit, control, formState: { errors,isDirty },setValue:setTime, watch } = useForm({
    defaultValues: {
      
      end_date: null,
      
    }
  });
   const [isModalTimeOpen, setIsModalTimeOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
   const [showProfitReport, setShowProfitReport] = useState<boolean>();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [id, setId] = useState('');
   const [updateCampaign, { isLoading:isUpdating, isError, isSuccess }] = useEditCampaignMutation();
   const [isLeaderBoardVisible, setIsLeaderBoardVisible] = useState(false); // Sta
   const limit = 5; // Number of campaigns per fetch
   const [campaigns, setCampaigns] = useState<any[]>([]);
   const [isFetching, setIsFetching] = useState(false);
   const containerRef = useRef<HTMLDivElement>(null);
   dayjs.extend(customParseFormat);
   // Fetch campaigns
   const { data, isLoading,isFetching : isCampaignFetching} = useGetCampaignsQuery({ skip, limit });

   // Update campaigns when new data is fetched
   useEffect(() => {
    if (data?.data) {
      setCampaigns((prev) => {
        const combinedData = [...prev, ...(data?.data??[])];
        return Array.from(new Map(combinedData.map((item) => [item.id, item])).values());
      });
    }
  }, [data]);
 
   // Scroll event listener
   const handleScroll = useCallback(() => {
     if (!containerRef.current || isFetching) return;
 
     const { scrollLeft,scrollWidth } = containerRef.current;
     console.log(scrollLeft,scrollWidth)
     if ((scrollWidth-scrollLeft) <= 1200) {
       setIsFetching(true);
       setSkip((prevSkip) => prevSkip + limit);
     }
   }, [isFetching]);
 
   // Attach event listener
   useEffect(() => {
     const container = containerRef.current;
     if (container) {
       container.addEventListener("scroll", handleScroll);
     }
     return () => {
       if (container) {
         container.removeEventListener("scroll", handleScroll);
       }
     };
   }, [handleScroll]);
 
   useEffect(() => {
     if (data) setIsFetching(false);
   }, [data]);
  const user = useSelector((state: RootState) => state.adminUser.user);
  const showModal = async (recordId: string) => {
    if(!recordId) return
    recordId&& setId(recordId)
    setIsModalOpen(true);
    try {
      // Call the API and expect a response in CSV format
      //@ts-ignore
      const response = await downloadReport({campaignId: recordId,reportType: "based_group_order" }).unwrap();
      // @ts-ignore
      
      // Check if response is a Blob or string
      // @ts-ignore
    if(response){
      setShowProfitReport(true)
      }
    } catch (err) {
      console.error('Download Error:', err);
      //@ts-ignore
      setShowProfitReport(false)
    }

  };
  const handleCancel = () => {
    setShowProfitReport(false)
    setIsModalOpen(false);
  };
  const showExtendModal = (campaign: Campaign) => {
    const targetDate =campaign?.end_date?convertUTCDateToLocalDate(new Date(campaign?.end_date) as Date):null
    //@ts-ignore
    setTime('end_date', targetDate?dayjs(targetDate) as unknown :null)
    setSelectedCampaign(campaign);
    setIsModalTimeOpen(true);
  };
  const onSubmit = async (data: any) => {
    if (!selectedCampaign) return;

    const updatedData = {
      id: selectedCampaign.id,
      
    };

    try {
      //@ts-ignore
      await updateCampaign({ id: parseInt(selectedCampaign.id || '0'), ...selectedCampaign,...data, group_id : selectedCampaign?.group.id ?? null,
      catalog_id :selectedCampaign?.catalog.id ?? null }).unwrap();
      message.success("Campaign extended successfully!");
      setIsModalOpen(false);
    } catch (error) {
      //@ts-ignore
      message.error(error?.data?.detail || "Failed to extend campaign.");
    }
  };


const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const disabledDate = (current: dayjs.Dayjs | null): boolean => {
  // Disable dates before today (returns boolean only)
  return !!current && current.isBefore(dayjs().startOf('day'));
};

const disabledTime = (current: dayjs.Dayjs | null) => {
  if (!current) return {};

  const now = dayjs();
  const isToday = current.isSame(now, 'day');
  
  if (isToday) {
    const currentHour = now.hour();
    const currentMinute = now.minute();
    
    // Disable hours less than 1 hour from now
    const disabledHours = range(0, 24).filter((hour) => hour <= currentHour);

    // Disable minutes if the selected hour is the current hour
    const disabledMinutes = (selectedHour: number) => 
      selectedHour === currentHour ? range(0, 60).filter((min) => min <= currentMinute) : [];

    return {
      disabledHours: () => disabledHours,
      disabledMinutes,
      disabledSeconds: () => [],
    };
  }
  return {};
};
  const handleDownload = async (campaignId: string, reportType: 'based_order' | 'based_user' | 'based_product'| 'finance_based_order'|'based_frm_xml'|'based_group_order') => {
    try {
      // Call the API and expect a response in CSV format
      const response = await downloadReport({ campaignId, reportType }).unwrap();
      console.log(response,"ygh")
      // @ts-ignore
      
      // Check if response is a Blob or string
      // @ts-ignore
      if (response instanceof Blob) {
        const url = URL.createObjectURL(response);
  
        const link = document.createElement('a');
        link.href = url;
        if(reportType==='based_frm_xml'){
          link.setAttribute('download', `report_${campaignId}_${reportType}.xml`);
        }else{
          link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        }
       
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else if (typeof response === 'string') {
        // If response is a string, handle it as CSV content
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        message.error('Unexpected response format');
      }
    } catch (err) {
      console.error('Download Error:', err);
      //@ts-ignore
      if(err?.data?.detail){
        // @ts-ignore
        message.error(err?.data?.detail)
        return
      }else{
        message.error('Failed to download report');

      }
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* @ts-ignore */}
      {campaigns.filter((item) => {
        return !item?.is_closed;
      })?.length > 0 && 
      <Flex>
<Title level={3}>Active Campaigns</Title>

      </Flex>
      
      
      }
      <div
        ref={containerRef}
        style={{
          display: "flex",
          marginBottom: "10px",
          width: "100%",
          overflowX: "auto",
          scrollBehavior: "smooth",
          scrollbarWidth:"none"
        }}
      >
        {isLoading ? (
          <Spin />
        ) : (
          campaigns
            ?.filter((item) => {
              return !item.is_closed;
            })
            .map((campaign,index) => {
              const amountRaised = campaign.money_raised
                ? `$${formatWithCommas(campaign.me_amount)}`
                : 'Not Raised';
              const endDate = convertUTCDateToLocalDate(
                new Date(campaign?.end_date) as Date
              );
        const currentDate = convertUTCDateToLocalDate(new Date());
        const timeDifference = (endDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);

              return (
                <>
                <Flex
                  vertical
                  style={{
                    width: 650,
                    marginLeft: 10,
                    borderRadius: 12,
                    backgroundColor: '#ffffff'
                  }}
                  align="center"
                >
                    {!campaign.is_closed && timeDifference < 24&&timeDifference>0 && (
              <Button type="primary" danger onClick={(e) => {showExtendModal(campaign)
                e.stopPropagation(); // Prevent row click
                e.preventDefault()
              }}>
                Extend Campaign
              </Button>
            )}
                  <ActiveCampaignCard
                    key={campaign.id}
                    link={campaign.campaign_link}
                    groupId={campaign.group_id}
                    title={campaign.title}
                    blurb={campaign.blurb}
                    campaignId={campaign?.id}
                    name={campaign.title}
                    endDate={
                      isNaN(endDate.getTime())
                        ? 'Invalid Date'
                        : endDate.toLocaleString()
                    }
                    status={campaign.is_closed ? 'Closed' : 'Active'}
                    onDetailsClick={() => {
                      navigate(`/edit-campaign/${campaign?.id}`, {
                        state: { campaignId: campaign?.id },
                      });
                      //@ts-ignore
                      // setCampaignId(campaign?.id);
                    }}
                  />
                    <Card
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      border: 'none',
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                    }}
                  >
                    <div
                      style={{
                        gap: 15,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                 <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                 }}>
                    
                    <Button type="primary"  onClick={()=>{
       dispatch(setUserId(null));
         dispatch(setCampaignId(campaign?.id??""));
         dispatch(setActiveTab(`tab7` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
         navigate(`/dashboard/orders`)
       }} style={{ marginRight: '8px' }}>
        Sales
      </Button>
      <Button type="primary"  onClick={()=>{
       dispatch(setGroupId(campaign?.group?.id?.toString()??''));
         dispatch(setActiveTab(`tab6` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
         navigate(`/dashboard/users`, { state: { route:"prevRouteGroup"  } })
       }} style={{ marginRight: '8px' }}>
        Users
      </Button>
      <Button type="primary"  onClick={()=>{
         dispatch(setActiveTab(campaign?.group.is_rep_group?`tab13`:`tab3` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
         navigate(`/edit-groups/${campaign?.group?.id?.toString()??''}`, { state: { route:"prevRouteGroup"  } })
       }} style={{ marginRight: '8px' }}>
        Organization
      </Button>
      <Button type="primary" onClick={(e)=> {
          showModal(campaign?.id);
          e.stopPropagation(); // Prevent row click
        e.preventDefault()
       }} icon={<DownloadOutlined  size={18} style={{
          color:"#ffffff",
        }}/>} style={{  width:"170px"}}>  <Text  style={{
          color:"#ffffff",
          padding: "4px 1px"
        }}>Download Reports</Text> </Button>
                    </div>  
    
      
      {/* <Button type="primary"  onClick={()=>{
       setIsLeaderBoardVisible(true)
       }} style={{ marginRight: '8px' }}>
       View Leaderboard
      </Button> */}
     
                    </div>
                  </Card>
                  <Card
                   
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: "space-between",
                      border: 'none',
                      
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                    }}
                  >
                    <div
                      style={{
                        gap: 15,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <Meta
                        title={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              gap: 15,
                            }}
                          >
                            <Button
                              type="primary"
                              shape="circle"
                              icon={
                                <CodeSandboxOutlined
                                  style={{
                                    color: '#396AFF',
                                    borderRadius: 50,
                                    fontSize: '20px',
                                  }}
                                />
                              }
                              onClick={() => {
                                dispatch(
                                  setActiveTab(
                                    `tab9` as
                                      | 'tab1'
                                      | 'tab2'
                                      | 'tab3'
                                      | 'tab4'
                                      | 'tab5'
                                      | 'tab6'
                                      | 'tab7'
                                      | 'tab8'
                                      | 'tab9'
                                      | 'tab11'
                                  )
                                );
                              }}
                              style={{
                                backgroundColor: '#E7EDFF',
                                padding: 25,
                                boxShadow: 'none',
                              }}
                            />

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                              }}
                            >
                              <Title
                                style={{
                                  fontSize: 12,
                                  color: 'grey',
                                  marginBottom: 0,
                                }}
                              >
                              Campaign Total Raised
                              </Title>
                              {/* @ts-ignore */}
                              {`$${formatWithCommas(campaign?.money_raised)}`}
                            </div>
                          </div>
                        }
                      />
                      <Meta
                        title={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',

                              alignItems: 'center',
                              gap: 15,
                            }}
                          >
                            <Button
                              type="primary"
                              shape="circle"
                              icon={
                                <FontAwesomeIcon
                                  icon={faSackDollar}
                                  size="lg"
                                  color="#FFBB38"
                                />
                              }
                              onClick={() => {
                                dispatch(
                                  setActiveTab(
                                    `tab9` as
                                      | 'tab1'
                                      | 'tab2'
                                      | 'tab3'
                                      | 'tab4'
                                      | 'tab5'
                                      | 'tab6'
                                      | 'tab7'
                                      | 'tab8'
                                      | 'tab9'
                                      | 'tab11'
                                  )
                                );
                              }}
                              style={{
                                backgroundColor: '#FFF5D9',
                                padding: 25,
                                boxShadow: 'none',
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                              }}
                            >
                              <Title
                                style={{
                                  fontSize: 12,
                                  color: 'grey',
                                  marginBottom: 0,
                                }}
                              >
                               Campaign Total Sales
                              </Title>
                              {/* @ts-ignore */}
                              {campaign?.order_count}
                            </div>
                          </div>
                        }
                      />
                    
                    </div>
                  </Card>
                </Flex>
               {(campaigns
            ?.filter((item) => {
              return !item.is_closed;
            }).length===index+1)&& isCampaignFetching&&  <Spin />}
                </>
              );
            })
            
        )}
      </div>
      <Modal
        title="Extend Campaign"
        
        open={isModalTimeOpen}
        onCancel={()=>setIsModalTimeOpen(false)}
        loading={isUpdating}
        onOk={handleSubmit(onSubmit)}
        confirmLoading={isLoading}
      >
        <Form layout="vertical">
          <Form.Item
            label="End Date and Time"
            validateStatus={errors.end_date ? "error" : ""}
            help={errors.end_date ? errors.end_date.message : ""}
          >
            <Controller
              name="end_date"
              control={control}
              rules={{ required: "End date and time is required" }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  style={{ width: "100%", padding: 8 }}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="Select End Date and Time"
                  onChange={(date) => field.onChange(date)}
                  disabledDate={disabledDate}
      disabledTime={disabledTime}
                />
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="Download Reports" open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel}>
          <Spin spinning={isLoadingReport}>
          <Button onClick={() => handleDownload(id, "based_order")} icon={<DownloadOutlined />} style={{
            width:"100%",
            marginBottom: 10
          }}> { 'Download Order Report'}</Button>
          {showProfitReport&& <Button onClick={() => handleDownload(id, "based_group_order")} icon={<DownloadOutlined />} style={{
            width:"100%",
            marginBottom: 10
          }}> { 'Download Profit Report'}</Button>}
          <Button onClick={() => handleDownload(id, "based_user")} icon={<DownloadOutlined />}
          style={{
            width:"100%",
            marginBottom: 10
          }}
          >{ 'Download User Report'}</Button>
          <Button onClick={() => handleDownload(id, "based_product")} icon={<DownloadOutlined />}
          style={{
            width:"100%",
            marginBottom: 10
          }}
          >  {'Download Product Report'}</Button>
         {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id, "finance_based_order")} icon={<DownloadOutlined />}
         style={{
          width:"100%",
          marginBottom: 10
        }}
         > Download Accounting Report </Button>}
            {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id, "based_frm_xml")} icon={<DownloadOutlined />}
         style={{
          width:"100%",
          marginBottom: 10
        }}
         > Download FRM XML Report </Button>}
         </Spin>
      </Modal>
     
     
    </div>
  );
};

export default OrganizerProfileSetup;

import React, { useState, useEffect } from 'react';
import { Table, Pagination, Spin, message, Button, Drawer, Form, Input, Select, Flex, Tag } from 'antd';
import { useGetOrdersQuery, useGetProductByIdMutation, useTagUserForOrderMutation } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import '../styles/OrdersTable.scss';
import { convertUTCDateToLocalDate } from '../utils';


const { Option } = Select;




const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount / 100);
};
interface UserOptionsProps { 
  userId:number,

  fullName: string;
  email: string;

}
interface OrderTableProps {
campaign_id?: number,
user_id?: number
}
const OrdersTable: React.FC<OrderTableProps> = ({campaign_id,user_id}) => {
  console.log(campaign_id,user_id,"hejn")
  const { currentPage, pageSize, onPageChange,onPageSizeChange } = usePagination(1,5);
  const { data, error, isLoading, isFetching } = useGetOrdersQuery({
    skip: (currentPage - 1) * pageSize,
    limit: 5,
    campaign_id,
    user_id
  });


  const [tagUserForOrder, { isLoading:isLoadingTagUser, isError, isSuccess }] = useTagUserForOrderMutation();
  const [selectedUser, setSelectedUser] = useState(null);



  const [selectedRowData, setSelectedRowData] = useState<any>({
    recipient_first_name: "",
    user_id: "",
    email: "",
    recipient_last_name: "",
    recipient_address_1: "",
    recipient_address_2: "",
    recipient_address_city: "",
    recipient_address_state: "",
    recipient_address_zip: "",
    recipient_phone: 0,
    amount_donation: 0,
    amount_shipping: 0,
    amount_total: 0,
    product_ids: [],
    card_token: "",
    is_quote: false,
    quote_number: 0,
    id: 0,
    created_at: "",
    shipping_quote: 0,
    is_success: false,
    message: "",
    
  });
 
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to control the drawer visibility
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const [getProductById, {data:dataLoadingProduct, isLoading: isLoadingProduct, isError: isErrorProduct, isSuccess : isSuccessProduct}] = useGetProductByIdMutation();
  const columns = [
    {
      title: 'Total Product Cost ($)',
      dataIndex: 'amount_product',
      key: 'amount_product',
      render: (_: any, record: any) => `$${record.amount_product/100}`,
      width: isDesktop ? '33%' : 50,
    },
    {
      title: 'Donation Cost ($)',
      dataIndex: 'amount_donation',
      key: 'amount_donation',
      width: isDesktop ? '33%' : 50,
      render: (_: any, record: any) => `$${record.amount_donation/100}`,
    },
    
    {
      title: 'Name',
      dataIndex: 'recipient_first_name',
      width: isDesktop ? '33%' : 50,
      key: 'recipient_first_name',
      sorter: (a: any, b: any) => a.recipient_first_name.localeCompare(b.recipient_first_name),
      render: (_: any, record: any) => `${record.recipient_first_name} ${record.recipient_last_name}`,
    },
    {
      title: 'Campaign Name',
      dataIndex: 'name',
      width: isDesktop ? '33%' : 50,
      key: 'name',
      render: (_: any, record: any) => `${record.campaign.title}`,
    },
 
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: isDesktop ? '33%' : 50,
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: isDesktop ? '33%' : 50,
      sorter: (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (text: string) => {
        const targetDate = convertUTCDateToLocalDate(new Date(text) as Date);
      
        return isNaN(targetDate.getTime()) ? 'Invalid Date' : targetDate.toLocaleString();
      },
    },
    
    // {
    //   title: 'Is Quote',
    //   dataIndex: 'is_quote',
    //   key: 'is_quote',
    //   render: (text: boolean) => (text ? 'Yes' : 'No'),
    // },
    // {
    //   title: 'Is Success',
    //   dataIndex: 'is_success',
    //   key: 'is_success',
    //   render: (text: boolean) => (text ? 'Yes' : 'No'),
    // },
    // {
    //   title: 'Campaign Id',
    //   dataIndex: 'campaign_id',
    //   key: 'campaign_id',
    //   sorter: (a: any, b: any) => a.campaign_id.localeCompare(b.campaign_id),
    // },
    // {
    //   title: 'User ID',
    //   dataIndex: 'user_id',
    //   key: 'user_id',
    //   sorter: (a: any, b: any) => a.user_id - b.user_id,
    // },
    // Add more columns as necessary
  ];
  useEffect(() => {
  

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    if (error) {
      message.error('Failed to load orders');
    }
  }, [error]);

  const handleRowClick = async(record: any) => {
    setSelectedRowData(record);
    try{
      await getProductById({product_ids:record.product_ids}).unwrap();
    }catch{
   
    }
   
    setIsDrawerOpen(true);
  };
  const onSubmit = async () => { console.log("v bnm,")
    try {
      let selectedOrder={id:selectedRowData.id,user_id:selectedUser};
     
      await tagUserForOrder(selectedOrder).unwrap();
      message.success('User Linked successfully!');
      handleCloseDrawer()
      console.log('user updated:', data);
      
    } catch (error) {
      // @ts-ignore
      message.error(error?.data?.detail || 'Something went wrong, Please try again.');
      console.error('Failed to add user:', error);
    }
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedRowData({
      recipient_first_name: "",
      //user_id: "",
      email: "",
      recipient_last_name: "",
      recipient_address_1: "",
      recipient_address_2: "",
      recipient_address_city: "",
      recipient_address_state: "",
      recipient_address_zip: "",
      recipient_phone: 0,
      amount_donation: 0,
      amount_shipping: 0,
      amount_total: 0,
      //product_ids: [],
      card_token: "",
      is_quote: false,
      //quote_number: 0,
      id: 0,
      created_at: "",
      shipping_quote: 0,
      is_success: false,
      //message: ""

    });
  };



  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <Table
            columns={ columns}
            loading={isFetching}
            dataSource={data?.data || []}
            pagination={false}
            scroll={{ x: !isDesktop?'100vw':undefined }}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowClassName="clickable-row"
            footer={()=> <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={data?.count}
              onChange={onPageChange}
              // showSizeChanger={true}  // Allow page size change
              // onShowSizeChange={(_, newSize) => onPageSizeChange(newSize)} // Handle page size changes
              // pageSizeOptions={["5",'10', '20', '50', '100']}  // Set options for page size
            />}
          />
         
        </>
      )}
      <Drawer open={isDrawerOpen} onClose={handleCloseDrawer} title={"Order Details"} width={500}>
        {selectedRowData && (
           <Spin spinning={isLoadingProduct}>
         <Form layout="vertical">
         <Flex justify="space-between" align="center" style={{
          gap:5
         }}>
           <Form.Item label="Full Name">
             <Input
               value={`${selectedRowData.recipient_first_name} ${selectedRowData.recipient_last_name}`}
               readOnly
             />
           </Form.Item>
           <Form.Item label="Email">
             <Input value={selectedRowData.email} readOnly />
           </Form.Item>
         </Flex>
         <Form.Item label="Address 1">
                   <Input value={selectedRowData.recipient_address_1} readOnly />
                 </Form.Item>
                 <Form.Item label="Address 2">
                   <Input value={selectedRowData.recipient_address_2} readOnly />
                 </Form.Item>
           
                 <Flex justify="space-between" align="center" style={{
          gap:5
         }}>
                 <Form.Item label="City">
                   <Input
                     value={selectedRowData.recipient_address_city}
                     readOnly
                   />
                 </Form.Item>
                 <Form.Item label="State">
                   <Input value={selectedRowData.recipient_address_state} readOnly />
                 </Form.Item>
               </Flex>
               <Flex justify="space-between" align="center" style={{
          gap:5
         }}>
                 <Form.Item label="ZIP">
                   <Input
                     value={selectedRowData.recipient_address_zip}
                     readOnly
                   />
                 </Form.Item>
                 <Form.Item label="Phone">
                   <Input value={selectedRowData.recipient_phone} readOnly />
                 </Form.Item>
               </Flex>
            {/* <p><strong>Student Name FreeForm:</strong> {selectedRowData.student_name_freeform}</p> */}
            <Flex justify="space-between" align="center" style={{
          gap:5
         }}>
            <Form.Item label="Donation Amount">
                   <Input value={formatCurrency(selectedRowData.amount_donation)} readOnly />
                 </Form.Item>
                 <Form.Item label="Created At">
                   <Input
                     value={new Date(selectedRowData.created_at).toLocaleString()}
                     readOnly
                   />
                 </Form.Item>
                
               </Flex>
               <Flex justify="space-between" align="center" style={{
          gap:5
         }}>
               <Form.Item label="Products">
                  <>
                  {!isErrorProduct&&(Object.values(dataLoadingProduct ?? {}) as string[]).map((value, index) => (
      <Tag key={index} >
        {value}
      </Tag>
    ))}
    </>
                 </Form.Item>
                 <Form.Item label="Product Amount">
                   <Input value={formatCurrency(selectedRowData.amount_product)} readOnly />
                 </Form.Item>
               </Flex>
               
            {/* <p><strong>Product IDs:</strong> {selectedRowData.product_ids.join(', ')}</p> */}
            {/* <p><strong>Quote Number:</strong> {selectedRowData.quote_number}</p> */}
            {/* <p><strong>Campaign ID:</strong> {selectedRowData.campaign_id}</p> */}
            {/* <p><strong>User ID:</strong> 
              {  
                selectedRowData.user_id 
              }
              {selectedRowData.student_name_freeform&&<>
                <Select
                  showSearch
                  placeholder="Select a user"
                  optionFilterProp="children"
                  onSearch={handleSearch}
                  onChange={(value) => {setSelectedUser(value)}}
                  style={{ width: '100%' }}
                  
                >
                  {userOptions.map(option => (
                    <Option key={option.userId} value={option.userId}>
                      {option.fullName} ({option.email})
                    </Option>
                  ))}
                </Select>
                <Button type="primary" onClick={onSubmit} loading={isLoadingTagUser}>Tag User</Button>
                </>}
              
            </p> */}
            {/* <p><strong>Created At:</strong> {new Date(selectedRowData.created_at).toLocaleString()}</p> */}
            {/* <p><strong>Message:</strong> {selectedRowData.message}</p> */}
          </Form>
          </Spin>
        )}
      </Drawer>
    
    </>
  );
};

export default OrdersTable;

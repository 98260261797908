import React, { useState, useEffect, useRef } from 'react';
import { useUploadFileMutation } from '../services/utils';
import { Avatar, Button, Drawer, Upload, message } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import '../styles/ImageUploader.scss';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';

interface ImageUploaderProps {
  onUpload: (url: string) => void;
  width?: number;
  height?: number;
  icon?: boolean;
  aspectRatio?: number;
  disabled?: boolean;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onUpload,
  width = 512,
  height = 512,
  icon = false,
  aspectRatio = 1,
  disabled = false
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [processedImage, setProcessedImage] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop | null>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [scaledWidth, setScaledWidth] = useState<number>(width);
  const [scaledHeight, setScaledHeight] = useState<number>(height);
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const imageRef = useRef<HTMLImageElement | null>(null);
  const originalImageRef = useRef<HTMLImageElement | null>(null);

  const base64ToBlob = (base64: string, mimeType: string): Blob => {
    const byteCharacters = atob(base64.split(',')[1]); // Decode base64
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  // Handle File Selection
  const handleFileChange = async (file: RcFile) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageSrc(reader.result as string);
      setDrawerVisible(true);
    };
    return false;
  };

  // Resize image for preview and adjust cropper size
  useEffect(() => {
    if (!imageSrc) return;

    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      originalImageRef.current = img;
      const { newSrc, newWidth, newHeight } = processImage(img, aspectRatio, width, height);
      setProcessedImage(newSrc || null);
      setScaledWidth(newWidth);
      setScaledHeight(newHeight);

      // Set the crop area to fit the entire image initially
      setCrop({
        unit: 'px',
        width: newWidth,
        height: newHeight,
        x: 0,
        y: 0,
      });
    };
  }, [imageSrc, aspectRatio, width, height]);

  // Process image for correct aspect ratio and white background
  const processImage = (image: HTMLImageElement, aspectRatio: number, maxWidth: number, maxHeight: number) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) return { newSrc: null, newWidth: 0, newHeight: 0 };

    const imgWidth = image.naturalWidth;
    const imgHeight = image.naturalHeight;

    let newWidth = maxWidth;
    let newHeight = maxWidth / aspectRatio;

    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = maxHeight * aspectRatio;
    }

    const scaleFactor = Math.min(newWidth / imgWidth, newHeight / imgHeight);
    newWidth = imgWidth * scaleFactor;
    newHeight = imgHeight * scaleFactor;

    // Set final canvas dimensions
    canvas.width = maxWidth;
    canvas.height = maxHeight;

    // Fill background with white (instead of black)
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, maxWidth, maxHeight);

    // Center image inside canvas
    const xOffset = (maxWidth - newWidth) / 2;
    const yOffset = (maxHeight - newHeight) / 2;
    context.drawImage(image, xOffset, yOffset, newWidth, newHeight);

    return { newSrc: canvas.toDataURL('image/jpeg', 0.8), newWidth, newHeight };
  };

  // Handle Upload
  const handleUpload = async () => {
    if (!processedImage || !imageRef.current || !originalImageRef.current || !crop) return;
  
    try {
      // Convert Base64 to Blob
      const imageBlob = base64ToBlob(processedImage, 'image/jpeg');
  
      const formData = new FormData();
      formData.append('file', imageBlob, 'image.jpg');
  
      const response = await uploadFile(formData).unwrap();
  
      setImageSrc(null);
      setProcessedImage(null);
      setDrawerVisible(false);
      onUpload(response.response);
    } catch (error) {
      message.error('Error uploading image');
      console.error('Error uploading image', error);
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Upload accept=".jpg,.jpeg,.heic,.png" showUploadList={false} beforeUpload={handleFileChange}>
        {icon ? (
          <div className="edit-icon-overlay" onClick={handleUpload} style={{ cursor: 'pointer', position: 'absolute', bottom: '10px', right: '10px', borderRadius: '50%', padding: '5px' }}>
            <Avatar size="large" icon={<EditOutlined style={{ width: 30, height: 'auto' }} />} style={{ backgroundColor: '#C31532' }} />
          </div>
        ) : (
          <Button color="primary" icon={<UploadOutlined color="#C31532" />} style={{ backgroundColor: '#FFCCC7', color: '#C31532' }} disabled={disabled}>
            Upload image
          </Button>
        )}
      </Upload>

      <Drawer title="Crop Image" placement="right" onClose={() => setDrawerVisible(false)} visible={drawerVisible} width={800}>
        {processedImage ? (
          <div style={{ position: 'relative', width: '100%', height: 'calc(100vh - 150px)', textAlign: 'center' }}>
            <ReactCrop
              crop={crop!}
              onChange={(newCrop) => setCrop(newCrop)}
              aspect={aspectRatio}
              ruleOfThirds
              keepSelection
              style={{ width: scaledWidth, height: scaledHeight }} // Ensure crop matches image size
            >
              <img src={processedImage} ref={(img) => (imageRef.current = img)} alt="Source" style={{ width: scaledWidth, height: scaledHeight, objectFit: 'contain' }} />
            </ReactCrop>

            <Button type="primary" loading={isLoading} style={{ marginTop: 5 }} onClick={handleUpload}>
              Upload
            </Button>
          </div>
        ) : (
          <p>Loading image...</p>
        )}
      </Drawer>
    </div>
  );
};

export default ImageUploader;

import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Button, Form, Input, Typography, Spin, message, Checkbox } from "antd";
import "../styles/SignUp.scss";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useSignInMutation, useSignUpMutation } from '../services/admin/adminAuth';
import { useNavigate, useParams } from "react-router-dom";
import { useGetGroupByIdQuery, useGetPublicGroupByIdQuery } from '../services/admin/inventoryApi';
import NotFound from "./NotFound";
import Loader from './Loader';
import { validatePasswordRules } from "../utils";
import {  signIn  as signInAction} from "../slices/admin/adminUserSlice";

const { Title, Link, Paragraph } = Typography;

const SignUp = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [signUp, { isLoading, isError, error, isSuccess }] = useSignUpMutation();
  const [signIn] = useSignInMutation();
  const [agreed, setAgreed] = useState(false);
  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(false);

  const { data: groupData, error: groupError, isLoading: groupLoading } = useGetPublicGroupByIdQuery({ group_id: parseInt(id || "0") });

  const handleSignUp = async () => {
    try {
      const values = await form.validateFields();
      const { email, password, fullName } = values;
      
        await signUp({ 
        email: email.toLowerCase(),  // Convert email to lowercase
        password, 
        full_name: fullName, 
        group_id: parseInt(id || "0") 
      }).unwrap();
      const result = await signIn({ email: email.toLowerCase(), password }).unwrap();
      dispatch(signInAction({ token: result.access_token, email }));
      
      if (result.access_token) {
        navigate("/dashboard/user-dashboard");
      }
      setIsSignUpSuccessful(true);  // Update state on successful sign-up
      message.success('Sign up successful!');
    } catch (err) {
      console.error('Failed to sign up:', err);
      // @ts-ignore
      message.error(err?.data?.detail || 'Failed to sign up. Please try again.');
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const validatePassword = (_: any, value: any) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('The two passwords that you entered do not match!'));
  };

  if (groupLoading) return <Loader />;

  if (!groupData?.name||groupError) {
    return <NotFound />;
  }
  

  return (
    <div>
    {isSignUpSuccessful ? (  // Conditional rendering
     <div>
     <Title level={3} style={{ textAlign: 'center', marginBottom: 20 }}>
       {groupData.name}
     </Title>
     {groupData.blurb && (
       <Paragraph style={{ textAlign: 'center', marginBottom: 20 }}>
         {groupData.blurb}
       </Paragraph>
     )}
      <Paragraph style={{ textAlign: 'center', marginTop: 20 }}>
        Your account has been created. <Link href="/Signin">Click here to Login.</Link>
      </Paragraph>
      </div>
    ) : (
    <Form
      name="signup_form"
      className="signup-form"
      form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={handleSignUp}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      {groupLoading ? (
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <Spin />
        </div>
      ) : (
        groupData && (
          <>
            <Title level={3} style={{ textAlign: 'center', marginBottom: 20 }}>
              {groupData?.name}
            </Title>
            {groupData?.blurb && (
              <Paragraph style={{ textAlign: 'center', marginBottom: 20 }}>
                {groupData?.blurb}
              </Paragraph>
              
            )}
          </>
        )
      )}
       {groupData?.is_signup_disabled? (
         <Paragraph style={{ textAlign: 'center', marginTop: 20 , color:'red' }}>
         Sign-up has been disabled, please contact your admin.
       </Paragraph>
       ): 
       <>
      
     
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Please input a valid Email!",
          },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            validator: validatePasswordRules,
            message: "Minimum length of at least 8 characters and contain both numeric and alphabetic",
          },
        ]}
        hasFeedback
      >
        <Input.Password prefix={<LockOutlined />} type="password" placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your Password!",
          },
          {
            validator: validatePassword,
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined />} type="password" placeholder="Confirm Password" />
      </Form.Item>
      <Form.Item
        name="fullName"
        label="Full Name"
        rules={[
          {
            required: true,
            message: "Please input your Full Name!",
          },
        ]}
      >
        <Input placeholder="Full Name" />
      </Form.Item>
     
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('You must agree to the terms and policies')),
          },
        ]}
      >
        <Checkbox onChange={(e) => setAgreed(e.target.checked)}>
          I agree to the <Link href="/terms">Terms and Policies</Link> and <Link href="/privacy">Privacy Policy</Link>.
        </Checkbox>
      </Form.Item>

      <Form.Item >
        <Button block type="primary" htmlType="submit" loading={isLoading} disabled={!agreed}>
          Sign up
        </Button>
      </Form.Item>
      </>}
      {isLoading && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Spin />
        </div>
      )}
    </Form>
     )}
     </div>

   
  );
};

export default SignUp;

import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
  } from 'react';
  import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Table,
    Upload,
    Select,
    InputNumber,
    Spin,
    notification,
    message,
  } from 'antd';
  import { Controller, useForm } from 'react-hook-form';
  import '../styles/AddOrder.scss';
  import Title from 'antd/es/typography/Title';
  import { useHotkeys } from 'react-hotkeys-hook';
  import { useUploadAttachmentFileMutation } from '../services/utils'; // Adjust the path as needed
  
  import { useNavigate, useParams } from 'react-router-dom';
  import {
    useDeleteOrdersByIdMutation,
    useGetCampaignpByIdQuery,
    useGetInventoryProductsQuery,
    useGetOrdersByIdQuery,
    useUpdateOrdersByIdMutation,
  } from '../services/admin/inventoryApi';
  import UserSelect from './UserSelectDropDown';
  import { InputMask } from '@react-input/mask';
  import CurrencyInput from 'react-currency-input-field';
  import { NotificationPlacement } from 'antd/es/notification/interface';
import Unauthorized from './UnAuthorised';
  
  const { Option } = Select;
  
  const EditOrder = () => {
    const {
      control,
      handleSubmit,
      setValue,
      watch,
      formState: { errors },
    } = useForm({
      defaultValues: {
        name: '',
        reference: '',
        email: '',
        phone: '',
        donationAmount: '',
        studentEnteredAmount: '',
      },
    });
  
    const selectedUser = watch('name');
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const { id,orderId } = useParams<{ id: string,orderId:string }>();
    const { data: order, isLoading: isLoadingOrder } = useGetOrdersByIdQuery({ order_id: parseInt(orderId || "0") });
    const [api, contextHolder] = notification.useNotification();
    const [uploadAttachmentFile, { isLoading: isUploading }] = useUploadAttachmentFileMutation();
    const { data: campaignsData, isLoading: isLoadingCampaigns } =
      useGetCampaignpByIdQuery({ campaign_id: parseInt(id || '0') });
    const {
      data: productsData,
      error: productsError,
      isLoading: productsLoading,
      isFetching: productsFetching,
    } =
     
      useGetInventoryProductsQuery({
        skip: 0,
        limit: 1000,
         //@ts-ignore
        catalog_id: parseInt(campaignsData?.catalog?.id || '0'),
      });
      const [updateManualOrder, { isLoading: isUpdatingOrder }] = useUpdateOrdersByIdMutation();
      const [deleteOrder, { isLoading: isDeletingOrder }] = useDeleteOrdersByIdMutation();
  
    // Product Management State
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [quantity, setQuantity] = useState<number>(1);
    const [userId, setUserId] = useState<number>(0);
    const navigate = useNavigate();
    const [phone, setPhone] = useState<number>(0);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [fileList, setFileList] = useState([]);
  
    // Hotkey navigation state
    const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(0);
  
    // 🔥 Full list of form fields for hotkey navigation
    const openNotification = (
      placement: NotificationPlacement,
      data?: string
    ) => {
      api.error({
        message: data ?? `Something went wrong`,
        placement,
      });
    };
    function extractString(input: string) {
      const regex = /\['(.*)'\]/;
      const match = input.match(regex);
      return match ? match[1] : null;
    }
    const handleDeleteOrder = async () => {
      //@ts-ignore
    const orderId = order?.id;
  
      // ⚠️ Confirm deletion for safety
      const confirmed = window.confirm(`Are you sure you want to delete order #${orderId}?`);
      if (!confirmed) return;
  
      try {
          await deleteOrder(orderId).unwrap();
          navigate(-1);
          message.success(`Order #${orderId} deleted successfully!`);
  
          // Optional: Clear form data or reset state after successful deletion
          setSelectedProducts([]);
          setSelectedProduct(null);
          setQuantity(1);
          setUserId(0);
          setPhone(0);
          setImageUrl('');
          setFileList([]);
      } catch (error) {
          console.error('Failed to delete order:', error);
          //@ts-ignore
          openNotification('topLeft', error?.data?.detail || 'Failed to delete order.');
      }
  };
    const handleUpdateOrder = async () => {
      const values = watch(); // Get all form values
  
      // ✅ Email Validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!values.email || !emailRegex.test(values.email)) {
          message.error("Please enter a valid email address.");
          return; // 🚫 Prevent submission
      }
  
      const donationAmount = parseFloat(watch('donationAmount')) || 0;
      if (!donationAmount && selectedProducts.length === 0) {
          message.error("Please add either a donation amount or at least one product.");
          return; // 🚫 Prevent submission
      }
      try {
          const response = await updateManualOrder({
            //@ts-ignore
            id:parseInt(orderId || "0"),
            //@ts-ignore
            reference_id: watch('reference'),
            //@ts-ignore
              order_id: parseInt(orderId || "0"), // Include the order_id for update
              //@ts-ignore
              campaign_id: parseInt(campaignsData?.id ?? '1'),
              //@ts-ignore
              user_id: userId > 0 ? Number(userId) : undefined,
              //@ts-ignore
              student_name_freeform: !userId ? watch('name') : undefined,
              //@ts-ignore
              email: watch('email'),
              recipient_phone: phone,
              //@ts-ignore
              file_uploads: imageUrl ? imageUrl : '',
              product_ids: selectedProducts.flatMap((item) =>
                  Array(item.quantity).fill(item.key)
              ),
              is_quote: false,
              amount_donation: donationAmount,
              amount_product: selectedProducts.reduce(
                  (total, item) => total + item.price * item.quantity,
                  0
              ),
              amount_platform_fee: 0,
              amount_total: parseFloat(watch('studentEnteredAmount')) || 0,
          }).unwrap();
  //@ts-ignore
          if (response.is_success) {
              message.success("Order updated successfully!");
          } else {
            //@ts-ignore
              openNotification('topLeft', extractString(response.message) ?? 'Order update failed.');
          }
      } catch (error) {
          //@ts-ignore
          openNotification('topLeft', error?.data?.detail || 'Failed to update order.');
          console.error('Failed to update manual order:', error);
      }
  };
  useEffect(() => {
    if (order) {
      if (order) {
        //@ts-ignore
        //@ts-ignore
        setUserId(order?.user_id)
        //@ts-ignore
        setValue("name",order?.student_name_freeform)
         //@ts-ignore
         setValue("phone",order?.recipient_phone)
          //@ts-ignore
        setValue("email",order?.email)
         //@ts-ignore
         setValue("reference",order?.reference_id)
        // setValue('reference', order.campaign_link);
        //@ts-ignore
        setValue("donationAmount",order?.amount_donation)
          //@ts-ignore
        setValue("studentEnteredAmount",order?.amount_total)
        // Handle Products from product_ids
        //@ts-ignore
        setImageUrl(order?.file_uploads)
        //@ts-ignore
        const defaultFiles = order?.file_uploads?.split(',').map((url, index) => ({
          uid: `${index}`,
          name: url.split('/').pop(),
          status: 'done',
          url,
      })) ?? [];
      setFileList(defaultFiles);
        //@ts-ignore
        const productIdCounts = order?.product_ids?.reduce((acc: Record<number, number>, id: number) => {
          acc[id] = (acc[id] || 0) + 1;
          return acc;
      }, {}); // Creates an object { 9: 4 } for easier lookup

      if (productsData) {
          const selected = Object.entries(productIdCounts).map(([id, quantity]) => {
              const product = productsData.find((p: any) => p.id === Number(id));
              return product
                  ? {
                      key: product.id,
                      image: product.primary_image_url,
                      sku: product.sku,
                      name: product.name,
                      price: product.cost,
                      quantity,
                      //@ts-ignore
                      total: product.cost * quantity,
                  }
                  : null;
          }).filter(Boolean); // Filter out any unmatched products

          setSelectedProducts(selected);
      }
      }
    }
  }, [order, orderId, setValue,productsData]);
  const handleFileUpload = async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
    console.log(file,"file")
      try {
        const response = await uploadAttachmentFile(formData).unwrap();
    
        if (response?.response) {
          console.log(response.response)
          const newFile = {
            //@ts-ignore
            uid: `${file?.uid}`, // Maintain unique identifier
            name: file.name,
            status: 'done',
            url: response?.response,
        };
        //@ts-ignore
        setFileList((prev) => [...prev, newFile]);
        setImageUrl((prevImageUrl) => {
          // Combine the new URL with existing URLs (if any)
          //@ts-ignore
          return prevImageUrl ? `${prevImageUrl},${response?.response}` : response?.response;
      });
          message.success('File uploaded successfully!');
          //@ts-ignore
          
          //@ts-ignore
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        console.error('File upload error:', error);
        message.error('Failed to upload file. Please try again.');
        return null;
      }
    };
  const resetFormData = () => {
      setSelectedProducts([]);
      setSelectedProduct(null);
    
      // Reset all form fields
      setValue('name', '');
      setValue('reference', '');
      setValue('email', '');
      setValue('phone', '');
      
    
    };
    // Add product logic
    const addProduct = () => {
      if (selectedProduct) {
        const product = productsData?.find((p: any) => p.id === selectedProduct);
        if (product) {
          const existingProduct = selectedProducts.find(
            (p) => p.key === product.id
          );
  
          if (existingProduct) {
            const updatedProducts = selectedProducts.map((p) =>
              p.key === product.id
                ? {
                    ...p,
                    quantity: p.quantity + quantity,
                    total: (p.quantity + quantity) * p.price,
                  }
                : p
            );
            setSelectedProducts(updatedProducts);
          } else {
            setSelectedProducts([
              ...selectedProducts,
              {
                key: product.id,
                image: product.primary_image_url,
                sku: product.sku,
                name: product.name,
                price: campaignsData?.catalog?.price_schedules?.[product.id]?campaignsData?.catalog?.price_schedules?.[product.id]:product?.cost,
                quantity,
                total: (campaignsData?.catalog?.price_schedules?.[product.id]?campaignsData?.catalog?.price_schedules?.[product.id]:product?.cost) * quantity,
              },
            ]);
          }
  
          setSelectedProduct(null);
          setQuantity(1);
        }
      }
    };
  
    // Remove product logic
    const removeProduct = (key: string) => {
      const updatedProducts = selectedProducts.filter(
        (product) => product.key !== key
      );
      setSelectedProducts(updatedProducts);
    };
  
    // Update product quantity logic
    const updateQuantity = (key: string, newQuantity: number) => {
      const updatedProducts = selectedProducts.map((product) =>
        product.key === key
          ? {
              ...product,
              quantity: newQuantity,
              total: product.price * newQuantity,
            }
          : product
      );
      setSelectedProducts(updatedProducts);
    };
  
    const onSubmit = (data: any) => {
      console.log('Form Data:', data);
      console.log('Selected Products:', selectedProducts);
    };
  
    const handleForward = useCallback(() => {
      const activeElement = document.activeElement as HTMLInputElement;
      const isSelectField =
        activeElement.getAttribute('aria-label') === 'select-product';
  
      // Identify the correct index
      const currentIndex = isSelectField
        ? 4 // Hardcode or dynamically detect the Select’s index
        : Number(activeElement?.getAttribute('data-index')) || 0;
      // Identify current index from `data-index` attribute
  
      const nextIndex = currentIndex === 10 ? 0 : currentIndex + 1;
      // 🔍 Find the next available input field and focus it
      // let foundNext = false;
  
      if (inputRefs.current[nextIndex]&&(currentIndex==0||currentIndex==4)) {
        inputRefs.current[nextIndex]?.focus();
        setCurrentFocusIndex(nextIndex);
        // foundNext = true;
      }
  
      // if (!foundNext) {
      //   inputRefs.current[0]?.focus();
      //   setCurrentFocusIndex(0);
      // }
    }, [setCurrentFocusIndex, currentFocusIndex]);
  
    useEffect(() => {
      if (isLoadingCampaigns) return;
    
      const tryFocus = () => {
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        } else {
          requestAnimationFrame(tryFocus); // Retry until the element is available
        }
      };
    
      requestAnimationFrame(tryFocus);
    }, [isLoadingCampaigns]);
  
    console.log(currentFocusIndex);
  
    useHotkeys(
      'enter',
      (e) => {
        handleForward();
      },
      { enableOnFormTags: true }
    );
  
    // Product Table Columns
    const productColumns = [
      {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        render: (text: string) => (
          <img
            src={text}
            alt="Product"
            style={{ width: '100px', height: '100px' }}
          />
        ),
      },
      { title: 'SKU', dataIndex: 'sku', key: 'sku' },
      { title: 'Name', dataIndex: 'name', key: 'name' },
      //@ts-ignore
      { title: 'Price', dataIndex: 'price',render: (value, ) => (
         <p>${value/100}</p>
        ), key: 'price' },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        //@ts-ignore
        render: (_, record) => (
          <InputNumber
          type="number"
            min={1}
            value={record.quantity}
            onChange={(value) => updateQuantity(record.key, value || 1)}
          />
        ),
      },
      { title: 'Total', dataIndex: 'total', key: 'total' ,
      //@ts-ignore
      render: (value, ) => (
        <p>${value/100}</p>
       ),},
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        //@ts-ignore
        render: (_, record) => (
          <Button type="link" danger onClick={() => removeProduct(record.key)}
          >
            Remove
          </Button>
        ),
      },
    ];
    if (isLoadingCampaigns||isLoadingOrder||isDeletingOrder) {
      return (
        <div
          className="edit-campaign-container"
          style={{ height: '100%', padding: 20 }}
        >
          <Spin tip="Loading..." />
        </div>
      );
    }
    console.log(userId)
    //@ts-ignore
    if (!order?.manual) {


      return <Unauthorized />
    }
    return (
      <>
            {contextHolder}
      <div className="add-order-container">
        <Spin spinning={isUpdatingOrder}>
        <Title level={2} className="editGroup">
          Edit Order
        </Title>
        <Form
        //@ts-ignore
          onFinish={handleSubmit(handleUpdateOrder)}
          layout="vertical"
          className="edit-group-form"
          // onKeyDown={(e) => {
          //     if (e.key === 'Enter') {
          //         const activeElement = document.activeElement as HTMLElement;
          //         //@ts-ignore
          //         console.log(activeElement?.getAttribute('data-index'),activeElement?.getAttribute('data-index')===9)
          //         // ✅ Prevent form submission unless Save button is focused
          //         //@ts-ignore
          //         if (activeElement?.getAttribute('data-index') !== 9) {
          //             e.preventDefault()
          //         }
                  
          //     }
          // }}
        >
          {/* Customer Details */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Name" >
                <Controller
                  name="name"
                  control={control}
                  // rules={{
                  //   required: "User Name is required", // ✅ Required Field
                  
                  // }}
                  render={({ field }) => (
                    <UserSelect
                      // {...field}
                      //@ts-ignore
                      //@ts-ignore
                      ref={(el) => {
                        if (inputRefs.current) {
                          //@ts-ignore
                          inputRefs.current[0] = el;
                        }
                      }}
                      data-index="0"
                      //@ts-ignore
                      width={'100%'}
                      allowAddNew={true}
                      //@ts-ignore
                      defaultValue={userId>0?userId:order?.student_name_freeform}
                      onChange={(value,option) => {
                        console.log(value,option)
                          if (typeof value === 'number') {
                              setUserId(value)
                          }else{
                            setUserId(0)
                              setValue("name",option?.full_name||option?.children)
                          }
                          
                          console.log(value,option)
                      }}
                      groupId={campaignsData?.group_id}
                    />
                  )}
                />
              </Form.Item>
            </Col>
  
            <Col span={12}>
              <Form.Item label="Reference ID">
                <Controller
                  name="reference"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      //@ts-ignore
                      ref={(el) => {
                        if (inputRefs.current) {
                          //@ts-ignore
                          inputRefs.current[1] = el;
                        }
                      }}
                      data-index="1"
                      placeholder="Enter Reference ID"
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Email"           validateStatus={errors.email ? 'error' : ''}
            help={errors.email ? errors.email.message : ''}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required", // ✅ Required Field
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // ✅ Email Pattern
                      message: "Please enter a valid email address",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      //@ts-ignore
                      ref={(el) => {
                        if (inputRefs.current) {
                          //@ts-ignore
                          inputRefs.current[2] = el;
                        }
                      }}
                      data-index="2"
                      placeholder="Enter Email"
                    />
                  )}
                />
              </Form.Item>
            </Col>
  
            <Col span={12}>
              <Form.Item label="Phone">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                      <InputMask
                      {...field}
                      style={{
                          padding: 16,
                          borderColor: "#d9d9d9",
                          width:"100%",
                          border: "1px solid #ddd",
                          fontSize:16
                      }}
                        mask="(___) ___-____"
                        replacement={{ _: /\d/ }}
                        placeholder="(xxx) yyy-yyyy"
                        onMask={(e) => {
                          const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                          //@ts-ignore
                          setPhone(value>0||value?.length>0?value:null);
                          
                        }}
                        ref={(el) => {
                          if (inputRefs.current) {
                            //@ts-ignore
                            inputRefs.current[3] = el;
                          }
                        }}
                        data-index="3"
                      />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Product Selection */}
          <Form.Item label="Products">
            <Table
              dataSource={selectedProducts}
              bordered
              loading={productsLoading||productsFetching}
              columns={productColumns}
              pagination={false}
              locale={{ emptyText: 'No products added yet. Add products below.' }}
            />
            <Input.Group compact style={{ marginTop: 30 }}>
              <Select
                ref={(el) => {
                  // For Ant Design's Select, use `.focus()` method from the instance
                  if (el) {
                    //@ts-ignore
                    inputRefs.current[4] = {
                      focus: () => el.focus({ preventScroll: false }),
                    };
                  }
                }}
                data-index="4"
                aria-label="select-product"
                style={{ width: '70%', borderRadius: 10 }}
                placeholder="Select a product"
                value={selectedProduct}
                showSearch  // ✅ Enables the search feature
                filterOption={(input, option) =>
                  //@ts-ignore
                    option?.children.toLowerCase().includes(input.toLowerCase()) || // Search by name
                    option?.['data-sku']?.toLowerCase().includes(input.toLowerCase()) // Search by SKU
                }
                dropdownRender={(menu) => (
                  <div data-index="4"> {menu} </div> // ✅ Track index on rendered dropdown
                )}
                onChange={(value) => setSelectedProduct(value)}
                loading={productsLoading || productsFetching}
                notFoundContent={
                  productsLoading || productsFetching ? (
                    <Spin size="small" />
                  ) : (
                    'No users found'
                  )
                }
                virtual={true}
              >
                {productsData?.map((product: any) => (
                  <Option key={product.id} value={product.id} data-sku={product.sku}>
                    {product.name}
                  </Option>
                ))}
              </Select>
  
              <InputNumber
               type="number"
                style={{ width: '20%' }}
                min={1}
                value={quantity}
                ref={(el) => {
                  if (inputRefs.current) {
                    //@ts-ignore
                    inputRefs.current[5] = el;
                  }
                }}
                data-index="5"
                onChange={(value) => setQuantity(value || 1)}
              />
  
              <Button
                type="primary"
                onClick={addProduct}
                ref={(el) => {
                  if (inputRefs.current) {
                    //@ts-ignore
                    inputRefs.current[6] = el;
                  }
                }}
                data-index="6"
              >
                Add
              </Button>
            </Input.Group>
          </Form.Item>
          {/* Upload Section */}
          <Form.Item label="Upload Order Form(s)">
    <Controller
    //@ts-ignore
      name="uploadedFile"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Upload.Dragger
        fileList={fileList}
        itemRender={(originNode, file) => {
          // Clone `originNode` and modify only the file name
          return React.cloneElement(originNode, {
            children: React.Children.map(originNode.props.children, (child) => {
              if (
                React.isValidElement(child) &&
                //@ts-ignore
                child.props.className?.includes('ant-upload-list-item-name')
              ) {
                // Replace `<a>` with `<span>` but keep the structure
                return (
                  <span
                  //@ts-ignore
                    tabIndex="0"
                    className="file-name"
                    onClick={() => window.open(file.url, '_blank')}
                    role="link"
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: '#1890ff',
                    }}
                  >
                    {file.name}
                  </span>
                );
              }
              return child; // Retains the delete icon and other elements
            }),
          });
        }}
        onRemove={(file) => {
          console.log(file,"file")
          //@ts-ignore
          const updatedList = fileList.filter((item) => item.uid !== file.uid);
          setFileList(updatedList);
          //@ts-ignore
          const updatedUrls = updatedList.map((f) => f?.url).join(',');

    setImageUrl(updatedUrls); // ✅ Ensures URLs stay in sync
          //@ts-ignore
          onChange(updatedList.map((f) => f.url).join(','));
      }}
         // fileList={value ? [{ name: value.split('/').pop(), status: 'done' }] : []}
          beforeUpload={async (file) => {
            const uploadedFileUrl = await handleFileUpload(file); // 🔥 Upload immediately
            if (uploadedFileUrl) {
              onChange(uploadedFileUrl); // ✅ Save the uploaded URL to the form
            }
            return false; // Prevent default upload behavior
          }}
          accept=".pdf,.docx,.xlsx,.png,.jpg"
        >
          <p className="ant-upload-text">
            Click to upload or drag and drop files here
          </p>
        </Upload.Dragger>
      )}
    />
  </Form.Item>
          {/* Donation and Student Amounts */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Donation Amount">
                <Controller
                  name="donationAmount"
                  control={control}
                  render={({ field }) => (
                     <CurrencyInput
                className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
                placeholder="Enter Donation Amount"
                //@ts-ignore
                defaultValue={((order?.amount_donation)??0)/100}
                 decimalsLimit={2}
                 onValueChange={(value) => {
                   const centsValue = value ? parseFloat(value) * 100 : 0;
                   field.onChange( centsValue );
                 }}
                 prefix="$"
                 ref={(el) => {
                  if (inputRefs.current) {
                    //@ts-ignore
                    inputRefs.current[7] = el;
                  }
                }}
                data-index="7"
               />
                  )}
                />
              </Form.Item>
            </Col>
  
            <Col span={12}>
              <Form.Item label="Student Entered Amount Collected">
                <Controller
                  name="studentEnteredAmount"
                  control={control}
                  render={({ field }) => (
                 
                    <CurrencyInput
                className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
                placeholder="Enter Amount Collected"
                  //@ts-ignore
                defaultValue={((order?.amount_total)??0)/100}
                 decimalsLimit={2}
                 onValueChange={(value) => {
                   const centsValue = value ? parseFloat(value) * 100 : 0;
                   field.onChange( centsValue );
                 }}
                 prefix="$"
                 //@ts-ignore
                 ref={(el) => {
                  if (inputRefs.current) {
                    //@ts-ignore
                    inputRefs.current[8] = el;
                  }
                }}
                data-index="8"
               />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
  
          {/* Action Buttons */}
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
          >
            <Button
              type="primary"
              loading={isUpdatingOrder}
              disabled={isUpdatingOrder}
              style={{
                marginTop: 0,
              }}
              ref={(el) => {
                if (inputRefs.current) {
                  //@ts-ignore
                  inputRefs.current[9] = el;
                }
              }}
              //@ts-ignore
               onClick={handleUpdateOrder}
              data-index="9"
            >
              
              Save
            </Button>
            <Button
              type="default"
              loading={isUpdatingOrder}
              disabled={isUpdatingOrder}
              ref={(el) => {
                if (inputRefs.current) {
                  //@ts-ignore
                  inputRefs.current[10] = el;
                }
              }}
              data-index="10"
              onClick={handleDeleteOrder}
            >
            
              Delete
            </Button>
          </div>
        </Form>
        </Spin>
      </div>
      </>
    );
  };
  
  export default EditOrder;
  
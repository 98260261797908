// /slices/addressSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AddressState {
  street1: string;
  street2: string;
  city: string;
  state: string | null;
  postalCode: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

const initialState: AddressState = {
  email: '',
  firstName: '',
  lastName: '',
  street1: '',
  street2: '',
  city: '',
  state: null,
  postalCode: '',
  phone: '',
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    updateAddress: (state, action: PayloadAction<Partial<AddressState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateAddress } = addressSlice.actions;

export default addressSlice.reducer;

import React, { useState, useEffect, useCallback } from 'react';
import { Table, Pagination, Spin, message, Button, Drawer, Form, Input, Select, Flex, Card, Image, Typography, Tag, Modal } from 'antd';
import { useDeleteOrdersByIdByAdminMutation, useGetOrdersQuery, useGetProductByIdMutation, useSearchCampaignsQuery, useSearchOrdersQuery, useSearchUsersQuery, useTagUserForOrderMutation } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Campaign, Catalog, Item, User } from '../types.ts'; // Ensure correct import path
import '../styles/OrdersTable.scss';
import { convertUTCDateToLocalDate } from '../utils';
import myImage from '../assets/images/NoOrder.png';
import { useGetUsersQuery } from '../services/admin/inventoryApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setCampaignId, setUserId } from '../slices/admin/adminUserSlice';
import Title from 'antd/es/typography/Title';
import Meta from 'antd/es/card/Meta';
import { SearchOutlined } from '@ant-design/icons';
import OrderDetailsDrawer from './OrderDetailsDrawer';
import UserSelect from './UserSelectDropDown';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;




const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount / 100);
};

interface OrderTableProps {
campaign_id?: number,
user_id?: number
}
const { TextArea } = Input;
const { Text } = Typography;
const OrdersTable: React.FC<OrderTableProps> = ({campaign_id,user_id}) => {

  const dispatch = useDispatch();
  const campaignId = useSelector((state: RootState) => state.adminUser.campaignId);
  
  const userId = useSelector((state: RootState) => state.adminUser.userId);
  const [sortColumn, setSortColumn] = useState<string | null>('created_at');  // Default sorting by Created At
  const [sortOrder, setSortOrder] = useState<boolean>(true);  // Default sorting order (descending)
  const { currentPage, pageSize, onPageChange,onPageSizeChange } = usePagination(1,5);
  const { currentPage: allUserCurrentPage, pageSize: allUsersPageSize, onPageChange: onAllUsersPageChange } = usePagination(1, 5);
  const [searchTerm, setSearchTerm] = useState<string>('');
  

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(searchTerm);
  const [searchTermUser, setSearchTermUser] = useState<string>('');
  const [debouncedSearchTermUser, setDebouncedSearchTermUser] = useState<string>(searchTermUser);
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const { data:campaignData , isFetching:isCampaignsFetching,isLoading:isCampaignsLoading,status} = useSearchCampaignsQuery({
    key: "title",
    value: debouncedSearchTerm,
    limit: 1000,
  });
  // const { data:allUsers , isFetching:isUsersFetching,isLoading:isUsersLoading,status:statusUsers} = useSearchUsersQuery({
  //   key: "full_name",
  //   value: debouncedSearchTermUser,
  //   limit: 5,
  // });
  const { data, error, isLoading, isFetching ,refetch} = useGetOrdersQuery({
    skip: (currentPage - 1) * pageSize,
    limit: 5,
   //@ts-ignore
   campaign_id: campaign_id||campaignId ,  // Use either campaignId or fallback
   //@ts-ignore
   user_id: user_id||userId ,  // Use either userId or fallback
   order_by: sortColumn ?? '',
   order_by_decending: sortOrder,  // Sort order boolean (true if descending)
  });
  const { data:searchData, error:searchError, isLoading: searchLoading, isFetching: searchFetching } = useSearchOrdersQuery({
   key:key,
   value: value,
   limit: 1000,
  },{skip:!key});
  

  const [tagUserForOrder, { isLoading:isLoadingTagUser, isError, isSuccess }] = useTagUserForOrderMutation();
  const [deleteOrdersByIdByAdmin, { isLoading: isDeleting }]=useDeleteOrdersByIdByAdminMutation();
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [freeFormName, setFreeFormName] = useState(null);
  const [users, setUsers] = useState<User[]>([]); // Store loaded users
  const [hasMore, setHasMore] = useState(true); // Track if more users exist
  const [selectedRowData, setSelectedRowData] = useState<any>({
    recipient_first_name: "",
    user_id: "",
    email: "",
    recipient_last_name: "",
    recipient_address_1: "",
    recipient_address_2: "",
    recipient_address_city: "",
    recipient_address_state: "",
    recipient_address_zip: "",
    recipient_phone: 0,
    amount_donation: 0,
    amount_shipping: 0,
    amount_total: 0,
    product_ids: [],
    card_token: "",
    is_quote: false,
    quote_number: 0,
    id: 0,
    created_at: "",
    shipping_quote: 0,
    is_success: false,
    message: "",
    
  });
  const { data: usersData, error: usersError, isLoading: usersLoading, isFetching: usersFetching } = useGetUsersQuery({
    skip: (allUserCurrentPage -1 )*5,
    limit: 5 ,
  });
  const navigate = useNavigate();
  const [getProductById, {data:dataLoadingProduct, isLoading: isLoadingProduct, isError: isErrorProduct, isSuccess : isSuccessProduct}] = useGetProductByIdMutation();
  const user = useSelector((state: RootState) => state.adminUser.user);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to control the drawer visibility
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  //@ts-ignore
  const showModal = (e,record) => {
    e.stopPropagation(); // Prevent row click
    e.preventDefault();
    //@ts-ignore
    setSelectedRowData(record)
    setIsModalVisible(true);}
    const handleOk =async () => {
      try {
        await deleteOrdersByIdByAdmin(selectedRowData.id).unwrap();
        message.success(`Order ID ${selectedRowData.id} deleted successfully.`);
        refetch(); // Refresh order data
    } catch (error) {
      //@ts-ignore
        message.error(error?.data?.detail || 'Failed to delete order.');
    }
        setIsModalVisible(false);
    };
    const handleCancel = () => setIsModalVisible(false);
  // Handle table change for sorting
  //@ts-ignore
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if(sorter.order===undefined)return
    setSortColumn(sorter.field);  // Update the column being sorted
    const sortOrder = sorter.order=="ascend"?true:false;
    setSortOrder(!sortOrder);   // Update the sort order
  };
  useEffect(() => {
    if (usersData?.data?.length) {
      setUsers((prevUsers) => [...prevUsers, ...(usersData?.data??[])]); // Append new users
      setHasMore(usersData?.data?.length === pageSize); // If full pageSize is loaded, more users exist
    } else {
      setHasMore(false);
    }
  }, [usersData]);


  useEffect(() => {
  

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 

  useEffect(() => {
    if (error) {
      message.error('Failed to load orders');
    }
  }, [error]);

  const handleRowClick = async (record: any) => {
    if(record?.manual){
      if (((((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&user?.permissions?.create_order_manual)||user?.scope === ('internal_admin'))){
        navigate(`/edit-order/${record.campaign_id}/${record.id}`);
      }
    }
    setIsDrawerOpen(true);
    setSelectedRowData(record);
    try {
    await getProductById({product_ids:record.product_ids}).unwrap();
    }catch (error) {}
  
  };
  const onSubmit = async () => { console.log("v bnm,")
    try {
      let selectedOrder={id:selectedRowData.id,user_id:(selectedUser!==null&&selectedUser>0)?selectedUser:undefined,student_name_freeform:freeFormName};
     
      await tagUserForOrder(selectedOrder).unwrap();
      message.success('User Linked successfully!');
      handleCloseDrawer()
      console.log('user updated:', data);
      
    } catch (error) {
      // @ts-ignore
      message.error(error?.data?.detail || 'Something went wrong, Please try again.');
      console.error('Failed to add user:', error);
    }
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedRowData({
      recipient_first_name: "",
      user_id: "",
      email: "",
      recipient_last_name: "",
      recipient_address_1: "",
      recipient_address_2: "",
      recipient_address_city: "",
      recipient_address_state: "",
      recipient_address_zip: "",
      recipient_phone: 0,
      amount_donation: 0,
      amount_shipping: 0,
      amount_total: 0,
      product_ids: [],
      card_token: "",
      is_quote: false,
      quote_number: 0,
      id: 0,
      created_at: "",
      shipping_quote: 0,
      is_success: false,
      message: ""

    });
  };


 
    // Debounce effect to delay API call for smoother UX
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearchTerm(searchTerm);  // Set debounced search term after delay
      }, 300);  // Adjust the delay as needed
  
      return () => {
        clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
      };
    }, [searchTerm]);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearchTermUser(searchTermUser);  // Set debounced search term after delay
      }, 300);  // Adjust the delay as needed
  
      return () => {
        clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
      };
    }, [searchTermUser]);
   // Handle search input in dropdown
   const handleCampaignSearch = (value: string) => {
    setSearchTerm(value);  // Update the search term
  };
  const handleUserSearch = (value: string) => {
    setSearchTermUser(value);  // Update the search term
  };
  const handleUserChange = (value: string | null) => {
    setSearchTermUser("");
    dispatch(setUserId(value));
    setKey("")
    setValue("")
  };
  const handleCampaignChange = (value: string | null) => {
    dispatch(setCampaignId(value));
    setSearchTerm("");
    setKey("")
    setValue("")
  };
    // @ts-ignore
    const getColumnCampaignProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
      //@ts-ignore
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{
          padding: 8,
          position: 'absolute',
          zIndex: 1050, // Ensures the dropdown appears above other elements
          background: 'white',
          transform: 'translateY(-170%)',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
        }} onKeyDown={(e) => e.stopPropagation()}>
           <Select
            placeholder="Select Campaign"
            allowClear
            showSearch
            onSearch={handleCampaignSearch}  // Trigger search on input
            style={{ width: 200 }}
            onChange={handleCampaignChange}
            defaultValue={campaignData?.find((group: Campaign) => group.id == campaignId)?.title || ''}
            optionFilterProp="children" // Search based on Option's children (full_name)
            notFoundContent={isCampaignsFetching && <Spin size="small" /> } 
            
          >
            {campaignData?.map((group: Campaign) => (
            //@ts-ignore
              <Option key={group.name} value={group.id}>
                {group.title}
              </Option>
            ))}
          </Select>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: campaignId ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
      ),
   
   
    });
        // @ts-ignore
        const getColumnUserProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
          //@ts-ignore
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{
              padding: 8,
              position: 'absolute',
              overflow: "hidden",
              zIndex: 1050, // Ensures the dropdown appears above other elements
              background: 'white',
              transform: 'translateY(-170%)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
            }} onKeyDown={(e) => e.stopPropagation()}>
             <UserSelect value={userId} onChange={handleUserChange} />
            </div>
          ),
          filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: userId ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
          ),
       
        
        });
               // @ts-ignore
               const getColumnEmailProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
                //@ts-ignore
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                  <div style={{
                    padding: 8,
                    position: 'absolute',
                    zIndex: 1050, // Ensures the dropdown appears above other elements
                    background: 'white',
                    transform: 'translateY(-170%)',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px',
                  }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                    placeholder="Search Email"
                    allowClear
                    style={{ width: 200 }}
                  value={key==="email"?value:""}
                    onChange={(e)=>{
                      if(e.target.value===""){
                        setKey("")
                      }else{
                      setKey("email")
                      }
                      setValue(e.target.value)
                      dispatch(setUserId(null));
                      dispatch(setCampaignId(null));
                    }}
                 />
                
                  </div>
                ),
                filterIcon: (filtered: boolean) => (
                  <SearchOutlined style={{ color:key==="email"&&value ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
                ),
             
             
              });
                // @ts-ignore
                const getColumnPhoneProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
                  //@ts-ignore
                  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                    <div style={{
                      padding: 8,
                      position: 'absolute',
                      zIndex: 1050, // Ensures the dropdown appears above other elements
                      background: 'white',
                      transform: 'translateY(-170%)',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                      borderRadius: '4px',
                    }} onKeyDown={(e) => e.stopPropagation()}>
                      <Input
                      placeholder="Search Recipient Phone"
                      allowClear
                      style={{ width: 200 }}
                    value={key==="recipient_phone"?value:""}
                      onChange={(e)=>{
                        if(e.target.value===""){
                          setKey("")
                        }else{
                        setKey("recipient_phone")
                        }
                        setValue(e.target.value)
                        dispatch(setUserId(null));
                        dispatch(setCampaignId(null));
                      }}
                   />
                  
                    </div>
                  ),
                  filterIcon: (filtered: boolean) => (
                    <SearchOutlined style={{ color:key==="recipient_phone"&&value ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
                  ),
               
               
                });
                // @ts-ignore
                const getColumnNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
                  //@ts-ignore
                  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                    <div style={{
                      padding: 8,
                      position: 'absolute',
                      zIndex: 1050, // Ensures the dropdown appears above other elements
                      background: 'white',
                      transform: 'translateY(-170%)',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                      borderRadius: '4px',
                    }} onKeyDown={(e) => e.stopPropagation()}>
                      <Input
                      placeholder="Search First Name"
                      allowClear
                      style={{ width: 200 }}
                    value={key==="recipient_first_name"?value:""}
                      onChange={(e)=>{
                        if(e.target.value===""){
                          setKey("")
                        }else{
                        setKey("recipient_first_name")
                        }
                        setValue(e.target.value)
                        dispatch(setUserId(null));
                      dispatch(setCampaignId(null));
                      }}
                   />
                  
                    </div>
                  ),
                  filterIcon: (filtered: boolean) => (
                    <SearchOutlined style={{ color: key==="recipient_first_name"&&value  ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
                  ),
               
               
                });
                  // @ts-ignore
                  const getColumnIdProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
                    //@ts-ignore
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                      <div style={{
                        padding: 8,
                        position: 'absolute',
                        zIndex: 1050, // Ensures the dropdown appears above other elements
                        background: 'white',
                        transform: 'translateY(-170%)',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                      }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                        placeholder="Search Order Id"
                        allowClear
                        style={{ width: 200 }}
                      value={key==="id"?value:""}
                        onChange={(e)=>{
                          if(e.target.value===""){
                            setKey("")
                          }else{
                          setKey("id")
                          }
                          setValue(e.target.value)
                          dispatch(setUserId(null));
                      dispatch(setCampaignId(null));
                        }}
                     />
                    
                      </div>
                    ),
                    filterIcon: (filtered: boolean) => (
                      <SearchOutlined style={{ color: key==="id"&&value  ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
                    ),
                 
                 
                  });
  const columns = [
 
    {
      title: 'Order Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      ...getColumnIdProps('id'),
    },
    {
      title: 'Total Product Cost ($)',
      dataIndex: 'amount_product',
      key: 'amount_product',
      render: (_: any, record: any) => `$${record.amount_product/100}`,
     
    },
    {
      title: 'Shipping Cost ($)',
      dataIndex: 'amount_shipping',
      key: 'amount_shipping',
      render: (_: any, record: any) => `$${record.amount_shipping/100}`,
    },
    {
      title: 'Donation Cost ($)',
      dataIndex: 'amount_donation',
      key: 'amount_donation',
      render: (_: any, record: any) => `$${record.amount_donation/100}`,
    },
    {
      title: 'Total Paid ($)',
      dataIndex: 'amount_total',
      key: 'amount_total',
      render: (_: any, record: any) => `$${record.amount_total/100}`,
    },
    {
      title: 'Name',
      dataIndex: 'recipient_first_name',
      key: 'recipient_first_name',
      sorter: (a: any, b: any) => a.recipient_first_name.localeCompare(b.recipient_first_name),
      render: (_: any, record: any) => `${record.recipient_first_name} ${record.recipient_last_name}`,
      ...getColumnNameProps('recipient_first_name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      ...getColumnEmailProps('email'),
    },
    // {
    //   title: 'Is Quote',
    //   dataIndex: 'is_quote',
    //   key: 'is_quote',
    //   render: (text: boolean) => (text ? 'Yes' : 'No'),
    // },
    {
      title: 'Phone number',
      dataIndex: 'recipient_phone',
      key: 'recipient_phone',
      render: (text: boolean) => (text ),
      ...getColumnPhoneProps("recipient_phone")
    },
    {
      title:"Campaign Name",
      dataIndex: 'campaign_id',
      key: 'campaign_id',
      sorter: (a: any, b: any) => a?.campaign?.title?.toString().localeCompare(b?.campaign?.title),
      render: (_: any, record: any) => `${record?.campaign?.title?.toString()}`,
      ...getColumnCampaignProps('campaign_id'),
    },
    {
      title:"Seller",
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: (a: any, b: any) => a?.user?.full_name?.toString().localeCompare(b?.user?.full_name),
      render: (_: any, record: any) => `${record?.user?.full_name?.toString() ?? '-'}`,
      ...getColumnUserProps('user_id'),
    },
    {
      title:"Entered Manually",
      dataIndex: 'manual',
      key: 'manual',
      //@ts-ignore
      sorter: (a: Campaign, b: Campaign) => Number(a.manual) - Number(b.manual),
      render: (text: boolean) => (text ? <p >TRUE</p> : <p >FALSE</p>),

    },
    {
      title:"Is Deleted",
      dataIndex: 'is_deleted',
      key: 'is_deleted',
      //@ts-ignore
      sorter: (a: Campaign, b: Campaign) => Number(a.is_deleted) - Number(b.is_deleted),
      //@ts-ignore
      render: (text: boolean,record) => text ? (
        <p>TRUE</p>
    ) : (
        <Flex vertical align="center" gap="small">
            <p>FALSE</p>
            
           
        </Flex>
    )

    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (text: string) => {
        const targetDate = convertUTCDateToLocalDate(new Date(text) as Date);
      console.log(targetDate)
        return isNaN(targetDate.getTime()) ? 'Invalid Date' : targetDate.toLocaleString();
      },
    },
    // Add more columns as necessary
  ];
  return (
    <>
      {(isLoading) ? (
       <div style={{
        display: 'flex',
        height: '100vh',      // Full viewport height
        justifyContent: 'center', // Center horizontally
        alignItems: 'center'      // Center vertically
    }}>
    
        <Spin tip="Loading..." />
      
    </div>
      ) : (
        <>
          
          {/* {data?.data?.length? */}
          <Table
           title={()=>
           <Title level={2}>Order List </Title>
          
        }
            onChange={handleTableChange}  // Trigger sorting on column change
            columns={columns}
            loading={isFetching||searchLoading||searchFetching}
            //@ts-ignore
            dataSource={key?searchData??[]: data?.data || []}
            pagination={false}
            scroll={{ x: !isDesktop?'100vw':undefined }}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowClassName="clickable-row"
            footer={() =>value?<></>: <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={data?.count}
              onChange={onPageChange}
              showSizeChanger={false}  // Allow page size change
              onShowSizeChange={(_, newSize) => onPageSizeChange(newSize)} // Handle page size changes
              pageSizeOptions={["5",'10', '20', '50', '100']}  // Set options for page size
            />
            }
          />
        </>
      )}
     <OrderDetailsDrawer
  isDrawerOpen={isDrawerOpen}
  handleCloseDrawer={handleCloseDrawer}
  selectedRowData={selectedRowData}
  isLoadingProduct={isLoadingProduct}
  isErrorProduct={isErrorProduct}
  dataLoadingProduct={dataLoadingProduct}
  formatCurrency={formatCurrency}

  //@ts-ignore
  setSelectedUser={setSelectedUser}
  //@ts-ignore
  setFreeFormName={setFreeFormName}
  onSubmit={onSubmit}
  isLoadingTagUser={isLoadingTagUser}
/>;
<Modal
            //@ts-ignore
                title="Confirm Deletion"
                open={isModalVisible}
                loading={isDeleting}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Yes, Delete"
                cancelText="Cancel"
            >
                <p>Are you sure you want to delete this item?</p>
            </Modal>
    </>
  );
};

export default OrdersTable;

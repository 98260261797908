// src/components/AddCampaign.tsx

import React, { useEffect, useState } from 'react';
import '../styles/AddCampaign.scss';
import ImageUploader from './ImageUploader';
import CurrencyInput from 'react-currency-input-field';
import { Form, Input, Button, Typography, DatePicker, Switch, Drawer, List, Radio, Tooltip,Checkbox, Col, Row, Flex, Popover } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useAddCampaignMutation, useGetGroupByIdQuery, useGetGroupsNamesQuery, useGetInventoryCatalogsQuery, useSearchGroupsQuery } from '../services/admin/inventoryApi';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { HexColorPicker } from "react-colorful";
import { Catalog, Group } from '../types.ts';
import { DownOutlined, QuestionCircleOutlined,BgColorsOutlined } from '@ant-design/icons';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import Unauthorized from './UnAuthorised';

const { TextArea } = Input;
const { Title,Text } = Typography;
const defaultLogoUrl = 'https://storage.googleapis.com/frm-reporting/fund-dash-user/1739522830.4327264_i75DFhoV2osDLTnDv4h5vn.png';
const defaultHeroUrl = 'https://storage.googleapis.com/frm-reporting/fund-dash-user/1741018579.001252_A6jRAzd87gkcJ5QrRu897U.png';
const AddCampaign: React.FC = () => {
  const { register, handleSubmit, control, formState: { errors,isDirty }, setValue, watch } = useForm({
    defaultValues: {
      campaign_link: '',
      logo_image_url: defaultLogoUrl,
      hero_image_url: defaultHeroUrl,
      video_url: '',
      title: '',
      blurb: 'Thank you for taking the time to check out our fundraiser store!  This is your chance to help us reach our goal, any purchase has a major impact on our program.  Besides helping us reach our goal, you’ll get an amazing high-quality product.  Another way you can help is by sharing this page with your friends and family by clicking one of the sharing icons. Thanks in advance for your generosity!',
      money_goal: 0,
      money_raised: 0,
      end_date: '',
      is_closed: false,
      donations_enabled: false,
      leader_board_enabled: false,
      recent_orders_enabled: false,
      group_id: null,
      group_name: '',
      start_date: '',
      catalog_id: null,
      catalog_name: '',
      ship_promo_enabled: false,
      pre_sale_id:'',
      campaign_send_rep_1:false,
      campaign_send_admin_1_send: false,
      campaign_send_contact_goal_1_send: false,
      campaign_send_contact_end_1_send: false,
    }
  });
  const location = useLocation();
  const groupId = location.state;
  const {
    data: groupData,
    error: groupsError,
    isLoading: groupsLoading,
    isFetching: groupsFetching,
  } = useGetGroupByIdQuery({ group_id: parseInt(groupId || "0") },{skip:!groupId});
  const [textColor, setTextColor] = useState("#000000"); // Default color: black
  const user = useSelector((state: RootState) => state.adminUser.user);
  const [visible, setVisible] = useState(false);
  const [blurbColor, setBlurbColor] = useState("#000000"); // Default color: black
  const [visibleBlurbColor, setVisibleBlurbColor] = useState(false);
  const [hexInput, setHexInput] = useState<string>(blurbColor); // Track hex input

  const [hexError, setHexError] = useState<string | null>(null);
  const isValidHex = (hex: string) => /^#([0-9A-F]{3}){1,2}$/i.test(hex); 
  const handleHexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setHexInput(value);

    if (isValidHex(value)) {
      setBlurbColor(value);
      setHexError(null);
    } else {
      setHexError("Invalid Hex Code");
    }
  };
  const [hexTitleInput, setHexTitleInput] = useState<string>(blurbColor); // Track hex input

  const [hexTitleError, setHexTitleError] = useState<string | null>(null);
  const handleHexTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setHexTitleInput(value);

    if (isValidHex(value)) {
      setTextColor(value);
      setHexTitleError(null);
    } else {
      setHexTitleError("Invalid Hex Code");
    }
  };
  const logoImageUrl = watch('logo_image_url');
  const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_BASE_URL_FE);
  const heroImageUrl = watch('hero_image_url');
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsNamesQuery({ skip: 0, limit: 1000 , is_rep_group:false});
  const [addCampaign, { isLoading, isError, isSuccess }] = useAddCampaignMutation();
  const [isDrawerCatalogVisible, setIsDrawerCatalogVisible] = useState(false);
  const { data: catalogsData, isLoading: isLoadingCatalogs } = useGetInventoryCatalogsQuery({ skip: 0, limit: 1000 });
  const [selectedCatalog, setSelectedCatalog] = useState<Catalog | null>(null);
  const navigate = useNavigate();
  const [searchPreSaleId, setSearchPreSaleId] = useState<string>('');
  const [debouncedPreSaleId, setDebouncedPreSaleId] = useState<string>(searchPreSaleId);
  const { data:groups , isFetching:isGroupsFetching,isLoading:isGroupsLoading,status:statusGroups} = useSearchGroupsQuery({
    key: "name",
    value: debouncedPreSaleId,
    limit: 1000,
  },{skip: !debouncedPreSaleId});
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [useDefaultLogo, setUseDefaultLogo] = useState(true);
  const [useDefaultHero, setUseDefaultHero] = useState(true);
  useEffect(()=>{
    setBaseUrl(process.env.REACT_APP_BASE_URL_FE)
  },[process.env.REACT_APP_BASE_URL_FE])
  useEffect(()=>{
    if(!groupData) return ;
    setSelectedGroup(groupData);
    setValue('group_name', groupData.name);
  },[groupData])
  const onSubmit = async (data: any) => {
    // data.end_date = moment(data.end_date).utc().toISOString();
    data.group_id = selectedGroup?.id ?? null;
    data.catalog_id = selectedCatalog?.id ?? null;
    data.title_color= textColor;
    data.blurb_color= blurbColor;
    if (useDefaultLogo) {
      data.logo_image_url = defaultLogoUrl;
    }
    if (useDefaultHero) {
      data.hero_image_url = defaultHeroUrl;
    }
    try {
      await addCampaign(data).unwrap();
      console.log('Campaign added successfully:', data);
      navigate(-1);
    } catch (error) {
      console.error('Failed to add campaign:', error);
    }
  };

  const handleLogoImageUpload = (imageUrl: string) => {
    setValue('logo_image_url', imageUrl, { shouldValidate: true });
    setUseDefaultLogo(false);
  };

  const handleHeroImageUpload = (imageUrl: string) => {
    setValue('hero_image_url', imageUrl, { shouldValidate: true });
    setUseDefaultHero(false);  
  };

  const handleDrawerOpen = () => {
    setIsDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };
  const handleDrawerCatalogOpen = () => {
    setIsDrawerCatalogVisible(true);
  };
  const handleDrawerCatalogClose = () => {
    setIsDrawerCatalogVisible(false);
  };
  const handleCatalogSelect = (catalog: Catalog) => {
    setSelectedCatalog(catalog);
    setValue('catalog_name', catalog.name);
    setIsDrawerCatalogVisible(false);
  };

  const handleGroupSelect = (group: Group) => {
    setSelectedGroup(group);
    setValue('group_name', group.name);
    setIsDrawerVisible(false);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedPreSaleId(searchPreSaleId);  // Set debounced search term after delay
    }, 500);  // Adjust the delay as needed
  
    return () => {
      clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
    };
  }, [searchPreSaleId]);
  if (((user?.scope === 'rep'||user?.scope==='organizer')&&!user?.permissions?.create_campaign)) {


  return <Unauthorized />
}
  return (
    <div className="add-campaign-container">
      <Title level={2} className="editProduct">Add Campaign</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="add-campaign-form">
      <Row>
            <Col flex={3}>
        <Form.Item
          label="Campaign Store Link"
          validateStatus={errors.campaign_link ? 'error' : ''}
          help={errors.campaign_link ? errors.campaign_link.message : ''}
        >
          <Controller
            name="campaign_link"
            control={control}
            rules={{ required: 'Campaign Store Link is required' }}
            render={({ field }) => (
              <Input type="text" {...field}  addonBefore={`${baseUrl}raise/`} placeholder='Enter Link here'/>
            )}
          />
        </Form.Item>

       

        <Form.Item
  label="Youtube Video Link"
  validateStatus={errors.video_url ? 'error' : ''}
  help={errors.video_url ? errors.video_url.message : ''}
>
  <Controller
    name="video_url"
    control={control}
    rules={{}}
    render={({ field }) => <Input type="text" {...field} placeholder='Enter Video URL'/>}
  />
</Form.Item>

<Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Title"
          validateStatus={errors.title ? 'error' : ''}
          help={errors.title ? errors.title.message : ''}
        >
          <Controller
            name="title"
            control={control}
            rules={{ required: 'Title is required' }}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter Campaign Title'
              styles={{
                input: { color: textColor }, // Apply text color correctly
              }}
              addonAfter={
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                 

                  {/* Color Picker Popover */}
                  <Popover
                    content={
                      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                      <HexColorPicker color={textColor} onChange={(color) => {
                        setTextColor(color);
                        setHexTitleInput(color);
                        setHexTitleError(null);
                      }} />
                      <Input
                        value={hexTitleInput}
                        onChange={handleHexTitleChange}
                        placeholder="Enter hex code"
                        maxLength={7}
                      />
                      {hexTitleError && <span style={{ color: "red" }}>{hexTitleError}</span>}
                    </div>
                    }
                    trigger="click"
                    visible={visible}
                    onVisibleChange={setVisible}
                  >
                    <BgColorsOutlined style={{ cursor: "pointer" }} />
                  </Popover>
                </div>
              }
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Pre Sale ID"
          validateStatus={errors.pre_sale_id ? 'error' : ''}
          help={errors.pre_sale_id ? errors.pre_sale_id.message : ''}
        >
          <Controller
            name="pre_sale_id"
            control={control}
            rules={{ required: 'pre_sale_id is required' }}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter Pre Sale ID'/>
            )}
          />
        </Form.Item>
        </Flex>
<Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Group Money Goal"
          validateStatus={errors.money_goal ? 'error' : ''}
          help={errors.money_goal ? errors.money_goal.message : ''}
        >
          <Controller
            name="money_goal"
            control={control}
            rules={{ required: 'Money goal is required' }}
            render={({ field }) => (
              <CurrencyInput
              className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
               placeholder="Please enter Money Goal"
               defaultValue={field.value}
               decimalsLimit={2}
               onValueChange={(value) => {
                 const centsValue = value ? parseFloat(value) * 100 : 0;
                 field.onChange( centsValue );
               }}
               prefix="$"
             />
            )}
          />
        </Form.Item>
        

        <Form.Item
          label="Money Raised"
          validateStatus={errors.money_raised ? 'error' : ''}
          help={errors.money_raised ? errors.money_raised.message : ''}
        >
          <Controller
            name="money_raised"
            control={control}
            rules={{ required: 'Money raised is required' }}
            render={({ field }) => (
              <Input type="text"
              disabled
              value={(field.value as number / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              onChange={(e) => {
                // Remove non-numeric characters and convert to cents
                const centsValue = parseInt(e.target.value.replace(/[^\d]/g, ''), 10);
                field.onChange(centsValue);
              }} />
            )}
          />
        </Form.Item>
        </Flex>
        <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Start Date and Time"
          validateStatus={errors.start_date ? 'error' : ''}
          help={errors.start_date ? errors.start_date.message : ''}
        >
          <Controller
            name="start_date"
            control={control}
            rules={{ required: 'Start date and time is required' }}
            render={({ field }) => (
              <DatePicker 
                {...field} 
                style={{ width: '100%' ,padding: 16,borderRadius: 0}} 
                showTime 
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Select Start Date and Time"
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="End Date and Time"
          validateStatus={errors.end_date ? 'error' : ''}
          help={errors.end_date ? errors.end_date.message : ''}
        >
          <Controller
            name="end_date"
            control={control}
            rules={{ required: 'End date and time is required' }}
            render={({ field }) => (
              <DatePicker 
                {...field} 
                style={{ width: '100%' ,padding: 16,borderRadius: 0}} 
                showTime 
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Select End Date and Time"
              />
            )}
          />
        </Form.Item>
        </Flex>
        <Form.Item
          label="Main Store Text"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}
            rules={{ required: 'Seller Default text is required' }}
            render={({ field }) => (
              <Flex justify="space-between" align="start" className='flexRow' style={{gap:20}}>
              <TextArea {...field} rows={8} placeholder='Enter your description....'
              styles={{
                textarea: { color: blurbColor }, // Apply text color correctly
              }}
           
              />
              <Popover
                    content={
                      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                      <HexColorPicker color={blurbColor} onChange={(color) => {
                        setBlurbColor(color);
                        setHexInput(color);
                        setHexError(null);
                      }} />
                      <Input
                        value={hexInput}
                        onChange={handleHexChange}
                        placeholder="Enter hex code"
                        maxLength={7}
                      />
                      {hexError && <span style={{ color: "red" }}>{hexError}</span>}
                    </div>
                    }
                    trigger="click"
                    visible={visibleBlurbColor}
                    onVisibleChange={setVisibleBlurbColor}
                  >
                    <BgColorsOutlined style={{ cursor: "pointer" ,fontSize:20}} />
                  </Popover>
                  </Flex>
            )}
          />
        </Form.Item>

        
      
        

      

        
        <Row gutter={20} >
        <Col span={10}>
        <Form.Item
          label=""
          validateStatus={errors.is_closed ? 'error' : ''}
          help={errors.is_closed ? errors.is_closed.message : ''}
        >
          <Controller
            name="is_closed"
            control={control}
            render={({ field }) => (
              <div>
              <Checkbox checked={field.value} onChange={field.onChange} />
              <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>
              Is Closed
              </Text>
              </div> 
            )}
          />
        </Form.Item>
        </Col>
        <Col span={14}>
        <Form.Item
          label=""
          validateStatus={errors.campaign_send_admin_1_send ? 'error' : ''}
          help={errors.campaign_send_admin_1_send ? errors.campaign_send_admin_1_send.message : ''}
        >
          <Controller
            name="campaign_send_admin_1_send"
            control={control}
            render={({ field }) => (
              <div>
              <Checkbox checked={field.value} onChange={field.onChange} />
              <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>
             Send Sponsor Daily Summary Email
              </Text>
                </div> 
            )}
          />
        </Form.Item>
        </Col>
        </Row >

        <Row gutter={20} >
        <Col span={10}>
        <Form.Item
          label=""
          validateStatus={errors.is_closed ? 'error' : ''}
          help={errors.ship_promo_enabled ? errors.ship_promo_enabled.message : ''}
        >
          <Controller
            name="ship_promo_enabled"
            control={control}
            render={({ field }) => (
            <div>  <Checkbox checked={field.value} onChange={field.onChange} /><Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Ship Promo Enabled</Text></div>
            )}
          />
        </Form.Item>
        </Col>
        <Col span={14}>
        <Form.Item
          label=""
          validateStatus={errors.campaign_send_rep_1 ? 'error' : ''}
          help={errors.campaign_send_rep_1 ? errors.campaign_send_rep_1.message : ''}
        >
          <Controller
            name="campaign_send_rep_1"
            control={control}
            render={({ field }) => (
              <div>
              <Checkbox checked={field.value} onChange={field.onChange} />
              <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Send Rep Daily Summary Email</Text>
              </div>
            )}
          />
        </Form.Item>
        {/* <Form.Item
          label=""
          validateStatus={errors.campaign_send_contact_goal_1_send ? 'error' : ''}
          help={errors.campaign_send_contact_goal_1_send ? errors.campaign_send_contact_goal_1_send.message : ''}
        >
          <Controller
            name="campaign_send_contact_goal_1_send"
            control={control}
            render={({ field }) => (
              <div>
              <Checkbox checked={field.value} onChange={field.onChange} />
              <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Send Sponsor's Contacts Threshold Email</Text>
               </div> 
            )}
          />
        </Form.Item> */}
        </Col>
        </Row >

        <Row gutter={20} >
        <Col span={10}>
        <Form.Item
          label=""
          validateStatus={errors.donations_enabled ? 'error' : ''}
          help={errors.donations_enabled ? errors.donations_enabled.message : ''}
        >
          <Controller
            name="donations_enabled"
            control={control}
            render={({ field }) => (
              <div>
              <Checkbox checked={field.value} onChange={field.onChange} />
              <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Donations Enabled</Text>
              </div>
            )}
          />
        </Form.Item>
        </Col>
        <Col span={14}>
        <Form.Item
          label=""
          validateStatus={errors.campaign_send_contact_end_1_send ? 'error' : ''}
          help={errors.campaign_send_contact_end_1_send ? errors.campaign_send_contact_end_1_send.message : ''}
        >
          <Controller
            name="campaign_send_contact_end_1_send"
            control={control}
            render={({ field }) => (
              <div>
              <Checkbox checked={field.value} onChange={field.onChange} />
              <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Send Sponsor's Contacts Closing Push Email</Text>
            </div>
            )}
          />
        </Form.Item>
        </Col>
        </Row>
        <Row gutter={20} >
        <Col span={10}>
        <Form.Item
          label=""
          validateStatus={errors.leader_board_enabled ? 'error' : ''}
          help={errors.leader_board_enabled ? errors.leader_board_enabled.message : ''}
        >
          <Controller
            name="leader_board_enabled"
            control={control}
            render={({ field }) => (
              <div>
              <Checkbox checked={field.value} onChange={field.onChange} />
              <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Leader Board enabled</Text>
              </div>
            )}
          />
        </Form.Item>
        </Col>
        <Col span={14}>
        <Form.Item
          label=""
          validateStatus={errors.recent_orders_enabled ? 'error' : ''}
          help={errors.recent_orders_enabled ? errors.recent_orders_enabled.message : ''}
        >
          <Controller
            name="recent_orders_enabled"
            control={control}
            render={({ field }) => (
              <div>
              <Checkbox checked={field.value} onChange={field.onChange} />
              <Text style={{
                borderBottom: "none",
                color:"#1d2026"
              }}>Recent Sales Enabled</Text>
              </div>
            )}
          />
        </Form.Item>
        </Col>
        </Row>
       
     
    
        </Col>
<Col flex={2}>
<Form.Item
          label="Logo Image URL"
          validateStatus={errors.logo_image_url ? 'error' : ''}
          help={errors.logo_image_url ? errors.logo_image_url.message : ''}
          style={{
            paddingLeft: 20
          }}
        >
          <Checkbox
            checked={useDefaultLogo}
            onChange={(e) => {
              setUseDefaultLogo(e.target.checked);
            
            }}
            style={{
              paddingBlock:10
            }}
          >
            Use Default Logo
          </Checkbox>
          {(
            <Flex justify='center' align='center' vertical style={{
              border: "1px solid  #d9d9d9",
              paddingBottom: 40,
              paddingTop:40
             }}>
            <Controller
              name="logo_image_url"
              control={control}
              rules={{ required: 'Logo image is required' }}
              render={({ field }) => (
                <>
                  {(useDefaultLogo?defaultLogoUrl:logoImageUrl) && (
                    <img src={useDefaultLogo?defaultLogoUrl:logoImageUrl} alt="Logo" style={{ width: '256px', height: '105.6px' }} />
                  )}
               {!useDefaultLogo &&    <div style={{ display: 'flex', alignItems: 'center',flexDirection:"column" }}>
                    <ImageUploader onUpload={(imageUrl) => handleLogoImageUpload(imageUrl)} width={640} height={264} aspectRatio={2.42}/>
                    <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center',height:'100%' }}>
                      <p  style={{
                        width: 300,
                        textAlign: "center",
color: "#6E7485"
          }} >
         <Text strong style={{
            borderBottom:"none",
            padding: 0
           }}> Important guidelines: </Text> For best results, please upload a 640 × 264. Supported format: <Text strong style={{
            borderBottom:"none",
            padding: 0
           }}>.jpg or .png</Text>

          </p>
          </div>
                    {/* <Tooltip title="For best results, please upload a 640 × 264 png or jpg">
                      <QuestionCircleOutlined style={{ marginLeft: 8, marginTop: 20, color: '#1890ff' }} />
                    </Tooltip> */}
                  </div>}
                </>
              )}
            />
            </Flex>
          )}
        </Form.Item>

        <Form.Item
          label="Hero Image URL"
          validateStatus={errors.hero_image_url ? 'error' : ''}
          help={errors.hero_image_url ? errors.hero_image_url.message : ''}
          style={{
            paddingLeft: 20,
          }}
        >
          <Checkbox
            checked={useDefaultHero}
            onChange={(e) => {
              setUseDefaultHero(e.target.checked);
            }}
            style={{
              paddingBlock:10
            }}
          >
            Use Default Hero
          </Checkbox>
          { (
            <Flex justify='center' align='center' vertical style={{
              border: "1px solid  #d9d9d9",
              paddingBottom: 40,
              paddingLeft:10,
              paddingRight:10,
              paddingTop:40
             }}>
            <Controller
              name="hero_image_url"
              control={control}
              rules={{ required: 'Hero image is required' }}
              render={({ field }) => (
                <>
                  {(useDefaultHero?defaultHeroUrl:heroImageUrl) && (
                    <img src={useDefaultHero?defaultHeroUrl:heroImageUrl} alt="Hero" style={{ width: '320px', height: '180px' }} />
                  )}
                {!useDefaultHero &&  <div style={{ display: 'flex', alignItems: 'center' ,flexDirection:"column"}}>
                    <ImageUploader onUpload={(imageUrl) => handleHeroImageUpload(imageUrl)} width={1600} height={900} aspectRatio={1.78} />
                    <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center',height:'100%' }}>
                      <p  style={{
                        width: 300,
                        textAlign: "center",
color: "#6E7485"
          }} >
           <Text strong style={{
            borderBottom:"none",
            padding: 0
           }}>  Important guidelines: </Text>For best results, please upload a 1600 × 900. Supported format:<Text strong style={{
            borderBottom:"none",
            padding: 0
           }}> .jpg or .png</Text>

          </p>
          </div>
                    {/* <Tooltip title="For best results, please upload a 1600 × 900 png or jpg">
                      <QuestionCircleOutlined style={{ marginLeft: 8, marginTop: 20, color: '#1890ff' }} />
                    </Tooltip> */}
                  </div>}
                </>
              )}
            />
             </Flex>
          )}
        </Form.Item>
       
        </Col>
</Row>
<Form.Item
          label="Organization"
          validateStatus={errors.group_name ? 'error' : ''}
          help={errors.group_name ? errors.group_name.message : ''}
        >
          <Controller
            name="group_name"
            control={control}
            rules={{ required: 'Organization is required' }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  readOnly
                  onClick={handleDrawerOpen}
                  placeholder="Select Organization"
                  addonAfter={<DownOutlined onClick={handleDrawerOpen}/>}
                />
              </>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Catalog"
          validateStatus={errors.catalog_name ? 'error' : ''}
          help={errors.catalog_name ? errors.catalog_name.message : ''}
        >
          <Controller
            name="catalog_name"
            control={control}
            rules={{ required: 'Catalog is required' }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  readOnly
                  onClick={handleDrawerCatalogOpen}
                  placeholder="Select Catalog"
                  addonAfter={<DownOutlined  onClick={handleDrawerCatalogOpen}/>}
                />
              </>
            )}
          />
        </Form.Item>
        <Row justify={'center'}>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={!isDirty}>Add Campaign</Button>
        </Form.Item>
</Row>
        {isError && <p>Failed to add campaign. Please try again.</p>}
        {isSuccess && <p>Campaign added successfully!</p>}
      </Form>

      <Drawer
         title={<>
          <Text>Select Organization</Text>
          <Input  value={searchPreSaleId}
          onChange={(e)=>{
           
            setSearchPreSaleId(e.target.value)
          }}/>
          </>}
        width={400}
        loading={isLoadingGroups||isGroupsFetching||isGroupsLoading}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <List
        //@ts-ignore
         dataSource={searchPreSaleId?groups?.filter(group=>!group.is_rep_group):groupsData?.data ?? []}
          renderItem={(group: Group) => (
            <List.Item key={group.id}>
              <Radio
                checked={selectedGroup?.id === group.id}
                onChange={() => handleGroupSelect(group)}
              >
                {group.name}
              </Radio>
            </List.Item>
          )}
        />
      </Drawer>
     
      <Drawer
        title="Select Catalog"
        width={400}
        onClose={handleDrawerCatalogClose}
        visible={isDrawerCatalogVisible}
      >
        <List
          dataSource={catalogsData ?? []}
          renderItem={(catalog: Catalog) => (
            <List.Item key={catalog.id}>
              <Radio
                checked={selectedCatalog?.id == catalog.id}
                onChange={() => handleCatalogSelect(catalog)}
              >
                {catalog.name}
              </Radio>
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
};

export default AddCampaign;

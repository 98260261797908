import React, { useEffect, useState } from 'react';
import { Table, Flex, Button, Checkbox, message, Drawer, Input, List, Radio, Typography } from 'antd';
import { Group, GroupGroupLink, User } from '../types.ts'; // Ensure correct import path
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/CatalogsTable.scss';
import { useAddGroupGroupLinksMutation, useGetGroupLinksQuery, useGetGroupsLinkedQuery, useGetGroupsNamesQuery, useSearchGroupsQuery, useUpdateGroupLinksMutation } from '../services/admin/inventoryApi';

interface GroupsTableProps {
  associatedGroups: Group[];
  loading: boolean;
  onLinkClick?: () => void;
  selectedSubRep?: User;
  group?: string;
}
const { Title, Paragraph,Text } = Typography;
const RepGroupTable: React.FC<GroupsTableProps> = ({
  associatedGroups,
  loading,
  onLinkClick,
  selectedSubRep,
  group,
}) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [selectedDrawerGroups, setSelectedDrawerGroups] = useState<number[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [searchPreSaleId, setSearchPreSaleId] = useState<string>('');
const [debouncedPreSaleId, setDebouncedPreSaleId] = useState<string>(searchPreSaleId);
  const [updateGroupLinks, { isLoading: isSubmitting }] = useUpdateGroupLinksMutation();
  const { data:groups , isFetching:isGroupsFetching,isLoading:isGroupsLoading,status:statusGroups} = useSearchGroupsQuery({
    key: "name",
    value: debouncedPreSaleId,
    limit: 1000,
  },{skip: !debouncedPreSaleId});
  const {
    data: linkedGroupsData,
    isLoading: linkedGroupsLoading,
    isFetching: linkedGroupsFetching,
  } = useGetGroupsLinkedQuery(
    { user_id: selectedSubRep?.id ?? 0 },
    { skip: !selectedSubRep?.id }
  );
  const { data, error, isLoading } = useGetGroupLinksQuery({
    group_id: parseInt(id || "0"),
    limit: 200,
    order_by: 'group_name',
});
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsNamesQuery({ skip: 0, limit: 1000 , is_rep_group:false});
  const [addGroupLinks, { isLoading: isAdding }] = useAddGroupGroupLinksMutation();
  useEffect(()=>{
     setSelectedGroups(linkedGroupsData?.filter((group:GroupGroupLink)=>group.is_linked).map((group: GroupGroupLink) => group.linked_group_id)??[]);
  },[linkedGroupsData])

  const handleCheckboxChange = (groupId: number, checked: boolean) => {
    setSelectedGroups((prevSelected) =>
      checked ? [...prevSelected, groupId] : prevSelected.filter((id) => id !== groupId)
    );
  };

  const handleAddSubmit = async () => {
    if (!selectedSubRep) {
      message.error("Please select a Sub Rep before submitting.");
      return;
    }

    //@ts-ignore
    const allSelectedGroups = [...new Set([...selectedGroups, ...selectedDrawerGroups])];

    if (!allSelectedGroups.length) {
      message.warning("No groups selected for linking.");
      return;
    }

    try {
      await addGroupLinks({
        group_id: parseInt(group || "0"),
        linked_group_ids: allSelectedGroups
      }).unwrap();

      message.success(`${allSelectedGroups.length} group links added successfully.`);
      setSelectedGroups([]);
      setSelectedDrawerGroups([]);
    } catch (error) {
      message.error("Failed to add group links.");
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (!selectedSubRep) {
      message.error("Please select a Sub Rep before submitting.");
      return;
    }

    // Transform selected groups into required format
    const payload: GroupGroupLink[] = selectedGroups.map((groupId) => ({
      is_created_by: false,
      sub_rep_user_id: selectedSubRep.id,
      //@ts-ignore
      group_id: parseInt(group),
      linked_group_id: groupId, // Assuming linked_group_id is same as group_id
      is_linked: true, // This marks the group as linked
    }));

    try {
      await updateGroupLinks({groupLinks:payload,user_id:selectedSubRep.id}).unwrap();
      message.success("Group links updated successfully!");
    } catch (error) {
      message.error("Failed to update group links.");
      console.error(error);
    }
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const columns = [
    {
      title: 'Organization Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'blurb',
      key: 'blurb',
      sorter: (a: any, b: any) => a.blurb.localeCompare(b.blurb),
    },
    {
      title: '',
      render: (_: string, record: Group) => {
        const isDisabled = linkedGroupsData
          ?.some((group: GroupGroupLink) => group.is_created_by && group.linked_group_id === record.id);
const isLinked = linkedGroupsData
?.some((group: GroupGroupLink) => group.is_linked && group.linked_group_id === record.id);
        return (
          <Checkbox
            disabled={isDisabled}
            checked={isDisabled || selectedGroups.includes(record.id)}
            onChange={(e) => handleCheckboxChange(record.id, e.target.checked)}
          />
        );
      },
    },
  ];

  const handleRowClick = (record: Group) => {
    if (selectedSubRep?.id) return;
    navigate(`/edit-groups/${record.id}`);
  };
  const handleRadioChange = (groupId: number, checked: boolean) => {
    setSelectedDrawerGroups((prevSelected) =>
      checked
        ? [...prevSelected, groupId] // Add if checked
        : prevSelected.filter((id) => id !== groupId) // Remove if unchecked
    );
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedPreSaleId(searchPreSaleId);  // Set debounced search term after delay
    }, 500);  // Adjust the delay as needed
  
    return () => {
      clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
    };
  }, [searchPreSaleId]);
  return (
    <>
      <Table
        loading={loading || linkedGroupsLoading || linkedGroupsFetching}
        columns={selectedSubRep?.id ? columns : columns.slice(0, 2)}
        dataSource={associatedGroups || []}
        pagination={false}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        title={() => (
          <Flex justify="space-between" align="center" className="flexRow">
            <Title level={2}>Organization List</Title>
            <Flex gap={10} align="center">
              <span>{selectedSubRep?.full_name}</span>
              <Button type="primary" onClick={() => onLinkClick?.()}>
                Link Sub Reps
              </Button>
              <Button type="primary" onClick={() => setIsDrawerVisible(true)}>
                Link Groups
              </Button>
            </Flex>
          </Flex>
        )}
        rowClassName="clickable-row"
      />

      {/* Submit Button */}
      {selectedSubRep?.id && (
        <Flex justify="end" style={{ marginTop: '20px' }}>
          <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>
            Submit
          </Button>
        </Flex>
      )}
          <Drawer
           footer={
            <Flex justify="end" gap={10}>
              <Button onClick={handleDrawerClose}>Cancel</Button>
              <Button 
                type="primary" 
                onClick={handleAddSubmit} 
                loading={isAdding}
              >
                Submit
              </Button>
            </Flex>
          }
        title={<>
        <Text>Select Organization</Text>
        <Input  value={searchPreSaleId}
        onChange={(e)=>{
         
          setSearchPreSaleId(e.target.value)
        }}/>
        </>}
        width={400}
        loading={isLoadingGroups||isGroupsLoading||isGroupsFetching}
        
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <>
      
        </>
        <List
        //@ts-ignore
          dataSource={searchPreSaleId?groups?.filter(group=>!group.is_rep_group):groupsData?.data ?? []}
          renderItem={(group: Group) => (
            <List.Item key={group.id}>
              <Radio
                disabled={data
                  ?.filter(item => item.sub_rep_user_id === null) // Filter items with null `sub_rep_user_id`
                  .map(item => item.linked_group_id)              // Extract `linked_group_id`s
                  .some(linkedId => linkedId === group.id)}
                  checked={selectedDrawerGroups.includes(group.id)}  // ✅ Tracks checked status
                  onClick={() => handleRadioChange(group.id, !selectedDrawerGroups.includes(group.id))}  
              >
                {group.name}
              </Radio>
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};

export default RepGroupTable;

import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Select, Spin, Button, message } from "antd";

import { User } from "../types.ts";
import usePagination from "../hooks/usePagination";
import { useGetUsersQuery, useSearchUsersQuery } from "../services/admin/inventoryApi";

const { Option } = Select;

interface UserSelectProps {
  value?: string | null;
  onChange: (value: string | null, option: any) => void;
  groupId?: number;
  width?: number;
  defaultValue?: number | string;
  allowAddNew?: boolean;
}

//@ts-ignore
const UserSelect = forwardRef<typeof BaseSelectRef, UserSelectProps>(
  ({ value, onChange, groupId, width = 200, allowAddNew = false, defaultValue }, ref) => {
    const [searchTermUser, setSearchTermUser] = useState<string>("");
    const [selectedValue, setSelectedValue] = useState<string | null>(value || null);
    const [debouncedSearchTermUser, setDebouncedSearchTermUser] = useState<string>(searchTermUser);
    const [users, setUsers] = useState<User[]>([]);
    const [initialLoadDone, setInitialLoadDone] = useState<boolean>(false); // Track initial load state

    const { currentPage, onPageChange } = usePagination(1, 5);

    // Initial API Call - Fetch specific user only if `defaultValue` exists
    const { data: initialUserData, isFetching: initialUserFetching } = useGetUsersQuery(
      {
        skip: 0,
        limit: 1,
        //@ts-ignore
        user_id_filter: defaultValue || undefined, // Only pass `user_id_filter` if `defaultValue` exists
      },
      { skip: !defaultValue }
    );

    // Paginated API Call - Load more users using `group_id_filter`
    const { data: paginatedUsersData, isFetching: paginatedFetching } = useGetUsersQuery(
      {
        skip: (currentPage - 1) * 5,
        limit: 5,
        group_id_filter: groupId || undefined,
      },
      {
          //@ts-ignore
         skip: defaultValue && !initialLoadDone } // Skip pagination until initial user load is complete
    );

    // Search API for user search functionality
    const { data: allUsers, isFetching: isUsersFetching, isLoading: isUsersLoading } = useSearchUsersQuery({
      key: "full_name",
      value: debouncedSearchTermUser,
      limit: 5,
      //@ts-ignore
      group_id_filter: groupId || undefined,
    });

    // Handle Initial User Load
    useEffect(() => {
      if (initialUserData?.data?.length) {
        const fetchedUser = initialUserData.data[0];

        setUsers((prevUsers) => {
          const existingUser = prevUsers.some((user) => user.id === fetchedUser.id);
          return existingUser ? prevUsers : [...prevUsers, fetchedUser];
        });
    //@ts-ignore
        setSelectedValue(fetchedUser.id);
          //@ts-ignore
        onChange(fetchedUser.id, fetchedUser);

        setInitialLoadDone(true); // Mark initial load complete
      } else if (!defaultValue) {
        // No `defaultValue`, skip directly to pagination
        setInitialLoadDone(true);
      }
    }, [initialUserData]);

    // Handle Pagination Users (Load More)
    useEffect(() => {
      if (paginatedUsersData?.data?.length) {
        setUsers((prevUsers) => {
            //@ts-ignore
          const newUsers = paginatedUsersData.data.filter(
            (newUser) => !prevUsers.some((existingUser) => existingUser.id === newUser.id)
          );
          return [...prevUsers, ...newUsers];
        });
      }
    }, [paginatedUsersData]);

    // Search Term Debounce
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearchTermUser(searchTermUser);
      }, 200);

      return () => clearTimeout(handler);
    }, [searchTermUser]);

    // Handle Adding New User
    const handleAddUser = () => {
      if (!searchTermUser.trim()) return;

      const newUser = {
        id: `new-${Date.now()}`,
        full_name: searchTermUser,
      };
       //@ts-ignore
      setUsers((prevUsers) => [...prevUsers, newUser]);
      setSelectedValue(newUser.id);
      onChange(newUser.id, newUser);

      message.success(`User "${newUser.full_name}" added successfully as freeform name`);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if ((e.key === "Enter" || e.key === "Tab") && allowAddNew && !isUserFound) {
        handleAddUser();
      }
    };

    // Expose clearSelection and focus methods
    useImperativeHandle(ref, () => ({
      clearSelection: () => {
        setSelectedValue(null);
        onChange(null, null);
      },
      focus: () => {
        const tryFocus = () => {
          const selectElement = document.querySelector(".ant-select-selector input");
          if (selectElement) {
            //@ts-ignore
            selectElement?.focus();
          } else {
            requestAnimationFrame(tryFocus);
          }
        };
        requestAnimationFrame(tryFocus);
      },
    }));

    const isUserFound =
      !!(searchTermUser.length > 0 &&
        ((groupId && users.some((user) => user.full_name.toLowerCase() === searchTermUser.toLowerCase())) ||
          (!groupId && allUsers?.some((user) => user.full_name.toLowerCase() === searchTermUser.toLowerCase()))));

    return (
      <Select
        //@ts-ignore
        ref={ref}
        placeholder="Select User"
        allowClear
        filterOption={
          groupId
            ? (input, option) => 
              //@ts-ignore
            option?.children?.toLowerCase().includes(input.toLowerCase())
            : false
        }
        onClear={() => setSearchTermUser("")}
        showSearch
        listHeight={150}
        onSearch={setSearchTermUser}
        style={{ width: width }}
        value={selectedValue}
        onChange={(val, option) => {
          setSelectedValue(val);
          onChange(val, option);
        }}
        virtual={true}
        onInputKeyDown={handleKeyDown}
        dropdownRender={(menu) => (
          <div>
            <Spin spinning={isUsersFetching || isUsersLoading || initialUserFetching || paginatedFetching}>
              {menu}
            </Spin>

            {paginatedUsersData?.data?.length === 5 && !searchTermUser && (
              <div style={{ textAlign: "center", padding: 8 }}>
                <Button
                  size="small"
                  onClick={() => onPageChange(currentPage + 1)}
                  disabled={paginatedFetching}
                >
                  {paginatedFetching ? <Spin size="small" /> : "Load More"}
                </Button>
              </div>
            )}
          </div>
        )}
        notFoundContent={
          isUsersFetching || isUsersLoading || initialUserFetching || paginatedFetching ? (
            <Spin size="small" />
          ) : (
           <Button onClick={handleAddUser} size="small" style={{
            padding: 6,
            fontSize: 14
           }}>
            Create User {searchTermUser}
           </Button>
          )
        }
      >
        {(searchTermUser.length > 0  ? allUsers : users)?.map((user) => (
          <Option key={user.id} value={user.id}>
            {user.full_name}
          </Option>
        ))}
      </Select>
    );
  }
);

export default UserSelect;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Input,
  Spin,
  Typography,
  message,
  Drawer,
  List,
  Checkbox,
  Select,
  Switch,
  Form,
  Row,
  Col,
  Flex,
  Tooltip,
} from 'antd';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import CurrencyInput from 'react-currency-input-field';

import {
  useEditUserMutation,
  useGetUserByIdQuery,
  useGetGroupsNamesQuery,
} from '../services/admin/inventoryApi';
import { User, Group } from '../types.ts';
import '../styles/AddUser.scss';
import SelectTags from './SelectTags';
import { RootState } from '../store';

const { Text } = Typography;
const { Option } = Select;

const EditUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  
  const user = useSelector((state: RootState) => state.adminUser.user);
  const {

    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors},
  } = useForm<User>();
  const selectedScope = watch('scope');
  const [selectedTags, setSelectedTags] = useState<{ [key: number]: number[] }>(
    {}
  );
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const {
    data: userData,
    error: userError,
    isLoading: userLoading,
  } = useGetUserByIdQuery({ id: parseInt(id || '0') });
  const { data: groupsData, isLoading: isLoadingGroups } =
    useGetGroupsNamesQuery({
      skip: 0,
      limit: 1000,
      //@ts-ignore
      is_rep_group:  selectedScope==="sub_rep"?true:null,
    });
  const [
    updateUser,
    { isLoading: updateLoading, isError: updateError, error },
  ] = useEditUserMutation();

  useEffect(() => {
    if (userData) {
      const user = userData;
      setValue('full_name', user?.full_name || '');
      setValue('email', user?.email || '');
      setValue('blurb', user?.blurb || '');
      setValue('is_active', user?.is_active || false);
      setValue('scope', user?.scope || 'student'); // Updated to include scope
      setValue('permissions.create_campaign', user?.permissions?.create_campaign || false);
      setValue('permissions.edit_platform_and_processing_fee', user?.permissions?.edit_platform_and_processing_fee || false);
      setValue('permissions.create_order_manual', user?.permissions?.create_order_manual || false);
      setValue('permissions.edit_campaign', user?.permissions?.edit_campaign || false);
      setValue('permissions.create_group', user?.permissions?.create_group || false);
      setValue('permissions.edit_group', user?.permissions?.edit_group || false);
      setValue('raise_goal', user?.raise_goal || 0);
      setSelectedGroups(user?.groups || []);
      if (userData?.tags) {
        const initialSelectedTags = userData.tags.reduce((acc, tag) => {
          // If the group_id already exists in the accumulator, add the tag id
          if (acc[tag?.group_id]) {
            acc[tag.group_id].push(tag.id);
          } else {
            // If the group_id doesn't exist, initialize it with an array containing the tag id
            acc[tag.group_id] = [tag.id];
          }
          return acc;
        }, {} as { [key: number]: number[] });

        // Set the selected tags state
        setSelectedTags(initialSelectedTags);
      }
    }
  }, [userData, setValue]);

  const validatePassword = (password: string) => {
    if (!password) return true; // No password provided, no need to validate
    if (password.length < 8) {
      return 'Password must be at least 8 characters long.';
    }
    if (!/[A-Za-z]/.test(password) || !/\d/.test(password)) {
      return 'Password must contain both letters and numbers.';
    }
    return true;
  };

  const onSubmit = async (data: User) => {
    console.log(data?.is_active,data)
    if (data?.scope === "organizer") {
      const hasRepGroup = selectedGroups.some(group => group.is_rep_group);
      if (hasRepGroup) {
          message.error("Organizer cannot be part of a sales group.");
          return; // Stop execution if condition fails
      }
  }
    const flattenedTags = Object.values(selectedTags).flat();
    const payload: Partial<User> & {
      tag_ids?: number[];
      group_ids?: number[];
      permissions?: {
        edit_group?: boolean,
        edit_campaign?: boolean,
        create_group?: boolean,
        create_campaign?: boolean,
        create_order_manual?:boolean
      }
    } = {
      ...data,
      email: data?.email?.trim().toLowerCase(),
      id: parseInt(id || "0"),
      //@ts-ignore
      tag_ids: flattenedTags,
      group_ids: selectedGroups.map((group) => group.id),
      permissions:{
        edit_group: data?.permissions?.edit_group,
        edit_campaign: data?.permissions?.edit_campaign,
        create_group: data?.permissions?.create_group,
        create_campaign: data?.permissions?.create_campaign,
        create_order_manual: data?.permissions?.create_order_manual,
        edit_platform_and_processing_fee:selectedScope==="rep"? data?.permissions?.edit_platform_and_processing_fee:false
      }
    };

    if (!data.new_password) {
      delete payload.new_password;
    }
    if(!(user?.scope=="internal_admin")){
      delete payload.permissions;
    }

    try {
      // Perform the PATCH request
      //@ts-ignore
      await updateUser({ id: parseInt(id || '0'), ...payload }).unwrap();
      message.success('User updated successfully!');
      // navigate(-1);
    } catch (err) {
      console.error('Failed to update user:', err);
      message.error('Failed to update user. Please try again.');
    }
  };

  const handleDrawerOpen = () => {
    setIsDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const handleTagSelection = (
    groupId: number,
    tagId: number,
    checked: boolean,
    oneTag: boolean
  ) => {
    setSelectedTags((prevState) => {
      const groupSelectedTags = prevState[groupId] || [];

      if (oneTag && checked && groupSelectedTags.length > 0) {
        // If only one tag is allowed and a tag is already selected, show warning
        message.warning(`You can only select one tag for this group.`);
        return prevState; // Do not update state
      }

      return {
        ...prevState,
        [groupId]: checked
          ? [...groupSelectedTags, tagId] // Add tag
          : groupSelectedTags.filter((id) => id !== tagId), // Remove tag
      };
    });
  };

  const handleGroupSelect = (group: Group) => {
    setSelectedGroups((prevGroups) => {
      if (prevGroups.some((g) => g.id === group.id)) {
        return prevGroups.filter((g) => g.id !== group.id);
      }
      return [...prevGroups, group];
    });
  };

  if (userLoading) return <Spin />;
  if (userError) return <Text type="danger">Failed to fetch user data.</Text>;

  return (
    <div className="add-user-container">
      <Title level={2} className="editProduct">
        Edit User
      </Title>
      <Form
        onFinish={handleSubmit(onSubmit)}
        layout="vertical"
        className="add-user-form"
      >
        <Row>
          <Col flex={3}>
            <Form.Item
              label="Full Name"
              validateStatus={errors.full_name ? 'error' : ''}
              help={errors.full_name ? errors.full_name.message : ''}
            >
              <Controller
                name="full_name"
                control={control}
                rules={{ required: 'Full Name is required' }}
                render={({ field }) => (
                  <Input {...field} placeholder="Full Name" />
                )}
              />
              {errors.full_name && (
                <Text type="danger">{errors.full_name.message}</Text>
              )}
            </Form.Item>
            <Form.Item
              label="Scope"
              validateStatus={errors.scope ? 'error' : ''}
              help={errors.scope ? errors.scope.message : ''}
            >
              <Controller
                name="scope"
                control={control}
                rules={{ required: 'Scope is required' }}
                render={({ field }) => (
                  <Select {...field} placeholder="Select Scope">
                    <Option value="student">Seller</Option>
                    {user?.scope!=="organizer"&& <Option value="rep">Rep</Option>}
                    <Option value="organizer">Organizer</Option>
                    {(user?.scope === 'internal_admin' || ((user?.scope === 'rep'||user?.scope==='organizer')&&user?.groups?.[0].is_rep_group ) ) && (
                      <Option value="sub_rep">Sub Rep</Option>
                    )}
                  </Select>
                )}
              />
              {errors.scope && (
                <Text type="danger">{errors.scope.message}</Text>
              )}
            </Form.Item>
            <Form.Item
              label="Store Default Text"
              validateStatus={errors.blurb ? 'error' : ''}
              help={errors.blurb ? errors.blurb.message : ''}
            >
              <Controller
                name="blurb"
                control={control}
                render={({ field }) => (
                  <TextArea {...field} placeholder="Blurb" rows={8} />
                )}
              />
            </Form.Item>
            <Form.Item
              label="New Password"
              validateStatus={errors.new_password ? 'error' : ''}
              help={errors.new_password ? errors.new_password.message : ''}
            >
              <Controller
                name="new_password"
                control={control}
                rules={{
                  validate: validatePassword,
                }}
                render={({ field }) => (
                  <Input.Password {...field} placeholder="New Password" />
                )}
              />
              {errors.new_password && (
                <Text type="danger">{errors.new_password.message}</Text>
              )}
            </Form.Item>
            <Form.Item label="Organizations">
              <Input
                value={`Select Organizations`}
                onClick={handleDrawerOpen}
                readOnly
                addonAfter={<DownOutlined onClick={handleDrawerOpen} />}
              />
              <List
                dataSource={selectedGroups}
                renderItem={(group) => (
                  <List.Item key={group.id}>{group.name}</List.Item>
                )}
              />
            </Form.Item>
          </Col>
          <Col
            flex={2}
            style={{
              paddingLeft: 40,
            }}
          >
            <Form.Item
              label="Email"
              validateStatus={errors.email ? 'error' : ''}
              help={errors.email ? errors.email.message : ''}
            >
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field }) => <Input {...field} placeholder="Email" />}
              />
              {errors.email && (
                <Text type="danger">{errors.email.message}</Text>
              )}
            </Form.Item>

            <Form.Item
              label="Raise Goal"
              validateStatus={errors.raise_goal ? 'error' : ''}
              help={errors.raise_goal ? errors.raise_goal.message : ''}
            >
              <Controller
                name="raise_goal"
                control={control}
                rules={{ required: 'Raise goal is required' }}
                render={({ field }) => (
                  <CurrencyInput
                    className="ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput"
                    placeholder="Please enter Money Goal"
                    defaultValue={(userData?.raise_goal ?? 0) / 100}
                    decimalsLimit={2}
                    onValueChange={(value) => {
                      const centsValue = value ? parseFloat(value) * 100 : 0;
                      field.onChange(centsValue);
                    }}
                    prefix="$"
                  />
                )}
              />
            </Form.Item>
            <Flex justify="space-between" align="center">
              {/* <Form.Item
          label="Show Name in Public Leaderboard"
          validateStatus={errors.is_active ? 'error' : ''}
          help={errors.is_active ? errors.is_active.message : ''}
        >
       
  <Controller
    name="in_leaderboard"
    control={control}
    render={({ field }) => (
      <Switch checked={field.value} onChange={field.onChange} />
    )}
  />
</Form.Item> */}

              <Form.Item
                label="Is Active"
                validateStatus={errors.is_active ? 'error' : ''}
                help={errors.is_active ? errors.is_active.message : ''}
              >
                <Controller
                  name="is_active"
                  control={control}
                  render={({ field }) => (
                    <Switch checked={field.value} onChange={field.onChange} />
                  )}
                />
              </Form.Item>
            </Flex>
            {user?.scope=="internal_admin"&&
            <>
            <Title level={4} style={{
              paddingLeft:"0px",
              paddingRight: "0px",
              border: "none"
            }}>
        User Permissions
      </Title>
            <Flex justify="space-between" align="center">
            <Form.Item
                label="Create Campaign"
                validateStatus={errors.permissions?.create_campaign ? 'error' : ''}
                help={errors.permissions?.create_campaign ? errors.permissions.create_campaign.message : ''}
              >
                <Controller
                  name="permissions.create_campaign"
                  control={control}
                  render={({ field }: { field: ControllerRenderProps<User, "permissions.create_campaign"> }) => (
                    <Switch checked={field.value} onChange={field.onChange} />
                  )}
                />
              </Form.Item>

              <Form.Item
                label="Edit Camppaign"
                validateStatus={errors.permissions?.edit_campaign ? 'error' : ''}
                help={errors.permissions?.edit_campaign ? errors.permissions.edit_campaign.message : ''}
              >
                <Controller
                  name="permissions.edit_campaign"
                  control={control}
                  render={({ field } :{ field: ControllerRenderProps<User, "permissions.edit_campaign"> }) => (
                    <Switch checked={field.value} onChange={field.onChange} />
                  )}
                />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
            <Form.Item
                label="Create Organization"
                validateStatus={errors.permissions?.create_group ? 'error' : ''}
                help={errors.permissions?.create_group ? errors.permissions.create_group.message : ''}
              >
                <Controller
                  name="permissions.create_group"
                  control={control}
                  render={({ field }:{ field: ControllerRenderProps<User, "permissions.create_group"> }) => (
                    <Switch checked={field.value} onChange={field.onChange} />
                  )}
                />
              </Form.Item>

              <Form.Item
                label="Edit Organization"
                validateStatus={errors.permissions?.edit_group ? 'error' : ''}
                help={errors.permissions?.edit_group ? errors.permissions.edit_group.message : ''}
              >
                <Controller
                  name="permissions.edit_group"
                  control={control}
                  render={({ field }:{ field: ControllerRenderProps<User, "permissions.edit_group"> }) => (
                    <Switch checked={field.value} onChange={field.onChange} />
                  )}
                />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
            <Form.Item
                label="Create Manual Order"
                validateStatus={errors.permissions?.create_order_manual ? 'error' : ''}
                help={errors.permissions?.create_order_manual ? errors.permissions.create_order_manual.message : ''}
              >
                <Controller
                  name="permissions.create_order_manual"
                  control={control}
                  render={({ field }:{ field: ControllerRenderProps<User, "permissions.create_order_manual"> }) => (
                    <Switch checked={field.value} onChange={field.onChange} />
                  )}
                />
              </Form.Item>
              
{(userData?.scope=="rep"||
//@ts-ignore
watch('scope')=="rep")&&
              <Form.Item
                label="Edit Platform and Processing Fee"
                validateStatus={errors.permissions?.edit_platform_and_processing_fee ? 'error' : ''}
                help={errors.permissions?.edit_platform_and_processing_fee ? errors.permissions.edit_platform_and_processing_fee.message : ''}
              >
                <Controller
                  name="permissions.edit_platform_and_processing_fee"
                  control={control}
                  render={({ field }:{ field: ControllerRenderProps<User, "permissions.edit_platform_and_processing_fee"> }) => (
                    <Switch checked={field.value} onChange={field.onChange} />
                  )}
                />
              </Form.Item>
}
            </Flex>
            </>}
          </Col>
        </Row>
       

<Form.Item
         label={
          <>
            Sub Groups
            <Tooltip title="Select what Sub Groups you'd like your sales to count towards.">
              <   QuestionCircleOutlined style={{ marginLeft: 8, color: '#888' }} />
            </Tooltip>
          </>
        }
         
        >
          {userData?.associated_groups?.map((group) => {
            if (group.tags.length === 0) return;
            return (
              <>
                <label
                  style={{ marginTop: 15, marginBottom: 15, display: 'block' }}
                >
                  {group.name}{' '}
                </label>
                <SelectTags
                  tagsData={group?.tags ?? []}
                  selectedTags={selectedTags[group.id] || []}
                  setSelectedTags={(tagId, checked) =>
                    handleTagSelection(group.id, tagId, checked, group.one_tag)
                  }
                />
              </>
            );
          })}
        </Form.Item>
        <Row justify={'end'}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={updateLoading}>
              Save Change
            </Button>
          </Form.Item>
        </Row>
        {updateError && (
          <div className="form-item">
            <Text type="danger">
              {error
                ? error.toString()
                : 'Failed to update user. Please try again.'}
            </Text>
          </div>
        )}
      </Form>

      <Drawer
        title="Select Organizations"
        width={400}
        loading={isLoadingGroups}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <List
          dataSource={groupsData?.data ?? []}
          renderItem={(group: Group) => (
            <List.Item key={group.id}>
              <Checkbox
                checked={selectedGroups.some((g) => g.id === group.id)}
                onChange={() => handleGroupSelect(group)}
              >
                {group.name}
              </Checkbox>
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
};

export default EditUser;

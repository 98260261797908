import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';

interface UploadResponse {
  response: string;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const utilsApi = createApi({
  reducerPath: 'utilsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).adminUser.token;
      headers.set('ngrok-skip-browser-warning', `true`);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    uploadFile: builder.mutation<UploadResponse, FormData>({
      query: (formData) => ({
        url: 'utils/upload_file',
        method: 'POST',
        body: formData,
      }),
    }),
    uploadAttachmentFile: builder.mutation<UploadResponse, FormData>({
      query: (formData) => ({
        url: 'utils/upload_attachment',  // 🔄 Updated to new endpoint
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const { useUploadFileMutation, useUploadAttachmentFileMutation } = utilsApi;

import React from 'react';
import { Card, Button, Tag } from 'antd';
import Meta from 'antd/es/card/Meta';
import Title from 'antd/es/typography/Title';

import '../styles/CampaignCard.scss'; // Make sure this path is correct
import ShareButtonStatic from './ShareButtonStatic';
import { useLocation } from 'react-router-dom';


type ActiveCampaignCardProps = {
  name: string;
  endDate: string;
  status: string;
  onDetailsClick: () => void;
  campaignId: string;
  userId?: number;
  title: string;
  blurb: string;
  link: string;
  groupId: number;
};

const ActiveCampaignCard: React.FC<ActiveCampaignCardProps> = ({
  name,
  endDate,
  status,
  onDetailsClick,
  campaignId,
  userId,
  title,
  blurb,
  link,
  groupId
}) => {
  const handleNavigateToCampaign = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL_FE}raise/${link}/${userId}`,
      '_blank'
    );
  };
  const location = useLocation();
  return (
    <Card
      className={`campaign-card ${status.toLowerCase()}`}
      style={{
        width: "100%",
        margin: 0,
        border: 'none',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <Tag
        bordered={false}
        color={status === 'Active' ? 'green' : 'red'}
        style={{
          padding: '4px 12px',
        }}
      >
        {status === 'Active' ? 'Active Campaign' : 'Closed Campaign'}
      </Tag>
      <Title
        level={2}
        style={{
          marginBlockStart: 5,
          marginBlockEnd: 5,
        }}
        ellipsis={{
          rows: 1,
        }}
      >
        {`${name.length > (location.pathname.includes("organizer-dashboard") ? 40 : 20) 
    ? `${name.slice(0, location.pathname.includes("organizer-dashboard") ? 40 : 20)}...` 
    : name}`}
      </Title>
      <div
        style={{
          marginTop: 10,
        }}
      ></div>
      <Meta title={endDate} description="End Date:" />
      <div
        style={{
          marginTop: 10,
        }}
      ></div>

      {/* <Meta 
        title={amountRaised} 
        description="Raised by Me:" 
      /> */}
        <div className={`details-button ${status.toLowerCase()}`}>
        {/* <span className="details-text">{status} Campaign</span> */}
        <Button type="primary" onClick={handleNavigateToCampaign}>
          View store
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: '#ffffff',
            border: '1px solid #C31532',
            color: '#C31532',
          }}
          onClick={onDetailsClick}
        >
          Details
        </Button>
      </div>
      <div
        style={{
          marginTop: 10,
        }}
      ></div>
      <ShareButtonStatic
        campaignId={campaignId ?? ''}
        userId={userId ?? 0}
        showEmail={false}
        schoolName={title ?? ''}
        blurb={blurb ?? ''}
        groupId={groupId}
      />
      {/* <Meta 
        title={ <ShareButtonStatic 
          campaignId={campaignId ?? ''}
          userId={userId?? 0}
          showEmail={false}
            schoolName={title ?? ''}
            blurb={blurb ?? ''}
           />} 
        description="Share" 
      /> */}

    
    </Card>
  );
};

export default ActiveCampaignCard;

import React from 'react';
import '../styles/AddProduct.scss';
import ImageUploader from './ImageUploader';
import { Switch, Form, Input, Button, Typography, Tooltip, Row, Col, Flex } from 'antd';
import myImage from '../assets/images/PlaceholderImage.png';
import CurrencyInput from 'react-currency-input-field';
import { useForm, Controller } from 'react-hook-form';
import { useAddProductMutation } from '../services/admin/inventoryApi';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Title, Text } = Typography;

const AddProduct: React.FC = () => {
  const { register, handleSubmit, control, formState: { errors,isDirty }, setValue, watch } = useForm({
    defaultValues: {
      primary_image_url: '',
      fulfilment_type: 'STS',
      cost: 0,
      name: '',
      blurb: '',
      internal_name: '',
      internal_id: '',
      sku: ''
    }
  });

  const primaryImageUrl = watch('primary_image_url');
  const [addProduct, { isLoading, isError, isSuccess }] = useAddProductMutation();
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    try {
      await addProduct(data).unwrap();
      console.log('Product added successfully:', data);
      navigate(-1);
    } catch (error) {
      console.error('Failed to add product:', error);
    }
  };

  const handleImageUpload = (imageUrl: string) => {
    setValue('primary_image_url', imageUrl, { shouldValidate: true });
  };

  return (
    <div className="add-product-container">
      <Title level={2}>Add New Product</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="add-product-form">
     
 <Row>
            <Col flex={3}>
        <Form.Item
          label="Name"
          validateStatus={errors.name ? 'error' : ''}
          help={errors.name ? errors.name.message : ''}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <Input
                type="text"
                {...field}
                placeholder='Enter Product Name'
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Internal Name"
          validateStatus={errors.internal_name ? 'error' : ''}
          help={errors.internal_name ? errors.internal_name.message : ''}
        >
          <Controller
            name="internal_name"
            control={control}
            rules={{ required: 'Internal Name is required' }}
            render={({ field }) => (
              <Input
                type="text"
                {...field}
                placeholder='Enter Internal Name'
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Internal ID"
          validateStatus={errors.internal_id ? 'error' : ''}
          help={errors.internal_id ? errors.internal_id.message : ''}
        >
          <Controller
            name="internal_id"
            control={control}
            rules={{ required: 'Internal ID is required' }}
            render={({ field }) => (
              <Input
                type="text"
                {...field}
                placeholder='Enter Internal ID'
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Sku"
          validateStatus={errors.sku ? 'error' : ''}
          help={errors.sku ? errors.sku.message : ''}
        >
          <Controller
            name="sku"
            control={control}
            rules={{ required: 'sku is required' }}
            render={({ field }) => (
              <Input
                type="text"
                {...field}
                placeholder='Enter sku'
              />
            )}
          />
        </Form.Item>
        <Row gutter={20} >
        <Col span={12}>
        <Form.Item
          label="Fulfilment Type"
          validateStatus={errors.fulfilment_type ? 'error' : ''}
          help={errors.fulfilment_type ? errors.fulfilment_type.message : ''}
        >
          <Controller
            name="fulfilment_type"
            control={control}
            render={({ field }) => (
              <div className="switch-container">
                <span>STH</span>
                <Switch
                  checked={field.value === 'STS'}
                  onChange={(checked) => field.onChange(checked ? 'STS' : 'STH')}
                />
                <span>STS</span>
              </div>
            )}
          />
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
  label="Cost"
  validateStatus={errors.cost ? 'error' : ''}
  help={errors.cost ? errors.cost.message : ''}
>
  <Controller
    name="cost"
    control={control}
    rules={{ required: 'Cost is required' }}
    render={({ field }) => (
      
      <CurrencyInput
     className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
      placeholder="Enter Product Cost"
      defaultValue={field.value}
      decimalsLimit={2}
      onValueChange={(value) => {
        const centsValue = value ? parseFloat(value) * 100 : 0;
        field.onChange( centsValue );
      }}
      prefix="$"
    />
    )}
  />
</Form.Item>
</Col>
</Row>
        </Col>
        <Col flex={2} style={{
              paddingLeft: 40
            }}>
           <Form.Item
          label="Primary Image URL"
          validateStatus={errors.primary_image_url ? 'error' : ''}
          help={errors.primary_image_url ? errors.primary_image_url.message : ''}
        >
           <Flex justify='center' align='center' vertical style={{
                  border: "1px solid  #d9d9d9",
                  paddingBottom: 40
                 }}>
          <Controller
            name="primary_image_url"
            control={control}
            rules={{ required: 'Primary image is required' }}
            render={({ field }) => (
              <>
                 <div style={{
                      width: "100%",
                      alignSelf: "center",
                      display:"flex",
                      justifyContent: "center"
                    }}>
                     
                {primaryImageUrl ?
                  <img src={primaryImageUrl} alt="Primary" style={{ width: '160px', height: '160px',borderRadius:20 ,border:"1px dashed #C31532",padding:20,marginTop:40}} />
                  :<img src={ myImage} alt="Primary" style={{ width: '160px', height: '160px',borderRadius:20 ,border:"1px dashed #C31532",padding:20,marginTop:40 }} />
                }
                </div>
                <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center',height:'100%' }}>
                      <p  style={{
                        width: 300,
                        textAlign: "center",
color: "#6E7485"
          }} >
         Upload your User Primary Image here.<Text strong style={{
            borderBottom:"none",
            padding: 0
           }}>  Important guidelines:</Text> For best results, please upload a 512 × 512. Supported format:<Text strong style={{
            borderBottom:"none",
            padding: 0
           }}>   .jpg or .png</Text>
          </p>
                <ImageUploader onUpload={(imageUrl) => handleImageUpload(imageUrl)}
                width={512}
                height={512} 
                />
                 {/* <Tooltip title="For best results, please upload a 512 × 512 png or jpg">
                    <QuestionCircleOutlined style={{ marginLeft: 8,marginTop:12, color: '#1890ff' }} />
                  </Tooltip> */}
                </div>

              </>
            )}
          />
          </Flex>
        </Form.Item>
        </Col>
        </Row>
       
       
        <Row>
            <Col flex={5}>
        <Form.Item
          label="Blurb"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}
            rules={{ required: 'Blurb is required' }}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={8}
                placeholder='Enter your description...'
              />
            )}
          />
        </Form.Item>
        </Col>
          </Row>
        <Row justify={'center'}>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled ={!isDirty}>Add Product</Button>
        </Form.Item>
        </Row>
        {isError && <p>Failed to add product. Please try again.</p>}
        {isSuccess && <p>Product added successfully!</p>}
      </Form>
    </div>
  );
};

export default AddProduct;

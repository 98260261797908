import React, { useEffect, useState } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  WhatsappIcon,
  PinterestShareButton,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import { useLocation, useParams } from 'react-router-dom';
import { Modal, Input, Form, message as Toast, QRCode, Button, Spin, Divider, Checkbox, Flex, Typography } from 'antd';
import { FaSms } from 'react-icons/fa';
import { InputMask } from '@react-input/mask';
import '../styles/ShareButtonsStatic.scss';
import { useGetCampaignForUserQuery, useGetLeaderboardForPublicCampaignBasedOnTagsQuery } from '../services/campaign';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faQrcode } from '@fortawesome/free-solid-svg-icons';
import Title from 'antd/es/typography/Title';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import PublicLeaderBoard from './PublicLeaderBoard';
import { Controller, useForm } from 'react-hook-form';
import { Group } from '../types.ts';
import CurrencyInput from 'react-currency-input-field';
import { useEditCampaignMutation, useEditCampaignOnlyMutation, useGetCampaignpByIdQuery } from '../services/admin/inventoryApi';
import ImageUploader from './ImageUploader';

interface ShareButtonsProps {
  schoolName: string;
  blurb: string;
  showEmail?: boolean;
  campaignId: string;
  userId: number;
  groupId: number;
}

const ShareButtonStatic: React.FC<ShareButtonsProps> = ({
  schoolName,
  blurb,
  showEmail = true,
  campaignId,
  userId,
  groupId,
}) => {
 
  const defaultLogoUrl = 'https://storage.googleapis.com/frm-reporting/fund-dash-user/1739522830.4327264_i75DFhoV2osDLTnDv4h5vn.png';
const defaultHeroUrl = 'https://storage.googleapis.com/frm-reporting/fund-dash-user/1725657145.5373192_njLgoSL9xrghZ6K5XzznUC.png';
  const { campaign_id } = useParams<{ campaign_id: string }>();
  const location = useLocation();
  const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_BASE_URL_FE);
  const user = useSelector((state: RootState) => state.adminUser.user);

  const [isLeaderBoardVisible, setIsLeaderBoardVisible] = useState(false); // Sta
 
  const { data: campaignsData, isLoading: isLoadingCampaigns , isError} = useGetCampaignpByIdQuery({ campaign_id: parseInt(campaignId || "0") });
  const { data: studentData = [], error: errorFetchingTagLeaderBoard, isLoading: isLoadingStudents ,isFetching} = useGetLeaderboardForPublicCampaignBasedOnTagsQuery({
    campaignId: campaignId || "0",
    groupId: campaignsData?.group?.id ?? 0,
    skip: 0,
    limit: 10000,
  }, {skip: !campaignsData?.group?.id|| !campaignId});
  const [campaignUrl, setcampaignUrl] = useState(
    `${process.env.REACT_APP_BASE_URL_FE}raise/${campaignsData?.campaign_link}${
      userId ? `/${userId}` : ''
    }`
  );
  const [registrationUrl, setRegistrationUrl] = useState(
    `${baseUrl}signup/${campaignsData?.group_id}`
  );
  const subject = `${schoolName} is Raising Funds`;
  const message = `${blurb} \n\nSupport us here: ${campaignUrl}`;
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [useDefaultLogo, setUseDefaultLogo] = useState(false);
  const [emailError, setEmailError] = useState('');
  const { Title, Paragraph,Text } = Typography;
  const [isSmsModalVisible, setIsSmsModalVisible] = useState(false);
  const [updateCampaign, { isLoading:isUpdating, isError:isUpdatingError, isSuccess }] = useEditCampaignOnlyMutation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const { register, handleSubmit, control, formState: { errors,isDirty }, setValue, watch } = useForm({
    defaultValues: {
      campaign_link: '',
      logo_image_url: defaultLogoUrl,
      hero_image_url: defaultHeroUrl,
      video_url: '',
      title: '',
      blurb: '',
      money_goal: 0,
      money_raised: 0,
      end_date: null,
      start_date: null,
      is_closed: false,
      donations_enabled: false,
      leader_board_enabled: false,
      recent_orders_enabled:false,
      group_id: null,
      group_name: '',
      catalog_id: null,
      catalog_name: '',
      ship_promo_enabled: false,
      pre_sale_id: 0,
      is_paid: false,
      campaign_send_rep_1:false,
      campaign_send_admin_1_send: false,
      campaign_send_contact_goal_1_send: false,
      campaign_send_contact_end_1_send: false,
    }
  });
  const logoImageUrl = watch('logo_image_url');
  const showEmailModal = () => {
    setIsEmailModalVisible(true);
  };
  useEffect(() => {
    if (campaignsData) {
      const campaign = campaignsData;
      if (campaign) {
        setValue('logo_image_url', campaign.logo_image_url);
        setValue('blurb', campaign.blurb);
        setValue('money_goal', campaign.money_goal);
       
       
      }
    }
  }, [campaignsData, campaignId, setValue]);
  useEffect(() => {
    setBaseUrl(process.env.REACT_APP_BASE_URL_FE);
  }, [process.env.REACT_APP_BASE_URL_FE]);
  const handleEmailOk = () => {
    if (!emailError) {
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(message)}`;
      window.location.href = mailtoLink;
      setIsEmailModalVisible(false);
    }
  };

  const handleEmailCancel = () => {
    setIsEmailModalVisible(false);
  };

  const showSmsModal = () => {
    setIsSmsModalVisible(true);
  };
  function doDownload(url: string, fileName: string) {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  const handleSmsOk = () => {
    if (!phoneError) {
      const smsLink = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
      window.location.href = smsLink;
      setIsSmsModalVisible(false);
    }
  };
  const downloadCanvasQRCode = () => {
    const canvas = document
      .getElementById('myqrcode')
      ?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      const url = canvas.toDataURL('image/png');
      doDownload(url, 'QRCode.png');
    }
  };
  const downloadCanvasRegistrationQRCode = () => {
    const canvas = document
      .getElementById('myqrcoderegistration')
      ?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      const url = canvas.toDataURL('image/png');
      doDownload(url, 'QRCodeRegistration.png');
    }
  };
  const handleSmsCancel = () => {
    setIsSmsModalVisible(false);
  };
  useEffect(() => {
    setcampaignUrl(
      `${process.env.REACT_APP_BASE_URL_FE}raise/${campaignsData?.campaign_link}${
        userId ? `/${userId}` : ''
      }`
    );
  }, [campaignsData?.campaign_link]);
  useEffect(() => {
    setRegistrationUrl(
      `${baseUrl}signup/${campaignsData?.group_id}`
    );
  }, [campaignsData?.group_id]);
  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  }, [email]);

  useEffect(() => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (phoneNumber && phoneNumber.length < 10) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
  }, [phoneNumber]);
  const copyToClipboard = () => {
    // @ts-ignore
    navigator.clipboard.writeText(`${campaignUrl}`);
    Toast.success('Campaign link copied to clipboard');
  };
  const copyRegistrationLinkToClipboard = () => {
    // @ts-ignore
    navigator.clipboard.writeText(`${registrationUrl}`);
    Toast.success('Registration link copied to clipboard');
  };
  const handleLeaderBoardClose = () => {
    setIsLeaderBoardVisible(false); // Close the leaderboard modal
  };
  const onSubmitImage = async (data?: any) => {
    // data.end_date = moment(data.end_date).utc().toISOString();
    if (useDefaultLogo) {
      data.logo_image_url = defaultLogoUrl;
    }
     try {
       await updateCampaign({ id: parseInt(campaignId || '0'),...campaignsData, catalog_id:campaignsData?.catalog?.id ?? null,
       logo_image_url:useDefaultLogo?defaultLogoUrl:watch('logo_image_url')
      }).unwrap();
       //@ts-ignore
       Toast.success('Your changes have been saved');
       console.log('Campaign updated successfully:', data);
       // navigate(-1);
     } catch (error) {
           // @ts-ignore
           Toast.error(error?.data?.detail ||'Failed to update campaign.');
     }
   };
  const handleLogoImageUpload = (imageUrl: string) => {
    setValue('logo_image_url', imageUrl, { shouldValidate: true });
    setUseDefaultLogo(false);
    onSubmitImage();
  };
  const onSubmit = async (data: any) => {
    // data.end_date = moment(data.end_date).utc().toISOString();
    if (useDefaultLogo) {
      data.logo_image_url = defaultLogoUrl;
    }
     try {
       await updateCampaign({ id: parseInt(campaignId || '0'),...campaignsData, money_goal:watch("money_goal") , catalog_id:campaignsData?.catalog?.id ?? null,
       logo_image_url:useDefaultLogo?defaultLogoUrl:watch('logo_image_url')
      }).unwrap();
       //@ts-ignore
       Toast.success('Your changes have been saved');
       console.log('Campaign updated successfully:', data);
       // navigate(-1);
     } catch (error) {
           // @ts-ignore
           Toast.error(error?.data?.detail ||'Failed to update campaign.');
     }
   };


  if (isError) return <></>;

  if (isLoadingCampaigns) return <Spin spinning={true} />;

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <TwitterShareButton
          url={campaignUrl}
          title={subject}
          className="share-button"
        >
          <XIcon
            size={32}
            round={false}
            style={{
              borderRadius: 5,
            }}
          />
        </TwitterShareButton>
        {showEmail && (
          <div className="share-button" onClick={showEmailModal}>
            <EmailIcon
              size={32}
              round={false}
              style={{
                borderRadius: 5,
              }}
            />
          </div>
        )}
        {/* <div className="share-button" onClick={showSmsModal}>
          <FaSms size={32} />
        </div> */}
        <FacebookShareButton url={campaignUrl} className="share-button">
          <FacebookIcon
            size={32}
            round={false}
            style={{
              borderRadius: 5,
            }}
          />
        </FacebookShareButton>
        <LinkedinShareButton
          url={campaignUrl}
          title={subject}
          summary={message}
          className="share-button"
        >
          <LinkedinIcon
            size={32}
            round={false}
            style={{
              borderRadius: 5,
            }}
          />
        </LinkedinShareButton>
        <PinterestShareButton
          url={campaignUrl}
          media={`${window.location.origin}/path/to/image`}
          className="share-button"
        >
          <PinterestIcon
            size={32}
            round={false}
            style={{
              borderRadius: 5,
            }}
          />
        </PinterestShareButton>
        <WhatsappShareButton
          url={campaignUrl}
          title={subject}
          separator=":: "
          className="share-button"
        >
          <WhatsappIcon
            size={32}
            round={false}
            style={{
              borderRadius: 5,
            }}
          />
        </WhatsappShareButton>
      </div>
      {location.pathname.includes("organizer-dashboard")&&<>
      <Spin spinning={isLoadingCampaigns}>
        <Title level={4}>Edit Campaign</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="edit-campaign-form" style={{padding:0}}>
      <Flex  align="center" className='flexRow' justify="space-between">
      <Form.Item
          label=""
          validateStatus={errors.logo_image_url ? 'error' : ''}
          help={errors.logo_image_url ? errors.logo_image_url.message : ''}
          
        >
          <Checkbox
            checked={useDefaultLogo}
            onChange={(e) => {
              setUseDefaultLogo(e.target.checked);
              
            }}
            style={{
              paddingBlock:10
            }}
          >
            Use Default Logo
          </Checkbox>
          {(
              <Flex justify='center' align='center' vertical style={{
                border: "1px solid  #d9d9d9",
                paddingBottom: 40,
                paddingTop:40
               }}>
            <Controller
              name="logo_image_url"
              control={control}
              rules={{ required: 'Logo image is required' }}
              render={({ field }) => (
                <>
                  {(useDefaultLogo?defaultLogoUrl:logoImageUrl) && (
                    <img src={useDefaultLogo?defaultLogoUrl:logoImageUrl} alt="Logo" style={{ width: '256px', height: '105.6px' }} />
                  )}
               {!useDefaultLogo &&    <div style={{ display: 'flex', alignItems: 'center',flexDirection:"column"  }}>
                    <ImageUploader onUpload={(imageUrl) => handleLogoImageUpload(imageUrl)} width={640} height={264} aspectRatio={2.42}/>
               
                    {/* <Tooltip title="For best results, please upload a 640 × 264 png or jpg">
                      <QuestionCircleOutlined style={{ marginLeft: 8, marginTop: 20, color: '#1890ff' }} />
                    </Tooltip> */}
                  </div>}
                </>
              )}
            />
            </Flex>
          )}
          

        </Form.Item>
        <Flex justify="space-between" vertical >

        
             <Form.Item
          label="Group Money Goal"
          validateStatus={errors.money_goal ? 'error' : ''}
          help={errors.money_goal ? errors.money_goal.message : ''}
          style={{width:"100%"}}
        >
          <Controller
            name="money_goal"
            control={control}
            rules={{ required: 'Money goal is required' }}
            render={({ field }) => (
              <CurrencyInput
              className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
               placeholder="Please enter Goal"
               defaultValue={((campaignsData?.money_goal)??0)/100}
               value={field.value/100}
               decimalsLimit={2}
               onValueChange={(value) => {
                 const centsValue = value ? parseFloat(value) * 100 : 0;
                 field.onChange( centsValue );
               }}
               prefix="$"
             />
            )}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary"  onClick={onSubmit}
          loading={isUpdating}
          >Update Campaign</Button>
        </Form.Item>
        </Flex>
        </Flex>
        
        </Form>
        </Spin>
     
      </>}
      <Flex>
      <Flex justify="space-between" vertical>
        <div>
      <Title level={4}>Copy Campaign Store Link</Title>
     
      <div style={{ display: 'flex' }}>
      <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 32,
            height: 32,
            borderRadius: 8,
            backgroundColor: '#ffc107',
            cursor: 'pointer',
          }}
          onClick={copyToClipboard}
        >
        
          <FontAwesomeIcon
            icon={faClone}
            style={{ fontSize: 22, color: '#ffffff' }}
          />
        </div>
      <Title level={5} style={{
        fontWeight:"400",
        margin: 0,
        fontSize: 12,
        width: "80%",
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10
      }}>{campaignUrl}</Title>
       
      </div>
      </div>
      {((user?.scope === ('internal_admin')||(user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&
   <>
      <Title level={4}>Copy Campaign Seller Invite Link</Title>
     
     <div style={{ display: 'flex' }}>
     <div
         style={{
           display: 'inline-flex',
           alignItems: 'center',
           justifyContent: 'center',
           width: 32,
           height: 32,
           borderRadius: 8,
           backgroundColor: '#ffc107',
           cursor: 'pointer',
         }}
         onClick={copyRegistrationLinkToClipboard}
       >
       
         <FontAwesomeIcon
           icon={faClone}
           style={{ fontSize: 22, color: '#ffffff' }}
         />
       </div>
     <Title level={5} style={{
       fontWeight:"400",
       margin: 0,
       fontSize: 12,
       width: "80%",
       display: 'flex',
       alignItems: 'center',
       marginLeft: 10
     }}>{registrationUrl}</Title>
      
     </div>
     </>
      )}
      </Flex>
      <Flex justify="space-between" vertical>
      <>
     
     <div>
      <Title level={4}>Download QR code</Title>
      <div style={{ display: 'flex' }}>
        <div id="myqrcode" style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 32,
              height: 32,
              borderRadius: 8,
              backgroundColor: '#4caf50',
              cursor: 'pointer',
            }}
            onClick={downloadCanvasQRCode}
          >
            <FontAwesomeIcon
              icon={faQrcode}
              style={{ fontSize: 22, color: '#ffffff' }}
            />
          </div>
          <QRCode
            value={`${campaignUrl}`}
            size={60}
            style={{
              width: '5px',
              height: '5px',
              padding: '5px',
              border: 'none',
              overflow: 'visible',
            }}
          />
        </div>
      </div>
      </div>
 
      {((user?.scope === ('internal_admin')||(user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&
     <div>
         <Title level={4}>Download Registration QR code</Title>
      <div style={{ display: 'flex' }}>
        <div id="myqrcoderegistration" style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 32,
              height: 32,
              borderRadius: 8,
              backgroundColor: '#4caf50',
              cursor: 'pointer',
            }}
            onClick={downloadCanvasRegistrationQRCode}
          >
            <FontAwesomeIcon
              icon={faQrcode}
              style={{ fontSize: 22, color: '#ffffff' }}
            />
          </div>
          <QRCode
            value={`${registrationUrl}`}
            size={60}
            style={{
              width: '5px',
              height: '5px',
              padding: '5px',
              border: 'none',
              overflow: 'visible',
            }}
          />
        </div>
      </div>
      </div>
      )}
      </>
      </Flex>
         </Flex>
     
    
      {location.pathname.includes("organizer-dashboard")&&
      <Button type="primary"  onClick={()=>{
       setIsLeaderBoardVisible(true)
       }} style={{ marginRight: '8px',marginTop:'15px', width:'100%' }}>
       View Leaderboard
      </Button>}
    
      
      <Modal
        title="Share via Email"
        visible={isEmailModalVisible}
        onOk={handleEmailOk}
        onCancel={handleEmailCancel}
      >
        <Form.Item
          label="Email"
          validateStatus={emailError ? 'error' : ''}
          help={emailError}
        >
          <div className="share-button-form">
            <Input
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </Form.Item>
      </Modal>
      {isLeaderBoardVisible&&
      <PublicLeaderBoard
      studentData={studentData}
      error={errorFetchingTagLeaderBoard}
      isLoadingStudents={isLoadingStudents}
      isFetching={isFetching}
      isClickable={true}
      campaignId={campaignId || "0"}
      groupId={campaignsData?.group?.id ?campaignsData?.group?.id : 0}
      visible={isLeaderBoardVisible}
      onClose={handleLeaderBoardClose}
      isMobile={false} // Or pass a prop to determine if the device is mobile
    />}
      {/* <Modal title="Share via SMS" visible={isSmsModalVisible} onOk={handleSmsOk} onCancel={handleSmsCancel}>
        <Form.Item label="Phone Number" validateStatus={phoneError ? 'error' : ''} help={phoneError}>
          <InputMask
mask="(___) ___-____"
replacement={{ _: /\d/ }}
placeholder="(xxx) yyy-yyyy"
onMask={(e) => {
  const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
  setPhoneNumber(value);
  setPhoneError('')
}}
style={{
  padding: "12px",
  border: "1px solid #ddd"
}}
         />
        </Form.Item>
      </Modal> */}
    </>
  );
};

export default ShareButtonStatic;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { Campaign, Student } from '../../types.ts';


const BASE_URL = process.env.REACT_APP_BASE_URL;

export const campaignApi = createApi({
  reducerPath: 'campaignApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).adminUser.token;
     
      headers.set('ngrok-skip-browser-warning', `true`);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCampaignForUser: builder.query<Campaign, string>({
      query: (userId) => `campaigns/for_campaign/${userId}`,
    }),
    getCampaignForStudent: builder.query<Student, {userId:string,campaignId:string}>({
      query: ({userId,campaignId}) => `users/for_campaign/${userId}/${campaignId}`,
    }),
    getLeaderboardForPublicCampaign: builder.query<Campaign[], { campaignId: string; skip: number; limit: number }>({
      query: ({ campaignId, skip, limit }) => ({
        url: `report/table/leaderboard_public/${campaignId}`,
        params: { skip, limit },
      }),

    }),
    getLeaderboardForPublicCampaignBasedOnTags: builder.query<Campaign[], { campaignId: string; skip: number; limit: number, groupId: number }>({
      query: ({ campaignId, skip, limit ,groupId}) => ({
        url: `report/table/leaderboard_public_tags/${campaignId}/${groupId}`,
        params: { skip, limit },
      }),
    }),
    createOrder: builder.mutation({
      query: (order) => ({
        url: 'orders/',
        method: 'POST',
        body: order,
      }),
    }),
    calculateShipping: builder.mutation({
      query: (shippingInfo) => ({
        url: 'orders/',
        method: 'POST',
        body: shippingInfo,
      }),
    }),

    
    postMessage: builder.mutation({
      query: (messageInfo) => ({
        url: 'orders/post_message/',
        method: 'POST',
        body: messageInfo,
      }),
    }),
    getCampaignMessage: builder.query({
      query: (campaignId) => ({
        url: `orders/post_message/${campaignId}`,
      }),
    }),
  }),
});

export const { usePostMessageMutation,  useGetCampaignMessageQuery,  useGetCampaignForUserQuery, useGetCampaignForStudentQuery,useGetLeaderboardForPublicCampaignQuery, useCreateOrderMutation,useCalculateShippingMutation, useGetLeaderboardForPublicCampaignBasedOnTagsQuery ,} = campaignApi;

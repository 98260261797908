import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Table,
  Upload,
  Select,
  InputNumber,
  Spin,
  notification,
  message,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import '../styles/AddOrder.scss';
import Title from 'antd/es/typography/Title';
import { useHotkeys } from 'react-hotkeys-hook';
import { useUploadAttachmentFileMutation } from '../services/utils'; // Adjust the path as needed

import { useParams } from 'react-router-dom';
import {
  useCreateOrderManuallyMutation,
  useGetCampaignpByIdQuery,
  useGetInventoryProductsQuery,
} from '../services/admin/inventoryApi';
import UserSelect from './UserSelectDropDown';
import { InputMask } from '@react-input/mask';
import CurrencyInput from 'react-currency-input-field';
import { NotificationPlacement } from 'antd/es/notification/interface';

const { Option } = Select;

const AddOrder = () => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      reference: '',
      email: '',
      phone: '',
      donationAmount: '',
      studentEnteredAmount: '',
    },
  });

  const selectedUser = watch('name');
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { id } = useParams<{ id: string }>();
  const [api, contextHolder] = notification.useNotification();
  const [uploadAttachmentFile, { isLoading: isUploading }] = useUploadAttachmentFileMutation();
  const { data: campaignsData, isLoading: isLoadingCampaigns } =
    useGetCampaignpByIdQuery({ campaign_id: parseInt(id || '0') });
  const {
    data: productsData,
    error: productsError,
    isLoading: productsLoading,
    isFetching: productsFetching,
  } =
   
    useGetInventoryProductsQuery({
      skip: 0,
      limit: 1000,
       //@ts-ignore
      catalog_id: parseInt(campaignsData?.catalog?.id || '0'),
    });
    const [createManualOrder, { isLoading: isCreatingOrder }] = useCreateOrderManuallyMutation();

  // Product Management State
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [userId, setUserId] = useState<number>(0);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [phone, setPhone] = useState<number>(0);
  const [fileList, setFileList] = useState([]);

  // Hotkey navigation state
  const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(0);

  // 🔥 Full list of form fields for hotkey navigation
  const openNotification = (
    placement: NotificationPlacement,
    data?: string
  ) => {
    api.error({
      message: data ?? `Something went wrong`,
      placement,
    });
  };
  function extractString(input: string) {
    const regex = /\['(.*)'\]/;
    const match = input.match(regex);
    return match ? match[1] : null;
  }
  const handleCreateManualOrder = async (resetForm = false) => {
    const values = watch(); // Get all form values
console.log(values.name,"hyujn")
  // ✅ Backup Check: Ensure Email Exists & Follows Correct Pattern
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!values.email || !emailRegex.test(values.email)) {
    message.error("Please enter a valid email address.");
    return; // 🚫 Prevent submission
  }
const donationAmount  = parseFloat(watch('donationAmount')) || 0;
    if (!donationAmount && selectedProducts.length === 0) {
      message.error("Please add either a donation amount or at least one product.");
      return; // 🚫 Prevent submission
    }
 
      try {
        const response = await createManualOrder({
          campaign_id: parseInt(campaignsData?.id ?? '1'),
          reference_id: watch('reference'),
          user_id: userId>0 ? Number(userId) : undefined,
          student_name_freeform: !userId ? watch('name') : undefined, // ✅ Handle manual entry
          email: watch('email'),
          recipient_phone: phone,
         file_uploads: imageUrl?imageUrl:'',
          product_ids: selectedProducts.flatMap((item) =>
            Array(item.quantity).fill(item.key)
          ),
          is_quote: false,   // ✅ Not a quote
          amount_donation: Math.ceil(parseFloat(watch('donationAmount'))*100) || 0,  // 💰 Donation Amount
          amount_product: selectedProducts.reduce(
            (total, item) => total + item.price * item.quantity,
            0
          ),  // 💰 Product Cost
          amount_platform_fee: 0,   // ✅ Zero Platform Fee
          amount_total:Math.ceil( parseFloat(watch('studentEnteredAmount'))*100) || 0, // 💰 Student Entered Amount
        }).unwrap();

        if (response.is_success) {
            message.success("Order created successfully!")
            if (resetForm) {
                resetFormData();  
              }
        } else {
          openNotification('topLeft', extractString(response.message) ?? 'Order creation failed.');
        }
      } catch (error) {
        //@ts-ignore
        openNotification('topLeft', error?.data?.detail || 'Failed to create order.');
        console.error('Failed to create manual order:', error);
      }
    
};
const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await uploadAttachmentFile(formData).unwrap();
  
      if (response?.response) {
        console.log(response.response)
        const newFile = {
          //@ts-ignore
          uid: `${file?.uid}`, // Maintain unique identifier
          name: file.name,
          status: 'done',
          url: response?.response,
      };
      //@ts-ignore
      setFileList((prev) => [...prev, newFile]);
        message.success('File uploaded successfully!');
        //@ts-ignore
        setImageUrl((prevImageUrl) => {
          // Combine the new URL with existing URLs (if any)
          //@ts-ignore
          return prevImageUrl ? `${prevImageUrl},${response?.response}` : response?.response;
      });
        //@ts-ignore
        return response.url; // ✅ Return the uploaded file URL
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('File upload error:', error);
      message.error('Failed to upload file. Please try again.');
      return null;
    }
  };
const resetFormData = () => {
    setSelectedProducts([]);
    setSelectedProduct(null);
    //@ts-ignore
    inputRefs.current[0]?.clearSelection();
    setFileList([])
    // Reset all form fields
    setImageUrl('')
    //@ts-ignore
    setValue("donationAmount",0)
    //@ts-ignore
  setValue("studentEnteredAmount",0)
    setValue('name', '');
    setValue('reference', '');
    setValue('email', '');
    setValue('phone', '');
  
  };
  // Add product logic
  const addProduct = () => {
    if (selectedProduct) {
      const product = productsData?.find((p: any) => p.id === selectedProduct);
      if (product) {
        const existingProduct = selectedProducts.find(
          (p) => p.key === product.id
        );

        if (existingProduct) {
          const updatedProducts = selectedProducts.map((p) =>
            p.key === product.id
              ? {
                  ...p,
                  quantity: p.quantity + quantity,
                  total: (p.quantity + quantity) * p.price,
                }
              : p
          );
          setSelectedProducts(updatedProducts);
        } else {
          setSelectedProducts([
            ...selectedProducts,
            {
              key: product.id,
              image: product.primary_image_url,
              sku: product.sku,
              name: product.name,
              price: campaignsData?.catalog?.price_schedules?.[product.id]?campaignsData?.catalog?.price_schedules?.[product.id]:product?.cost,
              quantity,
              total: (campaignsData?.catalog?.price_schedules?.[product.id]?campaignsData?.catalog?.price_schedules?.[product.id]:product?.cost) * quantity,
            },
          ]);
        }

        setSelectedProduct(null);
        setQuantity(1);
      }
    }
  };

  // Remove product logic
  const removeProduct = (key: string) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.key !== key
    );
    setSelectedProducts(updatedProducts);
  };

  // Update product quantity logic
  const updateQuantity = (key: string, newQuantity: number) => {
    const updatedProducts = selectedProducts.map((product) =>
      product.key === key
        ? {
            ...product,
            quantity: newQuantity,
            total: product.price * newQuantity,
          }
        : product
    );
    setSelectedProducts(updatedProducts);
  };

  const onSubmit = (data: any) => {
    console.log('Form Data:', data);
    console.log('Selected Products:', selectedProducts);
  };

  const handleForward = useCallback(() => {
    const activeElement = document.activeElement as HTMLInputElement;
    console.log(activeElement);
    const isSelectField =
      activeElement.getAttribute('aria-label') === 'select-product';

    // Identify the correct index
    const currentIndex = isSelectField
      ? 4 // Hardcode or dynamically detect the Select’s index
      : Number(activeElement?.getAttribute('data-index')) || 0;
    // Identify current index from `data-index` attribute

    const nextIndex = currentIndex === 10 ? 0 : currentIndex + 1;

    // 🔍 Find the next available input field and focus it
console.log(currentIndex,nextIndex,currentFocusIndex,"tgyhbj")
if (inputRefs.current[nextIndex]&&(currentIndex==0||currentIndex==4)) {
      inputRefs.current[nextIndex]?.focus();
      setCurrentFocusIndex(nextIndex);
    }

    // if (!foundNext) {
    //   inputRefs.current[0]?.focus();
    //   setCurrentFocusIndex(0);
    // }
  }, [setCurrentFocusIndex, currentFocusIndex]);

  useEffect(() => {
    if (isLoadingCampaigns) return;
  
    const tryFocus = () => {
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      } else {
        requestAnimationFrame(tryFocus); // Retry until the element is available
      }
    };
  
    requestAnimationFrame(tryFocus);
  }, [isLoadingCampaigns]);
  

  console.log(currentFocusIndex);

  useHotkeys(
    'enter',
    (e) => {
      handleForward();
    },
    { enableOnFormTags: true }
  );

  // Product Table Columns
  const productColumns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (text: string) => (
        <img
          src={text}
          alt="Product"
          style={{ width: '100px', height: '100px' }}
        />
      ),
    },
    { title: 'SKU', dataIndex: 'sku', key: 'sku' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    //@ts-ignore
    { title: 'Price', dataIndex: 'price',render: (value, ) => (
       <p>${value/100}</p>
      ), key: 'price' },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      //@ts-ignore
      render: (_, record) => (
        <InputNumber
        type="number"
          min={1}
          value={record.quantity}
          onChange={(value) => updateQuantity(record.key, value || 1)}
        />
      ),
    },
    { title: 'Total', dataIndex: 'total', key: 'total' ,
    //@ts-ignore
    render: (value, ) => (
      <p>${value/100}</p>
     ),},
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      //@ts-ignore
      render: (_, record) => (
        <Button type="link" danger onClick={() => removeProduct(record.key)}
        >
          Remove
        </Button>
      ),
    },
  ];
  if (isLoadingCampaigns) {
    return (
      <div
        className="edit-campaign-container"
        style={{ height: '100%', padding: 20 }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }
  console.log(errors.email)
  return (
    <>
          {contextHolder}
    <div className="add-order-container">
      <Spin spinning={isCreatingOrder}>
      <Title level={2} className="editGroup">
        Add Order
      </Title>
      <Form
      //@ts-ignore
        onFinish={handleSubmit(handleCreateManualOrder)}
        layout="vertical"
        className="edit-group-form"
        // onKeyDown={(e) => {
        //     if (e.key === 'Enter') {
        //         const activeElement = document.activeElement as HTMLElement;
        //         //@ts-ignore
        //         console.log(activeElement?.getAttribute('data-index'),activeElement?.getAttribute('data-index')===9)
        //         // ✅ Prevent form submission unless Save button is focused
        //         //@ts-ignore
        //         if (activeElement?.getAttribute('data-index') !== 9) {
        //             e.preventDefault()
        //         }
                
        //     }
        // }}
      >
        {/* Customer Details */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Name" >
              <Controller
                name="name"
                control={control}
                // rules={{
                //   required: "User Name is required", // ✅ Required Field
                
                // }}
                render={({ field }) => (
                  <UserSelect
                    // {...field}
                    //@ts-ignore
                    //@ts-ignore
                    ref={(el) => {
                      if (inputRefs.current) {
                        //@ts-ignore
                        inputRefs.current[0] = el;
                      }
                    }}
                    data-index="0"
                    //@ts-ignore
                    width={'100%'}
                    allowAddNew={true}
                    
                    onChange={(value,option) => {
                      console.log(value,option)
                        if (typeof value === 'number') {
                            setUserId(value)
                        }else{
                          setUserId(0)
                            setValue("name",option?.full_name)
                        }
                        
                        console.log(value,option)
                    }}
                    groupId={campaignsData?.group_id}
                  />
                )}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Reference ID">
              <Controller
                name="reference"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    //@ts-ignore
                    ref={(el) => {
                      if (inputRefs.current) {
                        //@ts-ignore
                        inputRefs.current[1] = el;
                      }
                    }}
                    data-index="1"
                    placeholder="Enter Reference ID"
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Email"           validateStatus={errors.email ? 'error' : ''}
          help={errors.email ? errors.email.message : ''}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required", // ✅ Required Field
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // ✅ Email Pattern
                    message: "Please enter a valid email address",
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    //@ts-ignore
                    ref={(el) => {
                      if (inputRefs.current) {
                        //@ts-ignore
                        inputRefs.current[2] = el;
                      }
                    }}
                    data-index="2"
                    placeholder="Enter Email"
                  />
                )}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Phone">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                    <InputMask
                    {...field}
                    style={{
                        padding: 16,
                        borderColor: "#d9d9d9",
                        width:"100%",
                        border: "1px solid #ddd",
                        fontSize:16
                    }}
                      mask="(___) ___-____"
                      replacement={{ _: /\d/ }}
                      placeholder="(xxx) yyy-yyyy"
                      onMask={(e) => {
                        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                        //@ts-ignore
                        setPhone(value>0||value?.length>0?value:null);

                      }}
                      ref={(el) => {
                        if (inputRefs.current) {
                          //@ts-ignore
                          inputRefs.current[3] = el;
                        }
                      }}
                      data-index="3"
                    />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* Product Selection */}
        <Form.Item label="Products">
          <Table
            dataSource={selectedProducts}
            bordered
            columns={productColumns}
            pagination={false}
            locale={{ emptyText: 'No products added yet. Add products below.' }}
          />
          <Input.Group compact style={{ marginTop: 30 }}>
            <Select
              ref={(el) => {
                // For Ant Design's Select, use `.focus()` method from the instance
                if (el) {
                  //@ts-ignore
                  inputRefs.current[4] = {
                    focus: () => el.focus({ preventScroll: false }),
                  };
                }
              }}
              showSearch  // ✅ Enables the search feature
    filterOption={(input, option) =>
      //@ts-ignore
        option?.children.toLowerCase().includes(input.toLowerCase()) || // Search by name
        option?.['data-sku']?.toLowerCase().includes(input.toLowerCase()) // Search by SKU
    }
              data-index="4"
              aria-label="select-product"
              style={{ width: '70%', borderRadius: 10 }}
              placeholder="Select a product"
              value={selectedProduct}
              dropdownRender={(menu) => (
                <div data-index="4"> {menu} </div> // ✅ Track index on rendered dropdown
              )}
              onChange={(value) => setSelectedProduct(value)}
              loading={productsLoading || productsFetching}
              notFoundContent={
                productsLoading || productsFetching ? (
                  <Spin size="small" />
                ) : (
                  'No users found'
                )
              }
              virtual={true}
            >
              {productsData?.map((product: any) => (
                <Option key={product.id} value={product.id} data-sku={product.sku}>
                  {product.name}
                </Option>
              ))}
            </Select>

            <InputNumber
            type="number"
              style={{ width: '20%' }}
              min={1}
              value={quantity}
              ref={(el) => {
                if (inputRefs.current) {
                  //@ts-ignore
                  inputRefs.current[5] = el;
                }
              }}
              data-index="5"
              onChange={(value) => setQuantity(value || 1)}
            />

            <Button
              type="primary"
              onClick={addProduct}
              ref={(el) => {
                if (inputRefs.current) {
                  //@ts-ignore
                  inputRefs.current[6] = el;
                }
              }}
              data-index="6"
            >
              Add
            </Button>
          </Input.Group>
        </Form.Item>
        {/* Upload Section */}
        <Form.Item label="Upload Order Form(s)">
  <Controller
  //@ts-ignore
    name="uploadedFile"
    control={control}
    render={({ field: { onChange, value } }) => (
      <Upload.Dragger
      fileList={fileList}
      itemRender={(originNode, file) => {
        // Clone `originNode` and modify only the file name
        return React.cloneElement(originNode, {
          children: React.Children.map(originNode.props.children, (child) => {
            if (
              React.isValidElement(child) &&
              //@ts-ignore
              child.props.className?.includes('ant-upload-list-item-name')
            ) {
              // Replace `<a>` with `<span>` but keep the structure
              return (
                <span
                //@ts-ignore
                  tabIndex="0"
                  className="file-name"
                  onClick={() => window.open(file.url, '_blank')}
                  role="link"
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: '#1890ff',
                  }}
                >
                  {file.name}
                </span>
              );
            }
            return child; // Retains the delete icon and other elements
          }),
        });
      }}
       // fileList={value ? [{ name: value.split('/').pop(), status: 'done' }] : []}
        beforeUpload={async (file) => {
          const uploadedFileUrl = await handleFileUpload(file); // 🔥 Upload immediately
          if (uploadedFileUrl) {
            onChange(uploadedFileUrl); // ✅ Save the uploaded URL to the form
          }
          return false; // Prevent default upload behavior
        }}
        onRemove={(file) => {
          console.log(file,"file")
          //@ts-ignore
          const updatedList = fileList.filter((item) => item.uid !== file.uid);
          setFileList(updatedList);
          //@ts-ignore
          const updatedUrls = updatedList.map((f) => f?.url).join(',');

    setImageUrl(updatedUrls); // ✅ Ensures URLs stay in sync
          //@ts-ignore
          onChange(updatedList.map((f) => f.url).join(','));
      }}
        accept=".pdf,.docx,.xlsx,.png,.jpg"
      >
        <p className="ant-upload-text">
          Click to upload or drag and drop files here
        </p>
      </Upload.Dragger>
    )}
  />
</Form.Item>
        {/* Donation and Student Amounts */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Donation Amount">
              <Controller
                name="donationAmount"
                control={control}
                render={({ field }) => (
                   <CurrencyInput
              className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
              placeholder="Enter Donation Amount"
              //@ts-ignore
              value={field.value?.toString() || ''}  // Store as string to preserve decimals
                  onValueChange={(value) => {
                    field.onChange(value); // Store the value directly as a string
                  }}
               defaultValue={field.value}
               decimalsLimit={2}
              
               prefix="$"
               ref={(el) => {
                if (inputRefs.current) {
                  //@ts-ignore
                  inputRefs.current[7] = el;
                }
              }}
              data-index="7"
             />
                )}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Student Entered Amount Collected">
              <Controller
                name="studentEnteredAmount"
                control={control}
                render={({ field }) => (
               
                  <CurrencyInput
                  className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
                  placeholder="Enter Amount Collected"
                  value={field.value?.toString() || ''}  // Store as string to preserve decimals
                  decimalsLimit={2}
                  onValueChange={(value) => {
                    field.onChange(value); // Store the value directly as a string
                  }}
                  
                  prefix="$"
                  ref={(el) => {
                    if (inputRefs.current) {
                      //@ts-ignore
                      inputRefs.current[8] = el;
                    }
                  }}
                  data-index="8"
                />
                
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Action Buttons */}
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
        >
          <Button
            type="primary"
            loading={isCreatingOrder}
            disabled={isCreatingOrder}
            style={{
              marginTop: 0,
            }}
            ref={(el) => {
              if (inputRefs.current) {
                //@ts-ignore
                inputRefs.current[9] = el;
              }
            }}
            //@ts-ignore
             onClick={handleCreateManualOrder}
            data-index="9"
          >
            
            Save
          </Button>
          <Button
            type="default"
            loading={isCreatingOrder}
            disabled={isCreatingOrder}
            ref={(el) => {
              if (inputRefs.current) {
                //@ts-ignore
                inputRefs.current[10] = el;
              }
            }}
            data-index="10"
            onClick={() => handleCreateManualOrder(true)}
          >
          
            Save & Start New
          </Button>
        </div>
      </Form>
      </Spin>
    </div>
    </>
  );
};

export default AddOrder;

import React, { useState } from 'react';
import { Table, Typography, Tag, Button, Popconfirm, notification } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useDeleteGroupGroupLinksByIdsMutation, useGetGroupLinksQuery } from '../services/admin/inventoryApi';
import { GroupLinkResponse } from '../types.ts';
import { useParams } from 'react-router-dom';

const { Text } = Typography;

const GroupLinksTable: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

    // Fetch data using RTK Query
    const { data, error, isLoading } = useGetGroupLinksQuery({
        group_id: parseInt(id || "0"),
        limit: 200,
        order_by: 'group_name',
    });

    const [deleteGroupLinks, { isLoading: isDeleting }] = useDeleteGroupGroupLinksByIdsMutation();

    // Handle Bulk Delete
    const handleBulkDelete = async () => {
        if (!selectedRowKeys.length) {
            notification.warning({ message: 'No records selected for deletion.' });
            return;
        }

        try {
            await deleteGroupLinks(selectedRowKeys).unwrap();
            notification.success({
                message: 'Success',
                description: `${selectedRowKeys.length} records deleted successfully.`,
            });
            setSelectedRowKeys([]);  // Clear selection after successful deletion
        } catch (error) {
            notification.error({
                message: 'Error',
                //@ts-ignore
                description: error?.data?.detail || 'Failed to delete records.',
            });
        }
    };

    // Define Table Columns
    const columns: ColumnsType<GroupLinkResponse> = [
        {
            title: 'Group Name',
            dataIndex: 'group_name',
            key: 'group_name',
            render: (text) => <Text strong>{text}</Text>,
        },
        {
            title: 'Linked Group Name',
            dataIndex: 'linked_group_name',
            key: 'linked_group_name',
        },
        {
            title: 'Sub Rep Username',
            dataIndex: 'sub_rep_username',
            key: 'sub_rep_username',
            render: (username) =>
                username ? <Tag color="blue">{username}</Tag> : <Text type="secondary">N/A</Text>,
        },
    ];

    // Loading State
    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error loading data. Please try again.</p>;

    return (
        <div>
            {/* Bulk Delete Button */}
            <Popconfirm
                title="Are you sure you want to delete selected records?"
                onConfirm={handleBulkDelete}
                okText="Yes"
                cancelText="No"
                disabled={!selectedRowKeys.length}
            >
                <Button
                    type="primary"
                    danger
                    style={{ marginBottom: 16 ,marginTop:16}}
                    loading={isDeleting}
                    disabled={!selectedRowKeys.length}
                >
                    Delete Selected
                </Button>
            </Popconfirm>

            {/* Table with Row Selection */}
            <Table
                rowSelection={{
                    selectedRowKeys,
                    //@ts-ignore
                    onChange: setSelectedRowKeys,
                }}
                dataSource={data || []}
                columns={columns}
                rowKey="id"
                style={{ marginTop: 10 }}
                pagination={false}
                bordered
            />
        </div>
    );
};

export default GroupLinksTable;

import React, { useEffect } from 'react';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { useAddGroupMutation } from '../services/admin/inventoryApi';
import '../styles/AddGroup.scss';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Form, Typography, Switch, Row, Flex, DatePicker, Divider } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { usePlacesWidget } from 'react-google-autocomplete';
import CurrencyInput from 'react-currency-input-field';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import Unauthorized from './UnAuthorised';

const { Title } = Typography;

const AddGroup: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: '',
      blurb: '',
      is_rep_group: false,
      group_default_blurb: 'Thank you for taking the time to check out my fundraiser page!  This is my chance to pitch in and help my organization reach our goal. Your help means the world to me!  Besides helping me reach my goal, you will get an amazing high-quality product.  Another way you can help is by sharing this page with your friends and family by clicking one of the sharing icons. Thanks in advance for your generosity!',
      group_contacts_required: 0,
      anticipated_last_funds_raised: 0,
      anticipated_seller_count:0,
      remittance_address_1: "",
  remittance_address_2: "",
  remittance_address_city: "",
  remittance_address_state: "",
  remittance_address_zip: "",
  group_default_goal:0,
  anticipated_end_date: null,
      anticipated_start_date: null,
      fee_platform_base:  Number(process.env.REACT_APP_FEE_PLATFORM_BASE ?? 230) / 100,
      fee_platform_percentage: process.env.REACT_APP_FEE_PLATFORM_PERCENTAGE
    },
  });

  const [addGroup, { isLoading, isError, isSuccess }] = useAddGroupMutation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.adminUser.user);
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_ADDRESS_KEY,
    onPlaceSelected: (place: any) => handlePlaceSelected(place),
    options: {
      types: ['geocode'],
      componentRestrictions: { country: 'usa' },
    },
  });

  const handlePlaceSelected = (place: any) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
    };

    for (const component of addressComponents) {
      const addressType = component.types[0];
      if (addressType === 'street_number') {
        newAddress.street1 += component.long_name + ' ';
      } else if (addressType === 'route') {
        newAddress.street1 += component.long_name;
      } else if (addressType === 'locality') {
        newAddress.city = component.long_name + ' ';
      } else if (addressType === 'sublocality_level_1') {
        newAddress.city += component.long_name;
      } else if (addressType === 'administrative_area_level_1') {
        newAddress.state = component.short_name;
      } else if (addressType === 'postal_code') {
        newAddress.postalCode = component.long_name;
      }
    }
    setValue("remittance_address_1",newAddress.street1)
    setValue("remittance_address_2",newAddress.street2)
    setValue("remittance_address_city",newAddress.city)
    setValue("remittance_address_state",newAddress.state)
    setValue("remittance_address_zip",newAddress.postalCode)
   
   
  };
  const onSubmit = async (data: FieldValues) => {
    try {
      const newGroup = await addGroup({...data,fee_platform_base: Math.ceil((data?.fee_platform_base || 0) * 100)}).unwrap();
      console.log('Group added successfully:', newGroup);
      navigate(`/edit-groups/${newGroup.id}`)
 
    } catch (error) {
      console.error('Failed to add group:', error);
    }
  };
  if (((user?.scope === 'rep'||user?.scope==='organizer')&&!user?.permissions?.create_group)) {


    return <Unauthorized />
  }
  return (
    <div className="add-group-container">
      <Title level={2} className="editGroup" style={{paddingLeft:20}}>
        Add New Organization
      </Title>
      <Form
        onFinish={handleSubmit(onSubmit)}
        layout="vertical"
        className="add-group-form"
      >
        <Form.Item
          label="Organization Name"
          validateStatus={errors.name ? 'error' : ''}
          help={errors.name ? errors.name.message : ''}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder="Enter Organization Name Here.."
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Seller Signup Page Text"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}
            rules={{ required: 'Description is required' }}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={8}
                placeholder="This is the text a seller sees when they sign up"
              />
            )}
          />
        </Form.Item>
        <Divider />
        <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Remittance address 1"
          validateStatus={errors.remittance_address_1 ? 'error' : ''}
          help={errors.remittance_address_1 ? errors.remittance_address_1.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_1"
            control={control}
            render={({ field }) => (
           
              <Input type="text"  placeholder='Enter address 1'  
              {...field}
              ref={(inputRef) => {
                // Forward the ref to the native input inside Ant Design's Input
                if (inputRef) {
                  (ref as React.MutableRefObject<HTMLInputElement | null>).current = inputRef.input;
                }
              }}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Remittance address 2"
          validateStatus={errors.remittance_address_2 ? 'error' : ''}
          help={errors.remittance_address_2 ? errors.remittance_address_2.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_2"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter address 2'/>
            )}
          />
        </Form.Item>
      </Flex>
      <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Remittance address city"
          validateStatus={errors.remittance_address_city ? 'error' : ''}
          help={errors.remittance_address_city ? errors.remittance_address_city.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_city"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter city'/>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Remittance address state"
          validateStatus={errors.remittance_address_state ? 'error' : ''}
          help={errors.remittance_address_state ? errors.remittance_address_state.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_state"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter address state'/>
            )}
          />
        </Form.Item>
      </Flex>
      <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Remittance address zip"
          validateStatus={errors.remittance_address_zip ? 'error' : ''}
          help={errors.remittance_address_zip ? errors.remittance_address_zip.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_zip"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter address zip'/>
            )}
          />
        </Form.Item>
      
      </Flex>
      <Divider />
              <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Anticipated seller count"
          validateStatus={errors.anticipated_seller_count ? 'error' : ''}
          help={errors.anticipated_seller_count ? errors.anticipated_seller_count.message : ''}
          style={{width:350}}
        >
          <Controller
            name="anticipated_seller_count"
            control={control}
            render={({ field }) => (
              <Input type="number" {...field} placeholder='Enter seller count'/>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Last Funds Raised"
          validateStatus={errors.anticipated_last_funds_raised ? 'error' : ''}
          help={errors.anticipated_last_funds_raised ? errors.anticipated_last_funds_raised.message : ''}
          style={{width:350}}
        >
          <Controller
            name="anticipated_last_funds_raised"
            control={control}
            render={({ field }) => (
              <CurrencyInput
              className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
               placeholder="Please enter Goal"
               defaultValue={0/100}
               value={field.value/100}
               decimalsLimit={2}
               onValueChange={(value) => {
                 const centsValue = value ? parseFloat(value) * 100 : 0;
                 field.onChange( centsValue );
               }}
               prefix="$"
             />
            )}
          />
        </Form.Item>
      </Flex>
      {/* <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Anticipated start date"
          validateStatus={errors.anticipated_start_date ? 'error' : ''}
          help={errors.anticipated_start_date ? errors.anticipated_start_date.message : ''}
          style={{width:350}}
        >
          <Controller
            name="anticipated_start_date"
            control={control}
            render={({ field }) => (
              <DatePicker
              {...field} 
              style={{ width: '100%' ,padding: 16,borderRadius: 0}} 
              showTime 
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Select End Date and Time"
            />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Anticipated end date"
          validateStatus={errors.anticipated_end_date ? 'error' : ''}
          help={errors.anticipated_end_date ? errors.anticipated_end_date.message : ''}
          style={{width:350}}
        >
          <Controller
            name="anticipated_end_date"
            control={control}
            render={({ field }) => (
              <DatePicker
              {...field} 
              style={{ width: '100%' ,padding: 16,borderRadius: 0}} 
              showTime 
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Select End Date and Time"
            />
            )}
          />
        </Form.Item>
      </Flex> */}
    
      <Divider />
    
      <Form.Item
          label="Seller Default Store Text "
          validateStatus={errors.group_default_blurb ? 'error' : ''}
          help={errors.group_default_blurb ? errors.group_default_blurb.message : ''}
        >
          <Controller
            name="group_default_blurb"
            control={control}
            render={({ field }) => (
              <TextArea
              {...field}
              rows={8}
              placeholder="This is the prefilled text that is shown on the student’s store."
            />
            )}
          />
        </Form.Item>

      <Flex justify="space-between" align="center" className='flexRow'>
      <Form.Item
    label="Seller default goal"
    validateStatus={errors.group_default_goal ? 'error' : ''}
    help={errors.group_default_goal ? errors.group_default_goal.message : ''}
    style={{width:350}}
  >
    <Controller
      name="group_default_goal"
      control={control}
      render={({ field }) => (
        <CurrencyInput
        className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
         placeholder="Please enter Goal"
         defaultValue={0/100}
         value={field.value/100}
         decimalsLimit={2}
         onValueChange={(value) => {
           const centsValue = value ? parseFloat(value) * 100 : 0;
           field.onChange( centsValue );
         }}
         prefix="$"
       />
      )}
    />
  </Form.Item>
      
        <Form.Item
          label="Contact Threshold"
          validateStatus={errors.group_contacts_required ? 'error' : ''}
          help={errors.group_contacts_required ? errors.group_contacts_required.message : ''}
          style={{width:350}}
        >
          <Controller
            name="group_contacts_required"
            control={control}
            render={({ field }) => (
              <Input type="number" {...field} placeholder='Enter contacts required count'/>
            )}
          />
        </Form.Item>
      </Flex>
      {(user?.scope === 'internal_admin'||user?.scope === 'rep') && <> <Divider />
      <Flex justify="space-between" align="center" className='flexRow'>
      <Form.Item
          label="Platform Base fee percentage"
          validateStatus={errors.fee_platform_percentage ? 'error' : ''}
          help={errors.fee_platform_percentage ? errors.fee_platform_percentage.message : ''}
          style={{width:350}}
        >
          <Controller
            name="fee_platform_percentage"
            control={control}
            render={({ field }) => (
              //@ts-ignore
              <Input type="number" {...field} placeholder='Enter base fee'
              disabled={(user?.scope === ('rep')&&!user?.permissions?.edit_platform_and_processing_fee)}
              />
            )}
          />
        </Form.Item>
  <Form.Item
    label="Platform base amount"
    validateStatus={errors.fee_platform_base ? 'error' : ''}
    help={errors.fee_platform_base ? errors.fee_platform_base.message : ''}
    style={{width:350}}
  >
    <Controller
      name="fee_platform_base"
      control={control}
      render={({ field }) => (
        <Input type="number" {...field} placeholder='Enter base fee' addonBefore="$" 
        disabled={(user?.scope === ('rep')&&!user?.permissions?.edit_platform_and_processing_fee)}
        />
      )}
    />
  </Form.Item>
</Flex>
<Divider /></>}
        {user?.scope === 'internal_admin' && (
          <Form.Item
            label="Is Fundraising Company"
            validateStatus={errors.is_rep_group ? 'error' : ''}
            help={errors.is_rep_group ? errors.is_rep_group.message : ''}
          >
            <Controller
              name="is_rep_group"
              control={control}
              render={({ field }) => (
                <Switch checked={field.value} onChange={field.onChange} />
              )}
            />
          </Form.Item>
        )}
        <Row justify={'center'}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={!isDirty}
            >
              Add Organization
            </Button>
          </Form.Item>
        </Row>
        {isError && <p>Failed to add Organization. Please try again.</p>}
        {isSuccess && <p>Organization added successfully!</p>}
      </Form>
    </div>
  );
};

export default AddGroup;

import React, { useEffect, useRef, useState } from "react";
import { Drawer, Form, Input, Flex, Spin, Tag, Select, Button, Tooltip, message, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import usePagination from "../hooks/usePagination";
import { useDeleteOrdersByIdByAdminMutation, useGetGroupByIdQuery, useGetUsersQuery, useSearchUsersQuery, useUpdateTagsForOrdersMutation } from "../services/admin/inventoryApi";
import { QuestionCircleOutlined } from "@ant-design/icons";
import SelectTags from "./SelectTags";
import { Tag as TagType, User } from "../types.ts";
import UserSelect from "./UserSelectDropDown";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const { Option } = Select;

interface OrderDetailsDrawerProps {
  isDrawerOpen: boolean;
  handleCloseDrawer: () => void;
  selectedRowData: any;
  isLoadingProduct: boolean;
  isErrorProduct: boolean;
  dataLoadingProduct: any;
  formatCurrency: (value: number) => string;
  setSelectedUser: (value: string) => void;
  onSubmit: () => void;
  isLoadingTagUser: boolean;
  setFreeFormName: (value: string) => void;
}
interface UserOptionsProps { 
    userId:number,
  
    fullName: string;
    email: string;
  
  }

const OrderDetailsDrawer: React.FC<OrderDetailsDrawerProps> = ({
  isDrawerOpen,
  handleCloseDrawer,
  selectedRowData,
  isLoadingProduct,
  isErrorProduct,
  setFreeFormName,
  dataLoadingProduct,
  formatCurrency,
  setSelectedUser,
  onSubmit,
  isLoadingTagUser,
}) => {
  console.log(dataLoadingProduct,selectedRowData.product_ids)
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [searchTermUser, setSearchTermUser] = useState<string>('');
  const [hasMore, setHasMore] = useState(true); // Track if more users exist
  const [debouncedSearchTermUser, setDebouncedSearchTermUser] = useState<string>(searchTermUser);
  const [users, setUsers] = useState<User[]>([]);
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { currentPage, pageSize, onPageChange,onPageSizeChange } = usePagination(1,5);
  const user = useSelector((state: RootState) => state.adminUser.user);
    const { currentPage: allUserCurrentPage, pageSize: allUsersPageSize, onPageChange: onAllUsersPageChange } = usePagination(0, 5);
    const [deleteOrdersByIdByAdmin, { isLoading: isDeleting }]=useDeleteOrdersByIdByAdminMutation();
    //@ts-ignore
    const [selectedTags, setSelectedTags] = useState<{ [key: number]: number[] }>({}); 
    const [userOptions, setUserOptions] = useState<UserOptionsProps[]>([]);
    const [updateTag, { isLoading: updateLoading, isError: updateError, error }] = useUpdateTagsForOrdersMutation();
    const {
      data: groupsData,
      error: groupsError,
      isLoading: groupsLoading,
      isFetching: groupsFetching,
    } = useGetGroupByIdQuery({ group_id: selectedRowData?.campaign?.group_id  },{
      skip: !selectedRowData?.campaign?.group_id
    });
    // const { data: usersData, error: usersError, isLoading: usersLoading, isFetching: usersFetching } = useGetUsersQuery({
    //   skip: (allUserCurrentPage -1 )*5,
    //   limit: 5 ,
    // });
      // const { data:allUsers , isFetching:isUsersFetching,isLoading:isUsersLoading,status:statusUsers} = useSearchUsersQuery({
      //   key: "full_name",
      //   value: debouncedSearchTermUser,
      //   limit: 5,
      // });
      // useEffect(() => {
      //   if (usersData?.data?.length) {
      //     setUsers((prevUsers) => [...prevUsers, ...(usersData?.data??[])]); // Append new users
      //     setHasMore(usersData?.data?.length === pageSize); // If full pageSize is loaded, more users exist
      //   } else {
      //     setHasMore(false);
      //   }
      // }, [usersData]);
      useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedSearchTermUser(searchTermUser);  // Set debounced search term after delay
        }, 300);  // Adjust the delay as needed
    
        return () => {
          clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
        };
      }, [searchTermUser]);
    
      useEffect(() => {
     
         
             //@ts-ignore
             if (selectedRowData?.tags) {
              //@ts-ignore
              const initialSelectedTags = selectedRowData.tags.reduce((acc, tag) => {
                // If the group_id already exists in the accumulator, add the tag id
                if (acc[tag?.group_id]) {
                  acc[tag.group_id].push(tag.id);
                } else {
                  // If the group_id doesn't exist, initialize it with an array containing the tag id
                  acc[tag.group_id] = [tag.id];
                }
                return acc;
              }, {} as { [key: number]: number[] });
        
              // Set the selected tags state
              setSelectedTags(initialSelectedTags);
            }
        
      }, [selectedRowData]);
      const handleOk =async () => {
        try {
          await deleteOrdersByIdByAdmin(selectedRowData.id).unwrap();
          message.success(`Order ID ${selectedRowData.id} deleted successfully.`);
      } catch (error) {
        //@ts-ignore
          message.error(error?.data?.detail || 'Failed to delete order.');
      }
          setIsModalVisible(false);
      };
      const handleCancel = () => setIsModalVisible(false);
      // useEffect(() => {
      //   // Assuming the user data comes from another API or part of the current data
      //   if (usersData && usersData.data) {
      //     const users = usersData.data.map(user => ({
      //       userId: user.id,
      //       fullName: user.full_name,
      //       email: user.email,
      //     }));
      //     setUserOptions(users);
      //   }
      // }, [usersData]);
      // const handleSearch = (value: string) => {
      //   if (usersData && usersData.data) {
      //     const users = usersData.data.map(user => ({
      //       userId: user.id,
      //       fullName: user.full_name,
      //       email: user.email,
      //     }));
      //     const filteredOptions = users.filter(option =>
      //       option.fullName.toLowerCase().includes(value.toLowerCase()) ||
      //       option.email.toLowerCase().includes(value.toLowerCase())
      //     );
      //     setUserOptions(filteredOptions);
          
      //   }
      
        
        
      // };
      //@ts-ignore
      function isNumber(value) {
        return typeof value === 'number' && !isNaN(value);
    }
      const handleTagSelection = (groupId: number, tagId: number, checked: boolean, oneTag: boolean) => {
        setSelectedTags((prevState) => {
          const groupSelectedTags = prevState[groupId] || [];
    
          if (oneTag && checked && groupSelectedTags.length > 0) {
            // If only one tag is allowed and a tag is already selected, show warning
            message.warning(`You can only select one tag for this group.`);
            return prevState; // Do not update state
          }
    
          return {
            ...prevState,
            [groupId]: checked
              ? [...groupSelectedTags, tagId] // Add tag
              : groupSelectedTags.filter((id) => id !== tagId), // Remove tag
          };
        });
      };
      const updateTags = async() => {
        try {
          // Perform the PATCH request
          //@ts-ignore
          await updateTag({ tag_ids: selectedTags[selectedRowData.campaign.group_id], order_id: selectedRowData.id }).unwrap();
          message.success('Order updated successfully!');
          // navigate(-1);
        } catch (err) {
          console.error('Failed to update Order:', err);
          message.error('Failed to update Order. Please try again.');
        }
      };
      const handleUserChange = (value: string | null) => {
        setSearchTermUser("");
        setKey("")
        setValue("")
      };
      const handleUserSearch = (value: string) => {
        setSearchTermUser(value);  // Update the search term
      };
      const showModal = () => {
       
        //@ts-ignore
     
        setIsModalVisible(true);}
       
  return (
    <>
    <Drawer open={isDrawerOpen} onClose={()=>{
       setTimeout(() => {
        //@ts-ignore
      inputRefs.current[0]?.clearSelection();
        handleCloseDrawer();
      }, 0);
     
      handleCloseDrawer()
    
    }} title="Order Details" width={500}>
      {selectedRowData && (
        <Spin spinning={isLoadingProduct}>
          <Form layout="vertical">
            <Flex justify="space-between" align="center">
              <Form.Item label="Full Name">
                <Input value={`${selectedRowData.recipient_first_name} ${selectedRowData.recipient_last_name}`} readOnly />
              </Form.Item>
              <Form.Item label="Email">
                <Input value={selectedRowData.email} readOnly />
              </Form.Item>
            </Flex>
            <Form.Item label="Address 1">
              <Input value={selectedRowData.recipient_address_1} readOnly />
            </Form.Item>
            <Form.Item label="Address 2">
              <Input value={selectedRowData.recipient_address_2} readOnly />
            </Form.Item>
            <Flex justify="space-between" align="center">
              <Form.Item label="City">
                <Input value={selectedRowData.recipient_address_city} readOnly />
              </Form.Item>
              <Form.Item label="State">
                <Input value={selectedRowData.recipient_address_state} readOnly />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
              <Form.Item label="ZIP">
                <Input value={selectedRowData.recipient_address_zip} readOnly />
              </Form.Item>
              <Form.Item label="Phone">
                <Input value={selectedRowData.recipient_phone} readOnly />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
              <Form.Item label="Seller Name FreeForm">
                <Input value={selectedRowData.student_name_freeform} readOnly />
              </Form.Item>
              <Form.Item label="Donation Amount">
                <Input value={formatCurrency(selectedRowData.amount_donation)} readOnly />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
            <Form.Item label="Products">
  {!isErrorProduct && selectedRowData.product_ids?.length&&dataLoadingProduct ?
    Object.entries(
      //@ts-ignore
      selectedRowData?.product_ids?.reduce((acc, id) => {
        acc[id] = (acc[id] || 0) + 1;
        return acc;
      }, {} as Record<number, number>)
    )?.map(([id, count]) => (
      <Tag key={id}>
        {dataLoadingProduct[Number(id)]} x ({count})
      </Tag>
    )):(
     ' No products'
    )}
</Form.Item>
              <Form.Item label="Quote Number">
                <Input value={selectedRowData.quote_number} readOnly />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
              <Form.Item label="Campaign ID">
                <Input value={selectedRowData.campaign_id} readOnly />
              </Form.Item>
              {!(selectedRowData.student_name_freeform || !selectedRowData.user_id) && (
                <Form.Item label="Seller ID">
                  <Input value={selectedRowData.user_id} readOnly />
                </Form.Item>
              )}
            </Flex>

            {(selectedRowData.student_name_freeform || !selectedRowData.user_id) && (
              <Form.Item label="Seller ID">
                <Flex justify="space-between" align="center">
                  {/* <Select
                    showSearch
                    loading={true}
                    placeholder="Select a user"
                    optionFilterProp="children"
                    onSearch={handleSearch}
                    onChange={(value) => setSelectedUser(value)}
                    style={{ width: "280px", height: "53px", borderRadius: 2 }}
                  >
                    {userOptions.map((option) => (
                      <Option key={option.userId} value={option.userId}>
                        {option.fullName} ({option.email})
                      </Option>
                    ))}
                  </Select> */}
               
               
                <UserSelect 
                ref={(el) => {
                  if (inputRefs.current) {
                    //@ts-ignore
                    inputRefs.current[0] = el;
                  }
                }}
                onChange={(value,option) => 
                
                  {
                    if(isNumber(value)){

//@ts-ignore
setSelectedUser(value)
                    }else{
                      //@ts-ignore
setFreeFormName(option?.full_name)
//@ts-ignore
setSelectedUser(0)

                    }
                    
                  }
                } 
                  allowAddNew={true}
                  groupId={selectedRowData?.campaign?.group_id }
                  />
        
                  <Button type="primary" onClick={onSubmit} loading={isLoadingTagUser}>
                    Tag User
                  </Button>
                </Flex>
              </Form.Item>
            )}
<Spin spinning={groupsFetching||groupsLoading}>
              <Form.Item
         label={
          <>
            Sub Groups
            <Tooltip title="Select what Sub Groups you'd like your sales to count towards.">
              <   QuestionCircleOutlined style={{ marginLeft: 8, color: '#888' }} />
            </Tooltip>
          </>
        }
         
        >

             { selectedTags&&<>
              <label style={{marginTop:15,marginBottom:15,display:"block"}}>{groupsData?.name} </label>
              <SelectTags tagsData={
              groupsData?.tags ??[]
            } 
            selectedTags={selectedTags[groupsData?.id??0] || []}
              setSelectedTags={(tagId, checked) => handleTagSelection(groupsData?.id??0, tagId, checked, false)}
            />
             <Button type="primary" onClick={updateTags}  loading={updateLoading}>
                    Tag Order
                  </Button>
              </>}
            
            </Form.Item>
            </Spin>
            <Form.Item label="Created At">
              <Input value={new Date(selectedRowData.created_at).toLocaleString()} readOnly />
            </Form.Item>
            <Form.Item label="Message">
              <TextArea
                value={selectedRowData.message}
                defaultValue={selectedRowData.message}
                readOnly
                autoSize={{ minRows: 3, maxRows: 5 }}
                style={{ wordWrap: "break-word", whiteSpace: "normal" }}
              />
            </Form.Item>
            <Form.Item label="Product Amount">
              <Input value={formatCurrency(selectedRowData.amount_product)} readOnly />
            </Form.Item>
          </Form>
          {user?.scope === 'internal_admin'&&!selectedRowData.manual&&
            <Button type="primary" danger onClick={(e) =>
            //@ts-ignore
            showModal()}  loading={isDeleting}>
                Delete Order
            </Button>}
        </Spin>
      )}
    </Drawer>
    <Modal
    //@ts-ignore
        title="Confirm Deletion"
        open={isModalVisible}
        loading={isDeleting}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes, Delete"
        cancelText="Cancel"
    >
        <p>Are you sure you want to delete this item?</p>
    </Modal>
    </>
  );
};

export default OrderDetailsDrawer;

import React, { useEffect, useState } from 'react';
import { Table, Pagination, Spin, message, Button, Flex, Typography, Card, Image, Input, Modal, Form, DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DownloadOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';

import { useDownloadReportMutation, useEditCampaignMutation, useGetCampaignsQuery, useSearchCampaignsQuery,useDownloadReportPdfMutation } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Campaign } from '../types.ts'; // Ensure correct import path
import { convertUTCDateToLocalDate } from '../utils';
import PublicLeaderBoard from './PublicLeaderBoard';
import Title from 'antd/es/typography/Title';
import myImage from '../assets/images/AddCampaign.png';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import '../styles/CatalogsTable.scss';
import { Controller, useForm } from 'react-hook-form';

interface CampaignsTableProps {
  handleRowClick: (record: Campaign) => void;
  groupId? : number;
  groupName?:string;
}

const { Text } = Typography;

const CampaignsTable: React.FC<CampaignsTableProps> = ({ handleRowClick ,groupId,groupName=""}) => {
  
  const [key, setKey] = useState<string>(groupName?"group_name":"");
  const [showProfitReport, setShowProfitReport] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTimeOpen, setIsModalTimeOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [id, setId] = useState('');
  const [value, setValue] = useState<string>(groupName?groupName:"");
  const { data:campaignData , isFetching:isCampaignsFetching,isLoading:isCampaignsLoading,status} = useSearchCampaignsQuery({
    key: groupId ?"group_name":key,
    value: groupName?groupName:value,
    limit: 1000,
  },{skip:(!key)});
  useEffect(()=>{
    if(groupName){
setKey("group_name")
    }
  },[groupName])
  // Define table columns
  // @ts-ignore
  const getColumnCampaignNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Campaign Name"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          if(e.target.value===""){
            setKey("")
          }else{
            setKey("title")
          }
         
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="title"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
  });
   // @ts-ignore
   const getColumnGroupNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Organization Name"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          if(e.target.value===""){
            setKey("")
          }else{
          setKey("group_name")
          }
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="group_name"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
  });
   // @ts-ignore
   const getColumnassociatedGroupNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Organization Name"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          if(e.target.value===""){
            setKey("")
          }else{
          setKey("associated_rep_group")
          }
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="associated_rep_group"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
  });

  // Define table columns
  // @ts-ignore
  const getColumnCampaigIdProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Pre Sale Id"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          if(e.target.value===""){
            setKey("")
          }else{
          setKey("pre_sale_id")
          }
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="pre_sale_id"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
  });
  const showExtendModal = (campaign: Campaign) => {
    const targetDate =campaign?.end_date?convertUTCDateToLocalDate(new Date(campaign?.end_date) as Date):null
    //@ts-ignore
    setTime('end_date', targetDate?dayjs(targetDate) as unknown :null)
    setSelectedCampaign(campaign);
    setIsModalTimeOpen(true);
  };
  const showModal = async (recordId: string) => {
    if(!recordId) return
    recordId&& setId(recordId)
    setIsModalOpen(true);
    try {
      // Call the API and expect a response in CSV format
      //@ts-ignore
      const response = await downloadReport({campaignId: recordId,reportType: "based_group_order" }).unwrap();
      // @ts-ignore
      
      // Check if response is a Blob or string
      // @ts-ignore
    if(response){
      setShowProfitReport(true)
      }
    } catch (err) {
      console.error('Download Error:', err);
      //@ts-ignore
      setShowProfitReport(false)
    }

  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'is_closed',
      key: 'is_closed',
      sorter: (a: Campaign, b: Campaign) => Number(a.is_closed) - Number(b.is_closed),
      render: (text: boolean) => (!text ? <Button style={{
        backgroundColor: "#EFFEF5",
        border: "1px solid #10A957",
        color: "#10A957",
        padding: 18,
        fontSize: "12px"
       
      }}>OPEN</Button> : <Button style={{
        backgroundColor: "#FFF0F0",
        border: "1px solid #FF5E5E",
        color:"#FF5E5E",
        padding: 18,
        fontSize: "12px"
       
      }}>CLOSED</Button>),
    },
    {
      title: 'PreSale ID',
      dataIndex: 'pre_sale_id',
      key: 'pre_sale_id',
      sorter: (a: Campaign, b: Campaign) => a.pre_sale_id - b.pre_sale_id,
      render: (text: string) => <strong>{text}</strong>, 
      ...(!groupId&&getColumnCampaigIdProps('pre_sale_id'))
    },
    {
      title: 'Organization Name',
      dataIndex: ['group', 'name'], // Accessing nested group name
      key: 'group.name',
      ...(!groupId&&getColumnGroupNameProps("group_id"))
    },
    {
      title: 'Campaign Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: Campaign, b: Campaign) => a.title.localeCompare(b.title),
      ...(!groupId&&getColumnCampaignNameProps('name'))
    },
   
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      sorter: (a: Campaign, b: Campaign) => new Date(a.end_date).getTime() - new Date(b.end_date).getTime(),
      render: (text: string, record: Campaign) => {
        const endDate = convertUTCDateToLocalDate(new Date(record.end_date));
        const currentDate = convertUTCDateToLocalDate(new Date());
        const timeDifference = (endDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);
        
        return (
          <>
            <div>{convertUTCDateToLocalDate(endDate).toLocaleString()}</div>
            {!record.is_closed && timeDifference < 24&&timeDifference>0 && (
              <Button type="primary" danger onClick={(e) => {showExtendModal(record)
                e.stopPropagation(); // Prevent row click
                e.preventDefault()
              }}>
                Extend Campaign
              </Button>
            )}
          </>
        );
      },
    },
  
    {
      title: 'Money Raised',
      dataIndex: 'money_raised', // Adjust according to the actual data field
      key: 'money_raised',
      render: (amount: number) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(amount / 100);
      },
    },
    // {
    //   title: 'Leaderboard', // New Leaderboard column
    //   key: 'leaderboard',
    //   render: (_: any, record: Campaign) => (
    //     <Button 
    //     style={{
    //       color:"##40A9FF",
    //       fontSize: 16,
    //     }}
    //     onClick={(e) => {
    //       e.stopPropagation(); // Prevent row click
    //       handleLeaderBoardOpen(record);
    //     }}>
    //       <EyeOutlined  size={18} style={{
    //       color:"#40A9FF",
    //     }}/>
    //     <Text  style={{
    //       color:"#40A9FF",
    //       fontSize: 12,
    //       padding: "4px 1px"
    //     }}>View Leaderboard</Text>  
    //     </Button>
    //   ),
    // },
    {
      title: 'Start Date', // New Leaderboard column
      key: 'start_date',
      dataIndex: 'start_date',
      sorter: (a: Campaign, b: Campaign) =>
      new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
      render: (text: string,record:any) => {
        const targetDate = convertUTCDateToLocalDate(new Date(record.start_date) as Date);
      console.log(targetDate)
        return isNaN(targetDate.getTime()) ? 'Invalid Date' : targetDate.toLocaleString();
    },
  },
    {
      title: 'Rep Organization Associated',
      dataIndex: '',
      key: 'name',
      render: (record: any) => record.groups?.length&&record.groups[0]?.is_rep_group ? record.groups.map((g: { name: string }) => g.name).join(', ') : "-",
      ...(!groupId&&getColumnassociatedGroupNameProps("associated_rep_group"))
    },
    {
      title: 'Download Report',
      dataIndex: 'downloadReport',
      key: 'downloadReport',
      render: (text: string, record: Campaign) => (
        <>
        <Button onClick={(e)=> {
          showModal(record.id);
          e.stopPropagation(); // Prevent row click
        e.preventDefault()
       }} icon={<DownloadOutlined  size={18} style={{
          color:"#40A9FF",
        }}/>} style={{  width:"170px"}}>  <Text  style={{
          color:"#40A9FF",
          fontSize: 12,
          padding: "4px 1px"
        }}>Download Reports</Text> </Button>
           <Button 
        style={{
          color:"##40A9FF",
          fontSize: 16,
          marginTop: 20
        }}
        onClick={(e) => {
          e.stopPropagation(); // Prevent row click
          handleLeaderBoardOpen(record);
        }}>
          <EyeOutlined  size={18} style={{
          color:"#40A9FF",
        }}/>
        <Text  style={{
          color:"#40A9FF",
          fontSize: 12,
          padding: "4px 1px"
        }}>View Leaderboard</Text>  
        </Button>
        </>
        
      ),
    },
  ];
  const { register, handleSubmit, control, formState: { errors,isDirty },setValue:setTime, watch } = useForm({
    defaultValues: {
      
      end_date: null,
      
    }
  });
  const [isLeaderBoardVisible, setIsLeaderBoardVisible] = useState(false); // Sta
  const [campaignId, setCampaignId] = useState(""); // Sta
  const [group_id, setGroupId] = useState(0); // Sta
  const { currentPage, pageSize, onPageChange } = usePagination(1,10);
  const [downloadReport,{isLoading:isLoadingReport}] = useDownloadReportMutation();
  const [downloadReportPdf,{isLoading:isLoadingReportPdf}] = useDownloadReportPdfMutation();
  const [updateCampaign, { isLoading:isUpdating, isError, isSuccess }] = useEditCampaignMutation();
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.adminUser.user);
  const [sortColumn, setSortColumn] = useState<string | null>('created_at');  // Default sorting by Created At
  const [sortOrder, setSortOrder] = useState<boolean>(true);  // Default sorting order (descending)
  const { data, error, isLoading, isFetching } = useGetCampaignsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: 10,
    order_by: sortColumn ?? '',
    order_by_decending: sortOrder,  
  });

  useEffect(() => {
    if (error) {
      message.error('Failed to load campaigns');
    }
  }, [error]);

  const handleLeaderBoardOpen = (data:Campaign) => {
    setCampaignId(data?.id)
    setGroupId(data?.group?.id)
    setIsLeaderBoardVisible(true); // Open the leaderboard modal
  };

  const handleLeaderBoardClose = () => {
    setIsLeaderBoardVisible(false); // Close the leaderboard modal
  };

  const handleCancel = () => {
    setShowProfitReport(false)
    setIsModalOpen(false);
  };
  const onSubmit = async (data: any) => {
    if (!selectedCampaign) return;

    const updatedData = {
      id: selectedCampaign.id,
      
    };

    try {
      //@ts-ignore
      await updateCampaign({ id: parseInt(selectedCampaign.id || '0'), ...selectedCampaign,...data, group_id : selectedCampaign?.group.id ?? null,
      catalog_id :selectedCampaign?.catalog.id ?? null }).unwrap();
      message.success("Campaign extended successfully!");
      setIsModalOpen(false);
    } catch (error) {
      //@ts-ignore
      message.error(error?.data?.detail || "Failed to extend campaign.");
    }
  };
  const handleDownload = async (campaignId: string, reportType: 'based_order' | 'based_user' | 'based_product'| 'finance_based_order'|'based_frm_xml'|'based_group_order') => {
    try {
      // Call the API and expect a response in CSV format
      const response = await downloadReport({ campaignId, reportType }).unwrap();
      console.log(response,"ygh")
      // @ts-ignore
      
      // Check if response is a Blob or string
      // @ts-ignore
      if (response instanceof Blob) {
        const url = URL.createObjectURL(response);
  
        const link = document.createElement('a');
        link.href = url;
        if(reportType==='based_frm_xml'){
          link.setAttribute('download', `report_${campaignId}_${reportType}.xml`);
        }else{
          link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        }
       
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else if (typeof response === 'string') {
        // If response is a string, handle it as CSV content
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        message.error('Unexpected response format');
      }
    } catch (err) {
      console.error('Download Error:', err);
      //@ts-ignore
      if(err?.data?.detail){
        // @ts-ignore
        message.error(err?.data?.detail)
        return
      }else{
        message.error('Failed to download report');

      }
    }
  };
  const handleDownloadPdf = async (campaignId: string, reportType: 'based_order' | 'based_user' | 'based_product'| 'finance_based_order'|'based_frm_xml'|'based_group_order') => {
    try {
      // Call the API and expect a response in CSV format
      const response = await downloadReportPdf({ campaignId, reportType }).unwrap();
      console.log(response,"ygh")
      // @ts-ignore
      
      // Check if response is a Blob or string
      // @ts-ignore
      if (response instanceof Blob) {
        const url = URL.createObjectURL(response);
  
        const link = document.createElement('a');
        link.href = url;
        if(reportType==='based_frm_xml'){
          link.setAttribute('download', `report_${campaignId}_${reportType}.xml`);
        }else{
          link.setAttribute('download', `report_${campaignId}_${reportType}.pdf`);
        }
       
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else if (typeof response === 'string') {
        // If response is a string, handle it as CSV content
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${campaignId}_${reportType}.pdf`);
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        message.error('Unexpected response format');
      }
    } catch (err) {
      console.error('Download Error:', err);
      //@ts-ignore
      if(err?.data?.detail){
        // @ts-ignore
        message.error(err?.data?.detail)
        return
      }else{
        message.error('Failed to download report');

      }
    }
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setKey('')
    setSortColumn(sorter.field);  // Update the column being sorted
    const sortOrder = sorter.order=="ascend"?true:false;
    setSortOrder(!sortOrder);   // Update the sort order
   
  };
  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
        { data?.data?.length? <Table
          
            columns={columns}
            dataSource={groupId?campaignData: key?campaignData:data?.data || []}
            pagination={false}
            rowKey="id"
            loading={isCampaignsLoading||isLoading||isCampaignsFetching||isFetching}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowClassName="clickable-row"
            onChange={handleTableChange}
            title={()=><Flex justify="space-between" align="center" className='flexRow'>
            <Title level={2}>Campaign List </Title>
            <Button type='primary' onClick={()=>{
              if(!user ) return null
              if((activeTab==('tab1')||activeTab==('tab2'))&&((user?.scope === 'rep'||user?.scope==='organizer'))) return null;
              if (user?.scope === ('internal_admin')||(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&user?.permissions?.create_campaign)) {
              navigate(`/add-campaign`, { state: groupId })
              }else{
                message.error("you do not have sufficient permissions for this action, please contact admin")
              }
            }}>+ Add New Campaign</Button>
          </Flex>}
          footer={() => {return groupId ? <></> : key ? <></> : <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data?.count}
            onChange={onPageChange}
            showSizeChanger={false} />;} 
          }
          />:
          <Card
          
          style={{ width: "55%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
          cover={<Image alt="example" src={myImage} preview={false}/>}
        >
          <Meta title={<Title level={2} style={{
            textAlign: "center",
            marginBottom: "0px"
          }}>No Campaign is added yet</Title>} description={
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px"
          }}>
<Text style={{
  color: "#595959",
  fontSize: 16,
  fontWeight: 400,
}}>You haven’t added Campaign, let’s add now by clicking below.</Text>
           <Button type='primary' onClick={()=>{
              if(!user ) return null
              if((activeTab==('tab1')||activeTab==('tab2'))&&(user?.scope === 'rep'||user?.scope==='organizer')) return null;
              if (user?.scope === ('internal_admin')||(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&user?.permissions?.create_campaign)) {
              navigate(`/add-campaign`)
              }else{
                message.error("you do not have sufficient permissions for this action, please contact admin")
              }
            }}>+ Add New Campaign</Button>
          </div>
          }
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
          />
          
        </Card>
        }
         
           {isLeaderBoardVisible&&
      <PublicLeaderBoard
      //@ts-ignore
      studentData={undefined}
      isClickable={true}
      campaignId={campaignId || '0'}
      groupId={group_id??groupId}
      visible={isLeaderBoardVisible}
      onClose={handleLeaderBoardClose}
      isMobile={false} // Or pass a prop to determine if the device is mobile
    />}
        <Modal title="Download Reports" open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel}>
          <Spin spinning={isLoadingReport||isLoadingReportPdf}>
          <Button onClick={() => handleDownload(id, "based_order")} icon={<DownloadOutlined />} style={{
            width:"100%",
            marginBottom: 10
          }}> { 'Download Order Report'}</Button>
          {showProfitReport&& <Button onClick={() => 
        
          handleDownload(id, "based_group_order")} icon={<DownloadOutlined />} style={{
            width:"100%",
            marginBottom: 10
          }}> { 'Download Profit Report'}</Button>}
            
         {showProfitReport&& <Button onClick={() =>
          //@ts-ignore
          handleDownloadPdf(id, "based_group_order_pdf")} icon={<DownloadOutlined />} style={{
            width:"100%",
            marginBottom: 10
          }}> { 'Download Profit Report pdf'}</Button>}
          <Button onClick={() => handleDownload(id, "based_user")} icon={<DownloadOutlined />}
          style={{
            width:"100%",
            marginBottom: 10
          }}
          >{ 'Download User Report'}</Button>
          <Button onClick={() => handleDownload(id, "based_product")} icon={<DownloadOutlined />}
          style={{
            width:"100%",
            marginBottom: 10
          }}
          >  {'Download Product Report'}</Button>
         {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id, "finance_based_order")} icon={<DownloadOutlined />}
         style={{
          width:"100%",
          marginBottom: 10
        }}
         > Download Accounting Report </Button>}
            {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id, "based_frm_xml")} icon={<DownloadOutlined />}
         style={{
          width:"100%",
          marginBottom: 10
        }}
         > Download FRM XML Report </Button>}
         </Spin>
      </Modal>
      <Modal
        title="Extend Campaign"
        open={isModalTimeOpen}
        onCancel={()=>setIsModalTimeOpen(false)}
        onOk={handleSubmit(onSubmit)}
        confirmLoading={isLoading}
      >
        <Form layout="vertical">
          <Form.Item
            label="End Date and Time"
            validateStatus={errors.end_date ? "error" : ""}
            help={errors.end_date ? errors.end_date.message : ""}
          >
            <Controller
              name="end_date"
              control={control}
              rules={{ required: "End date and time is required" }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  style={{ width: "100%", padding: 8 }}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="Select End Date and Time"
                  onChange={(date) => field.onChange(date)}
                />
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
        </>
      )}
    </>
  );
};

export default CampaignsTable;

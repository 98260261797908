import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TabKey = 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9'|'tab10'| 'tab11'| 'tab13';

interface ActiveTabState {
  activeTab: TabKey;
}

const initialState: ActiveTabState = {

  activeTab: 'tab5',
};

const activeTabSlice = createSlice({
  name: 'activeTab',
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<TabKey>) {
      state.activeTab = action.payload;
    },
  },
});

export const { setActiveTab } = activeTabSlice.actions;
export default activeTabSlice.reducer;

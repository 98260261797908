import React, { useEffect, useState } from 'react';
import { Table, Pagination, Spin, message, Flex, Button, Card, Image,Typography, TableColumnType, Input } from 'antd';
import { useGetGroupsQuery, useSearchGroupsQuery } from '../services/admin/inventoryApi';
import  usePagination  from '../hooks/usePagination';
import { Group, Item } from '../types.ts'; // Ensure correct import path
import Title from 'antd/es/typography/Title';
import { useNavigate } from 'react-router-dom';
import myImage from '../assets/images/AddGroup.png';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import '../styles/CatalogsTable.scss';
import Meta from 'antd/es/card/Meta';
import { SearchOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface GroupsTableProps {
  handleRowClick: (record: Group) => void;
}
const RepGroupsTable: React.FC<GroupsTableProps> = ({handleRowClick}) => {
  const navigate = useNavigate();
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const user = useSelector((state: RootState) => state.adminUser.user);
  const { currentPage, pageSize, onPageChange,onPageSizeChange } = usePagination(1,10);
  const [searchPreSaleId, setSearchPreSaleId] = useState<string>('');
  const [key, setKey] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string | null>('created_at');  // Default sorting by Created At
  const [sortOrder, setSortOrder] = useState<boolean>(true);  // Default sorting order (descending)
  const [debouncedPreSaleId, setDebouncedPreSaleId] = useState<string>(searchPreSaleId);
  const { data:groups , isFetching:isGroupsFetching,isLoading:isGroupsLoading,status:statusGroups} = useSearchGroupsQuery({
    key: key,
    value: debouncedPreSaleId,
    limit: 1000,
   
  },{skip: !key});
  const { data, error, isLoading, isFetching:isAllGroupsFetching } = useGetGroupsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: 10,
    //@ts-ignore
    is_rep_group:true,
    order_by: sortColumn ?? '',
    order_by_decending: sortOrder, 
  });
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedPreSaleId(searchPreSaleId);  // Set debounced search term after delay
    }, 500);  // Adjust the delay as needed
  
    return () => {
      clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
    };
  }, [searchPreSaleId]);
  //@ts-ignore
  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
        placeholder="Search Pre Sale ID"
        allowClear
        style={{ width: 200 }}
      value={key==="pre_sale_id"?searchPreSaleId:""}
        onChange={(e)=>{
          if(e.target.value===""){
            setKey("")
          }else{
            setKey("pre_sale_id")

          }
          setSearchPreSaleId(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key==="pre_sale_id"&&searchPreSaleId ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
    ),
 
 
  });

    //@ts-ignore
    const getColumnSearchCatalogNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
      //@ts-ignore
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{
          padding: 8,
          position: 'absolute',
          zIndex: 1050, // Ensures the dropdown appears above other elements
          background: 'white',
          transform: 'translateY(-170%)',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
        }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
          placeholder="Search Catalog Name"
          
          allowClear
          style={{ width: 200 }}
        value={key==="catalog_name"?searchPreSaleId:""}
          onChange={(e)=>{
            if(e.target.value===""){
              setKey("")
            }else{
              setKey("catalog_name")
            }
            
            setSearchPreSaleId(e.target.value)
          }}
       />
      
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: key==="catalog_name"&&searchPreSaleId ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
      ),
    
   
    });

    //@ts-ignore
    const getColumnSearchGroupNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
      //@ts-ignore
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{
          padding: 8,
          position: 'absolute',
          zIndex: 1050, // Ensures the dropdown appears above other elements
          background: 'white',
          transform: 'translateY(-170%)',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
        }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
          placeholder="Search Organization Name"
          allowClear
          style={{ width: 200 }}
        value={key==="name"?searchPreSaleId:""}
          onChange={(e)=>{
            if(e.target.value===""){
              setKey("")
            }else{
              setKey("name")
            }
            setSearchPreSaleId(e.target.value)
          }}
         
       />
      
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: key==="name"&&searchPreSaleId ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
      ),
   
   
    });
     //@ts-ignore
    const getColumnSearchAssociatedGroupNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
      //@ts-ignore
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{
          padding: 8,
          position: 'absolute',
          zIndex: 1050, // Ensures the dropdown appears above other elements
          background: 'white',
          transform: 'translateY(-170%)',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
        }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
          placeholder="Search Organization Name"
          allowClear
          style={{ width: 200 }}
        value={key==="associated_rep_group"?searchPreSaleId:""}
          onChange={(e)=>{
            if(e.target.value===""){
              setKey("")
            }else{
              setKey("associated_rep_group")
            }
            setSearchPreSaleId(e.target.value)
          }}
         
       />
      
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: key==="associated_rep_group"&&searchPreSaleId ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
      ),
   
   
    });
  const columns = [
   
    {
      title: 'Organization Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      ...getColumnSearchGroupNameProps('name')
    },
    {
      title: 'Rep Organization Associated',
      dataIndex: '',
      key: 'name',
      render: (record: any) => record.groups?.length&&record.groups[0].is_rep_group ? record.groups.map((g: { name: string }) => g.name).join(', ') : "-",
      ...getColumnSearchAssociatedGroupNameProps('associated_rep_group')
    },
    {
      title: 'Pre Sale Id',
      dataIndex: ['campaigns'],
      key: 'name',
      
      render: (campaigns: { pre_sale_id: string }[]) =>campaigns.length? campaigns?.map(g => g.pre_sale_id).join(', '):"-" ,
      ...getColumnSearchProps('full_name'),
    },
    {
      title: 'Catalog',
      dataIndex: ['catalogs'],
      key: 'name',
      render: (catalogs: { name: string }[]) =>catalogs.length? catalogs?.map(g => g.name).join(', '):"-" ,
      ...getColumnSearchCatalogNameProps('catalog_name'),
    },
    {
      title: 'Description',
      dataIndex: 'blurb',
      key: 'blurb',
      sorter: (a: any, b: any) => a.blurb.localeCompare(b.blurb),
    
    },
  
    // Add more columns as necessary
  ];
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setKey('')
    setSortColumn(sorter.field);  // Update the column being sorted
    const sortOrder = sorter.order=="ascend"?true:false;
    setSortOrder(!sortOrder);   // Update the sort order
   
  };

  useEffect(() => {
    if (error) {
      message.error('Failed to load groups');
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>{data?.data?.length?
          <Table
          loading={isLoading||isGroupsFetching||isAllGroupsFetching}
            columns={columns}
            dataSource={key?groups: data.data || []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              
            })}
            title={()=><Flex justify="space-between" align="center" className='flexRow'>
            <Title level={2}>Organization List </Title>
            <Button type='primary' onClick={()=>{
              if(!user ) return null
              if((activeTab==('tab1')||activeTab==('tab2'))&&(user?.scope === 'rep'||user?.scope==='organizer')) return null;
              if (user?.scope === ('internal_admin')||(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&user?.permissions?.create_group)||user?.scope === ('sub_rep')) {
              navigate(`/add-group`)
              }else{
                message.error("you do not have sufficient permissions for this action, please contact admin")
              }
            }}>+ Add New Organization</Button>
          </Flex>}
             rowClassName="clickable-row"
             onChange={handleTableChange}
             footer={() => searchPreSaleId?<></>:<Pagination
              current={currentPage}
              pageSize={5}
              total={data?.count}
              onChange={onPageChange}
              showSizeChanger={false}
              // onShowSizeChange={(_, newSize) => onPageSizeChange(newSize)}  
              // pageSizeOptions={["5", '10', '20', '50', '100']}  
            />
            }
          />:<Card
          
          style={{ width: "55%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
          cover={<Image alt="example" src={myImage} preview={false}/>}
        >
          <Meta title={<Title level={2} style={{
            textAlign: "center",
            marginBottom: "0px"
          }}>
          No Organizations are added yet
          </Title>} description={
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px"
          }}>
<Text style={{
  color: "#595959",
  fontSize: 16,
  fontWeight: 400,
}}>You haven’t added Organization, let’s add 
now by click below.

</Text>
           <Button type='primary' onClick={()=>{
               if(!user ) return null
               if((activeTab==('tab1')||activeTab==('tab2'))&&(user?.scope === 'rep'||user?.scope==='organizer')) return null;
               if (user?.scope === ('internal_admin')||(((user?.scope === 'rep'||user?.scope==='organizer')||user?.scope === ('sub_rep'))&&user?.permissions?.create_group)) {
               navigate(`/add-group`)
                }else{
                  message.error("you do not have sufficient permissions for this action, please contact admin")
                }
            }}>+ Add New Organizations</Button>
          </div>
          }
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
          />
          
        </Card>
          }
          
        </>
       )}
    </>
  );
};

export default RepGroupsTable;
import React, { useMemo, useRef, useState } from 'react';
// @ts-ignore
import CreditCardInput from 'react-credit-card-input';
import '../styles/CreditCardForm.scss';
import { Modal, Spin } from 'antd';

type BasicCardInfo = {
  cardNumber: string;
  cardExpiry: string;
  cardCode: string;
  firstName: string;
  lastName: string;
  zipCode: string;
};

type CreditCardFormProps = {
  cardData: BasicCardInfo;
  setCardData: React.Dispatch<React.SetStateAction<BasicCardInfo>>;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  loading: boolean;
  error: boolean;
  disabled: boolean;
  isModalOpen: boolean;
  closeModal: ()=>void;
};

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  cardData,
  isModalOpen,
  closeModal,
  setCardData,
  handleSubmit,
  loading,
  error,
  disabled,
}) => {
  const Context = React.createContext({ name: 'Default' });
  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  const [errors, setErrors] = useState({
    cardNumber: '',
    cardExpiry: '',
    cardCode: '',
    firstName: '',
    lastName: '',
    zipCode: '',
  });

  // Create a ref to directly manipulate the button
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const handleValidation = () => {
    const newErrors = {
      cardNumber: '',
      cardExpiry: '',
      cardCode: '',
      firstName: '',
      lastName: '',
      zipCode: '',
    };

    if (!cardData.firstName) {
      newErrors.firstName = 'First name is required';
    }
    if (!cardData.lastName) {
      newErrors.lastName = 'Last name is required';
    }
    if (!cardData.zipCode) {
      newErrors.zipCode = 'Zip code is required';
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error !== '');
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Disable the button instantly at DOM level
    if (btnRef.current) {
      console.log("Before disabling:", btnRef.current.disabled);
      btnRef.current.setAttribute('disabled', 'true');
      console.log("After disabling:", btnRef.current.disabled);
    }

    if (handleValidation()) {
      try {
        await handleSubmit(event);
      } catch (err) {
        console.error('Submission error:', err);
      }
    }

    // Re-enable if validation fails or submission completes
    if (btnRef.current) {
      btnRef.current.removeAttribute('disabled');
    }
  };

  return (
    <Modal
    title=""
    visible={isModalOpen}
    onCancel={()=>
      {
        if(btnRef?.current?.disabled) return
        closeModal()
      }
      }
    maskClosable={!btnRef?.current?.disabled}
    footer={null}
  >
    <form className="credit-card-form" onSubmit={handleFormSubmit}>
      <div className="form-group">
        <input
          placeholder="First Name"
          value={cardData.firstName}
          onChange={(e) =>
            setCardData({ ...cardData, firstName: e.target.value })
          }
        />
        {errors.firstName && <div className="error">{errors.firstName}</div>}
      </div>
      <div className="form-group">
        <input
          placeholder="Last Name"
          value={cardData.lastName}
          onChange={(e) =>
            setCardData({ ...cardData, lastName: e.target.value })
          }
        />
        {errors.lastName && <div className="error">{errors.lastName}</div>}
      </div>
      <div style={{ marginBottom: '10px' }}>
        <CreditCardInput
          cardNumberInputProps={{
            value: cardData.cardNumber,
            // @ts-ignore
            onChange: (e) =>
              setCardData({ ...cardData, cardNumber: e.target.value }),
          }}
          cardExpiryInputProps={{
            value: cardData.cardExpiry,
            // @ts-ignore
            onChange: (e) =>
              setCardData({ ...cardData, cardExpiry: e.target.value }),
          }}
          cardCVCInputProps={{
            value: cardData.cardCode,
            // @ts-ignore
            onChange: (e) =>
              setCardData({ ...cardData, cardCode: e.target.value }),
            onBlur: () =>
              setErrors({
                ...errors,
                cardCode: validateCardCode(cardData.cardCode)
                  ? ''
                  : 'CVC is required',
              }),
          }}
          fieldClassName="input"
        />
        {errors.cardNumber && <div className="error">{errors.cardNumber}</div>}
        {errors.cardExpiry && <div className="error">{errors.cardExpiry}</div>}
        {errors.cardCode && <div className="error">{errors.cardCode}</div>}
      </div>
      <div className="form-group">
        <input
          placeholder="Billing Zip Code"
          value={cardData.zipCode}
          onChange={(e) =>
            setCardData({ ...cardData, zipCode: e.target.value })
          }
        />
        {errors.zipCode && <div className="error">{errors.zipCode}</div>}
      </div>
      <button
        ref={btnRef}
        type="submit"
      >
        {loading ? <Spin /> : 'Pay'}
      </button>
    </form>
    </Modal>
  );
};

// Mock validation functions
const validateCardNumber = (number: string) => number.length >= 13 && number.length <= 19;
const validateExpiry = (expiry: string) => /^\d{2}\/\d{2}$/.test(expiry);
const validateCardCode = (code: string) => code.length >= 3 && code.length <= 4;

export default CreditCardForm;

// FundraisingCard.tsx
import React from 'react';
import '../styles/FundraisingCard.scss';
import { useParams } from 'react-router-dom';
import { useGetCampaignForStudentQuery, useGetCampaignForUserQuery } from '../services/campaign';
import { formatWithCommas } from '../utils';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';


const FundraisingCard: React.FC = () => {
  const { id,campaign_id, user_id } = useParams<{ id: string,campaign_id: string; user_id: string  }>();
  
  const {data:cd,  isError } = useGetCampaignForUserQuery(campaign_id || '0', {
    skip: !campaign_id,
    
  });
  const { data, error, isLoading } = useGetCampaignForStudentQuery({userId:user_id || '0',campaignId:cd?.id ?? '0'}, { skip: !user_id  || !cd?.id});
  var progressPercentage = data ? (data.money_raised / data.money_goal) * 100 : 0;
  if (!isFinite(progressPercentage) || progressPercentage < 0) {
    progressPercentage = 100; // Default to 100% if invalid
  }
  console.log(user_id,error,cd?.is_closed,isError,"user_id");
  if(isError) return <></>
  if(cd?.is_closed) return <></>
  if(isLoading) return <></>
  if(!user_id ||  error) return <></>
  const formattedMoneyRaised = formatWithCommas(data?.money_raised?.toFixed(2));
  const formattedMoneyGoal = formatWithCommas(data?.money_goal?.toFixed(2));


  return (
    <div className="fundraising-card">
      <Avatar size={240} icon={<UserOutlined />} src={data?.primary_image_url} shape="square"/>
      <h2 className="name">{data?.title}</h2>
      <p className="description">{data?.blurb}</p>
      
      <div className="progress-bar">
        
        <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
      </div>
      <p className="fundraising-status">I've raised: ${formattedMoneyRaised} out of my ${formattedMoneyGoal} goal</p>
    </div>
  );
};

export default FundraisingCard;

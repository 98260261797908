import React from 'react';
import { Button, Layout, Typography, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/Brandname-Fundraising.jpg'; // Update the path to your logo

const { Content } = Layout;
const { Title, Text } = Typography;

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate('/signin');
  };

  return (
    <Layout className="landing-page" style={{ minHeight: '100vh', backgroundColor:'#ffffff' }}>
      <Title level={2} style={{ marginBottom: '10px' }}>
        Brand Name Fundraising
      </Title>
      <div className="button-container" style={{ textAlign: 'center' , marginBottom:20}}>
              <Button type="primary" size="large" onClick={handleSignIn} block>
                Sign In
              </Button>
            </div>
      <Text style={{ fontSize: '16px', color: '#000000' }}>
        Our Brands
      </Text>
      <Content style={{ padding: '50px 20px' }}>
        <Row justify="center">
          <Col xs={24} md={16} lg={12}>
            {/* Logo Container */}
            <div className="logo-container" style={{ textAlign: 'center', marginBottom: '20px' }}>
              <img
                src={`https://brandnamefundraising.com/static/media/logo.ea5dd08c1cdbc1656baa.png`}
                alt="Fundraising Logo"
                className="fundraising-logo"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} md={16} lg={12}>
            {/* Quote Section */}
            <div className="quote-container" style={{ textAlign: 'center', marginBottom: '40px' }}>
              <Text
                style={{
                  fontSize: '24px',
                  fontStyle: 'italic',
                  display: 'block',
                  margin: '0 auto',
                }}
              >
                Fundraisers using Brand Name products see a 20% increase in profit over no-name brands.
              </Text>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} md={8} lg={6}>
            {/* Button Container */}
            
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default LandingPage;

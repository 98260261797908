import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { Campaign, Catalog, Group, GroupGroupLink, GroupLinkResponse, Item, User } from '../../types.ts';


interface AddProductRequest extends Item {
  primary_image_url: string;
  fulfilment_type: string;
  cost: number;
  name: string;
  blurb: string;
  internal_name: string;
  internal_id: string;
  sku: string;
}
interface GetProductByIdRequest{
  product_ids:number[]
}
interface ParsedData {
  email_address: string;
  name: string;
}
interface AddUserRequest {
  "email": string,
  "is_active": boolean,
  "is_superuser": boolean,
  "full_name": string,
  "scope": string,
  "in_leaderboard": boolean,
  "recent_orders_enabled": boolean,
  "primary_image_url": string,
  "raise_goal": number,
  "blurb": string,
  "password": string,
  "groups": number[]
  "new_password":string,
  "pre_sale_id":number,
  "count_amount":string;
  "sold_amount":string;
}
interface UserResponse {
data?: User[];
count: number;

}

interface GroupResponse {
  data?: Group[];
  count: number;
  }

  interface CampaignResponseI {
    data?: Campaign[];
    count: number;
    }
interface OrderResponse {
  length: number;
  data?: Item[];
  count: number;
  }
  export interface UpdateTagOrderLinkRequest {
    order_id: number;
    tag_ids: number[];
  }

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const inventoryApi = createApi({
  reducerPath: 'inventoryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).adminUser.token;
      headers.set('ngrok-skip-browser-warning', `true`);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['CampaignById','GroupsNames','GroupLinks','Products', 'Catalogs','ContactsBulk', 'Groups','Users', 'Campaign','OrdersBulk', 'Orders','InviteStudents','searchCampaign','searchUsers',"searchGroups","searchOrders","groupId","User","OrderEdit"],
  endpoints: (builder) => ({
    getUsers: builder.query<UserResponse, { skip: number; limit: number, group_id?: number,group_id_filter?:number,user_id_filter?:string ,order_by?:string,order_by_decending?:boolean}>({
      //@ts-ignore
      query: ({ skip, limit, group_id ,group_id_filter,user_id_filter,order_by, order_by_decending}) => `users?skip=${skip}&limit=${limit}${group_id?`&group_id=${group_id}`:''}${group_id_filter?`&group_id_filter=${group_id_filter}`:''}${user_id_filter?`&user_id_filter=${user_id_filter}`:''}${order_by?`&order_by=${order_by}`:''}${order_by_decending!==null&&order_by_decending!==undefined&&order_by_decending!=="undefined"?`&order_by_descending=${order_by_decending}`:''}`,
      providesTags: ['Users'],
    }),
    searchUsers: builder.query<User[], { key: string;value:string; limit: number,group_id_filter?:number }>({
      query: ({ key,value, limit ,group_id_filter}) => `users/search/?key=${key}&value=${value}&limit=${limit}${group_id_filter?`&group_id_filter=${group_id_filter}`:''}`,
      providesTags: ['searchUsers'],
    }),
    searchGroups: builder.query<User[], { key: string;value:string; limit: number }>({
      query: ({ key,value, limit }) => `groups/search/?key=${key}&value=${value}&limit=${limit}`,
      providesTags: ['searchGroups'],
    }),
    getOrders: builder.query<OrderResponse, { skip: number; limit: number, campaign_id?: number,user_id?: number ,order_by?:string,order_by_decending?:boolean}>({
      //@ts-ignore
      query: ({ skip, limit, campaign_id, user_id ,order_by, order_by_decending}) => `orders?skip=${skip}&limit=${limit}${campaign_id?`&campaign_id=${campaign_id}`:''}${user_id?`&user_id=${user_id}`:''}${order_by?`&order_by=${order_by}`:''}${order_by_decending!==null&&order_by_decending!==undefined&&order_by_decending!=="undefined"?`&order_by_descending=${order_by_decending}`:''}`,
      providesTags: ['Orders'],
    }),
    // New Query for Group Links
    getGroupLinks: builder.query<GroupLinkResponse[], { 
      group_id?: number;
      linked_group_id?: number;
      sub_rep_user_id?: number;
      skip?: number;
      limit?: number;
      order_by?: string;
      order_by_descending?: boolean;
  }>({
      query: ({ group_id, linked_group_id, sub_rep_user_id, skip = 0, limit = 100, order_by, order_by_descending }) => {
          const params = new URLSearchParams({
              skip: String(skip),
              limit: String(limit),
          });
  
          if (group_id !== undefined) params.append('group_id', String(group_id));
          if (linked_group_id !== undefined) params.append('linked_group_id', String(linked_group_id));
          if (sub_rep_user_id !== undefined) params.append('sub_rep_user_id', String(sub_rep_user_id));
          if (order_by !== undefined) params.append('order_by', order_by);
          if (order_by_descending !== undefined) {
              params.append('order_by_descending', String(order_by_descending));
          }
  
          return `groups/allgrouplinks?${params.toString()}`;
      },
      providesTags: ['GroupLinks'],
  }),

    getOrdersBulk: builder.query<{user_id:number}[], {  campaign_id?: number,user_ids?: number[] }>({
      //@ts-ignore
      query: ({ campaign_id, user_ids }) => `orders/bulk?${campaign_id?`&campaign_id=${campaign_id}`:''}${user_ids?`&user_ids=${user_ids}`:''}`,
      providesTags: ['OrdersBulk'],
    }),
    getOrdersById: builder.query<Item[], {  order_id?: number }>({
      //@ts-ignore
      query: ({  order_id }) => `orders/${order_id}`,
      providesTags: ['OrderEdit'],
    }),
    updateOrdersById: builder.mutation<void,  Item >({
      //@ts-ignore
      query: (  order ) => 
      ({
        url: `orders/manual-order/${order?.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: order,
      }),
      invalidatesTags: ['OrderEdit','Orders','searchUsers'],
    }),
    deleteOrdersById: builder.mutation<void, number>({
      query: (orderId) => ({
        url: `orders/manual-order/${orderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Orders'],  // Invalidate cache to refresh order data
    }),
    deleteGroupGroupLinksByIds: builder.mutation<void, number[]>({
      query: (ids) => ({
        url: `groups/bulk-delete/`,
        method: 'DELETE',  // Changed to PUT since your API expects PUT
        body:  ids ,  // Sending the IDs in the request body
      }),
      invalidatesTags: ['GroupLinks','Groups'],  // Refresh cache after deletion
    }),
    deleteOrdersByIdByAdmin: builder.mutation<void, number>({
      query: (orderId) => ({
          url: `orders/soft-delete-order/${orderId}`,  // Updated endpoint for soft delete
          method: 'PUT',  // Changed from DELETE to PUT for soft delete
        }),
      invalidatesTags: ['Orders'],  // Invalidate cache to refresh order data
  }),
  addGroupGroupLinks: builder.mutation<void, { group_id: number; linked_group_ids: number[] }>({
  query: ({ group_id, linked_group_ids }) => ({
    url: `groups/addlink/${group_id}`,
    method: 'POST',
    body: linked_group_ids,  // ✅ Sending linked group IDs in request body
  }),
  invalidatesTags: ['GroupLinks', 'Groups'],  // Refresh cache after successful addition
}),
    createOrderManually: builder.mutation({
      query: (shippingInfo) => ({
        url: 'orders/manual-order/',
        method: 'POST',
        body: shippingInfo,
      }),
      invalidatesTags: ['searchUsers'],
    }),
    getContactsBulk: builder.query<{user_id:number}[], {  campaign_id?: number,user_ids?: number[] }>({
      //@ts-ignore
      query: ({ campaign_id, user_ids }) => `contacts/bulk?${campaign_id?`&campaign_id=${campaign_id}`:''}${user_ids?`&user_ids=${user_ids}`:''}`,
      providesTags: ['ContactsBulk'],
    }),
    getInventoryProducts: builder.query<Item[], { skip: number; limit: number , catalog_id?: number}>({
      query: ({ skip, limit, catalog_id }) => `inventory/products/?skip=${skip}&limit=${limit}${catalog_id?`&catalog_id=${catalog_id}`:''}`,
      providesTags: ['Products'],
    }),

    GetGroupById: builder.query<Group, {group_id?: number}>({
      query: ({ group_id }) => `groups/${group_id}`,
      providesTags: ['Groups'],
    }),
    GetPublicGroupById: builder.query<Group, {group_id?: number}>({
      query: ({ group_id }) => `groups/public/${group_id}`,
    }),
    GetFilteredPublicGroupById: builder.query<Group, {group_id?: number}>({
      query: ({ group_id }) => `groups/filtered/${group_id}`,
    }),
    GetCampaignpById: builder.query<Campaign, {campaign_id?: number}>({
      query: ({ campaign_id }) => `campaigns/${campaign_id}`,
      providesTags: ['CampaignById'],
    }),
    addProduct: builder.mutation<void, AddProductRequest>({
      query: (product) => ({
        url: `inventory/products/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product,
      }),
      invalidatesTags: ['Products'],
    }),
    getProductById: builder.mutation<void, GetProductByIdRequest>({
      query: (product_ids) => ({
        url: `inventory/products/names/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product_ids.product_ids,
      }),
    }),
    addUser: builder.mutation<void, AddUserRequest>({
      query: (product) => ({
        url: `users/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product,
      }),
      invalidatesTags: ['Users'],
    }),
    addCampaign: builder.mutation<void, Campaign>({
      query: (product) => ({
        url: `campaigns/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product,
      }),
      invalidatesTags: ['Campaign'],
    }),
    editProduct: builder.mutation<void, Item>({
      query: (product) => ({
        url: `inventory/products/${product.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product,
      }),
      invalidatesTags: ['Products'],
    }),
    editUser: builder.mutation<void, User>({
      query: (user) => ({
        url: `users/${user.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),
   
    getInventoryCatalogs: builder.query<Catalog[], { skip: number; limit: number }>({
      query: ({ skip, limit }) => `inventory/catalogs/?skip=${skip}&limit=${limit}`,
      providesTags: ['Catalogs'],
    }),
    getUserById: builder.query<User, { id: number; }>({
      query: (user) => ({
        url: `users/${user.id}`,
        method: 'GET',
      
      }),
      providesTags: ['User'],
    }),
    addCatalog: builder.mutation({
        query: (newCatalog) => ({
          url: '/inventory/catalogs/',
          method: 'POST',
          body: newCatalog,
        }),
        invalidatesTags: ['Catalogs'],
      }),
      editCatalog: builder.mutation({
        query: (newCatalog) => ({
          url: `/inventory/catalogs/${newCatalog.id}`,
          method: 'PATCH',
          body: newCatalog,
        }),
        invalidatesTags: ['Catalogs'],
      }),
    getGroups: builder.query<GroupResponse, { skip: number; limit: number;is_rep_group?:Boolean,order_by?:string,order_by_decending?:boolean }>({
      //@ts-ignore
      query: ({ skip, limit,is_rep_group=true ,order_by, order_by_decending}) => `groups/?skip=${skip}&limit=${limit}${is_rep_group!==null?`&is_rep_group=${is_rep_group}`:''}${order_by?`&order_by=${order_by}`:''}${order_by_decending!==null&&order_by_decending!==undefined&&order_by_decending!=="undefined"?`&order_by_descending=${order_by_decending}`:''}`,
      providesTags: ['Groups'],
    }),
    getGroupsNames: builder.query<GroupResponse, { skip: number; limit: number;is_rep_group?:Boolean }>({
      query: ({ skip, limit,is_rep_group=true }) => `groups/names?skip=${skip}&limit=${limit}${is_rep_group!==null?`&is_rep_group=${is_rep_group}`:''}`,
      providesTags: ['GroupsNames'],
    }),
    getGroupsLinked: builder.query<GroupGroupLink[], { user_id: number }>({
      query: ({ user_id }) => `groups/group-links/${user_id}`,
      providesTags: ['GroupLinks'],
    }),
    updateGroupLinks: builder.mutation<GroupGroupLink[],  { groupLinks: GroupGroupLink[]; user_id: number }>({
      query: ({groupLinks , user_id}) => ({
        url: `groups/group-links/bulk-update/${user_id}`,
        method: "POST",
        body: groupLinks,
      }),
      invalidatesTags: ['GroupLinks'], // Ensures data is refreshed
    }),
    addGroup: builder.mutation({
      query: (newGroup) => ({
        url: 'groups/',
        method: 'POST',
        body: newGroup,
      }),
      invalidatesTags: ['Groups','GroupsNames'],
    }),
    inviteStudents: builder.mutation<void, { campaignId: string; data: ParsedData[] }>({
      query: ({campaignId,data}) => ({
        url: `campaigns/invite/sellers/${campaignId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['InviteStudents'],
    }),
    inviteUsers: builder.mutation<void, { campaignId: string; data: ParsedData[] }>({
      query: ({campaignId,data}) => ({
        url: `campaigns/invite/contacts_by_seller/${campaignId}`,
        method: 'POST',
        body: data,
      }),
     
    }),
    editGroups: builder.mutation({
      query: (group) => ({
        url: `/groups/${group.id}`,
        method: 'PATCH',
        body: {...group.groupData,id:group.id},
      }),
      invalidatesTags: ['Groups', 'Users','GroupsNames'],
    }),
    editCampaign: builder.mutation({
      query: (campaign) => ({
        url: `/campaigns/${campaign.id}`,
        method: 'PATCH',
        body: campaign,
      }),
      invalidatesTags: ['CampaignById','Campaign'],
    }),
    editCampaignOnly: builder.mutation({
      query: (campaign) => ({
        url: `/campaigns/${campaign.id}`,
        method: 'PATCH',
        body: campaign,
      }),
      invalidatesTags: ['CampaignById'],
    }),
    tagUserForOrder: builder.mutation({
      query: (order) => ({
        url: `/orders/${order.id}`,
        method: 'PATCH',
        body: order,
      }),
      invalidatesTags: ['Orders'],
    }),
    updateTagsForOrders: builder.mutation({
      query: (body:UpdateTagOrderLinkRequest) => ({
        url: `/orders/tagorderlink/${body.order_id}`,
        method: 'PATCH',
        body: {
          tag_ids: body.tag_ids
        },
      }),
    }),
    getCampaigns: builder.query<CampaignResponseI, { skip: number; limit: number ,order_by?:string,order_by_decending?:boolean}>({
      //@ts-ignore
      query: ({ skip, limit,order_by, order_by_decending }) => `campaigns/?skip=${skip}&limit=${limit}${order_by?`&order_by=${order_by}`:''}${order_by_decending!==null&&order_by_decending!==undefined&&order_by_decending!=="undefined"?`&order_by_descending=${order_by_decending}`:''}`,
      providesTags: ['Campaign'],
    }),
    searchCampaigns: builder.query<Campaign[], { key: string;value:string; limit: number }>({
      query: ({ key,value, limit }) => `campaigns/search/?key=${key}&value=${value}&limit=${limit}`,
      providesTags: ['searchCampaign'],
    }),
    searchOrders: builder.query<Campaign[], { key: string;value:string; limit: number }>({
      query: ({ key,value, limit }) => `orders/search/?key=${key}&value=${value}&limit=${limit}`,
      providesTags: ['searchOrders'],
    }),
    getInviteStudents: builder.query<{ data: ParsedData[]; count: number }, { campaignId: string; skip: number; limit: number }>({
      query: ({ campaignId, skip, limit }) => `campaigns/invite/contacts_by_seller/${campaignId}?skip=${skip}&limit=${limit}`,
      providesTags: ['InviteStudents'],
    }),
    getInviteCampaigns: builder.query<{ data: ParsedData[]; count: number }, { campaignId: string; skip: number; limit: number }>({
      query: ({ campaignId, skip, limit }) => `campaigns/invite/contacts_by_seller/${campaignId}?skip=${skip}&limit=${limit}`,
      providesTags: ['Campaign'],
    }),
    getReportSignups: builder.query<{ data: ParsedData[]; count: number }, { campaignId: string; skip: number; limit: number }>({
      query: ({ campaignId, skip, limit }) => `report/table/signups/${campaignId}?skip=${skip}&limit=${limit}`,
      providesTags: ['Campaign'],
    }),
    getLeaderBoard: builder.query<{ data: ParsedData[]; count: number }, { campaignId: string; skip: number; limit: number }>({
      query: ({ campaignId, skip, limit }) => `report/table/leaderboard/${campaignId}?skip=${skip}&limit=${limit}`,
      providesTags: ['Campaign'],
    }),

   
    downloadReport: builder.mutation<void, { campaignId: string; reportType: 'based_order' | 'based_user' | 'based_product'| 'finance_based_order'|'based_frm_xml' | 'based_group_order'}>({
      query: ({reportType,campaignId}) => ({
        url: `report/campaign/${reportType}/${campaignId}`,
        method: 'GET',
        headers: {
          'Accept': 'text/csv',
        },
        responseHandler: async (response) => {
          if (response.status==400) {
            // Handle error responses
            const errorData = await response.json(); // Parse JSON response
           return errorData
          }
          return response.blob(); // Process CSV as a blob
        },
      }),
    }),
    downloadReportPdf: builder.mutation<void, { campaignId: string; reportType: 'based_order' | 'based_user' | 'based_product'| 'finance_based_order'|'based_frm_xml' | 'based_group_order'}>({
      query: ({reportType,campaignId}) => ({
        url: `report/campaign/${reportType}/${campaignId}`,
        method: 'GET',
        headers: {
          'Accept': 'text/pdf',
        },
        responseHandler: async (response) => {
          if (response.status==400) {
            // Handle error responses
            const errorData = await response.json(); // Parse JSON response
           return errorData
          }
          return response.blob(); // Process CSV as a blob
        },
      }),
    }),
  }),
  
});

export const {useDeleteOrdersByIdMutation, useSearchOrdersQuery,useGetOrdersBulkQuery,useGetOrdersByIdQuery,  useGetInventoryProductsQuery, useGetInventoryCatalogsQuery, useGetGroupByIdQuery ,useGetGroupsQuery, useGetCampaignsQuery,useGetUserByIdQuery, useAddProductMutation, useEditProductMutation, useAddCatalogMutation, useEditCatalogMutation, useAddGroupMutation, useEditGroupsMutation, useGetUsersQuery,useAddCampaignMutation , useEditCampaignMutation, useEditUserMutation, useGetOrdersQuery, useAddUserMutation,useDownloadReportMutation , useInviteStudentsMutation, useInviteUsersMutation ,useGetInviteStudentsQuery,
  useGetInviteCampaignsQuery,useGetReportSignupsQuery,useGetLeaderBoardQuery,useTagUserForOrderMutation, useSearchCampaignsQuery,useSearchUsersQuery, useSearchGroupsQuery, useGetProductByIdMutation, useGetContactsBulkQuery, useGetCampaignpByIdQuery, useGetGroupsNamesQuery, useUpdateTagsForOrdersMutation ,useGetGroupsLinkedQuery , useUpdateGroupLinksMutation, useUpdateOrdersByIdMutation, useEditCampaignOnlyMutation,useCreateOrderManuallyMutation , useDeleteOrdersByIdByAdminMutation,useGetGroupLinksQuery,useDeleteGroupGroupLinksByIdsMutation, useAddGroupGroupLinksMutation, useDownloadReportPdfMutation, useGetPublicGroupByIdQuery, useGetFilteredPublicGroupByIdQuery} = inventoryApi;
